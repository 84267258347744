import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import { IAutoCompleteOption } from 'common/interfaces';
import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import { useSalesOrderDataTableViewContext } from 'pages/sales/context-provider';
import AdvanceSearchFilterModal from 'pages/shared/advancesearchfiltermodal';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import {
  ContainerFlat,
  ContainerFlexBetween,
} from 'styles/containers/container.grid';

import { Search } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  InputAdornment,
  Grid as MUIGrid,
  TextField,
  alpha,
  styled,
} from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import SearchPanelSidebar from './search-panel-sidebar';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const OrdersFilterSearchPanel = () => {
  const {
    setIsAddTagsModalOpen,
    setIsAddToBatchModalOpen,
    setIsAssingToOnOrderModalOpen,
    setIsDeleteBulkOrderModalOpen,
    isLocationAndFacilityModalOpen,
    setIsUpdateStatusOnOrderModalOpen,
    isUpdateOrderHoldModalOpen,
    setIsUpdateOrderHoldModalOpen,
    isAnyOrderHoldModalOpen,
    setIsAnyOrderHoldModalOpen,
  } = useContext(GlobalContext);

  const location = useLocation();

  const { isWarehouseCustomerAccount, handleUserPermissionAllow } =
    useContext(AuthContext);

  const {
    searchParams,
    isFetchingData,
    orderQuantities,
    advanceSearchValue,
    filterSearchOptions,
    orderFilterListLocal,
    selectedRowsOnCheckbox,
    setOrderFilterListLocal,
    rows,
    setIsBulkDelete,
    loadFilterList,
    setFilteredRows,
    setAdvanceSearchValue,
    onLoadOrdersDatatable,
    OnClickUserFilterSearchApply,
    selectedQuickFilter,
    showAdvancedFilter,
    setShowAdvancedFilter,
    handleQuickFiltersClick,
    apiRef,
  } = useSalesOrderDataTableViewContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [searchFieldValue, setSearchFieldValue] = React.useState('');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAdvancedSearchClick = async () => {
    setShowAdvancedFilter(true);
  };

  const handleAdvancedCloseClick = async () => {
    setShowAdvancedFilter(false);
  };

  const handleDropdownFilterClick = async (
    e: SyntheticEvent,
    newValue: IAutoCompleteOption,
  ) => {
    e.preventDefault();
    if (newValue) {
      setAdvanceSearchValue((prev) => ({
        ...prev,
        filterSearchParameter: {
          value: newValue.value,
          label: newValue.label,
        },
        setFilterName: newValue.label,
      }));
      OnClickUserFilterSearchApply();
      handleQuickFiltersClick('custom', newValue);
    } else {
      setAdvanceSearchValue((prev) => ({
        ...prev,
        filterSearchParameter: null,
        setFilterName: null,
      }));
      setOrderFilterListLocal(null);
      handleQuickFiltersClick('open');
    }
  };

  useEffect(() => {
    loadFilterList();
    if (!isLocationAndFacilityModalOpen) {
      if (
        advanceSearchValue?.filterSearchParameter ||
        advanceSearchValue?.ishold === true
      ) {
        OnClickUserFilterSearchApply(null, true);
      } else if (advanceSearchValue?.filterSearchParameter === null) {
        OnClickUserFilterSearchApply(null, false);
      }
    }
  }, [
    searchParams,
    advanceSearchValue.filterSearchParameter,
    orderFilterListLocal,
    isLocationAndFacilityModalOpen,
  ]);

  const handleSearchFieldChange = (newValue) => {
    if (newValue) {
      onLoadOrdersDatatable(searchParams, newValue)
        .then((x) => {
          const orders = [];
          x.forEach((o: any) => {
            orders.push({
              orderId: o.orderId,
              orderNo: o.orderNo,
              customerId: o.customerId,
              warehouseCustomerId: o.warehouseCustomerId,
              carrier: o?.carrier,
              customer: o?.customer,
              creationDate:
                o?.creationDateTime !== undefined
                  ? moment(o?.creationDateTime).format('MM/DD/YYYY')
                  : undefined,
              creationTime:
                o?.creationDateTime !== undefined
                  ? moment(o?.creationDateTime).format('LT')
                  : undefined,
              creationDateTime: o?.creationDateTime,
              referenceNo: o?.referenceNo,
              shipByDateTime:
                o?.shipByDateTime !== undefined
                  ? moment(o?.shipByDateTime).format('MMM DD YYYY')
                  : undefined,
              shipCancelDateTime:
                o?.shipCancelDateTime !== undefined
                  ? moment(o?.shipCancelDateTime).format('MMM DD YYYY')
                  : undefined,
              itemCount: o.itemCount,
              totalQuantity: o.totalQuantity,
              statusValue: o.statusValue,
              status: o.status,
              po: o.po,
              isHold: o.isHold,
              isBackOrder: o.isBackOrder,
              isPartial: o.isPartial,
              shipStationTags: o.shipStationTags,
              shipStationTagsExport: o.shipStationTagsExport,
              assignedByUserId: o.assignedByUserId,
              batchNo: o?.batchNo,
              invoiceId: o?.invoiceId,
              weightTotal: o?.weightTotal,
              shipAddress1: o?.shipAddress1,
              shipAddress2: o?.shipAddress2,
              shipName: o?.shipName,
              shipCompany: o?.shipCompany,
              shipCity: o?.shipCity,
              shipState: o?.shipState,
              shipZip: o?.shipZip,
              shipCountry: o?.shipCountry,
              shipEmail: o?.shipEmail,
              shipPhone: o?.shipPhone,
              shipDateDay: o?.shipDate
                ? moment(o?.shipDate).format('MM/DD/YYYY')
                : undefined,
              shipDateTime: o?.shipDate
                ? moment(o?.shipDate).format('LT')
                : undefined,
              isAddressInvalid: o?.isAddressInvalid,
              channel: o?.channel,
              isOpenPickTicketCompleted: o?.isOpenPickTicketCompleted,
              assignedTo: o?.assignedTo,
            });
          });
          setFilteredRows(orders);
        })
        .catch((err) => console.log(err));
    } else {
      setFilteredRows(rows);
    }
  };

  return (
    <ContainerFlat
      sx={{
        display: location.pathname.includes('/returns') ? 'none' : 'block',
      }}
    >
      <ContainerFlexBetween>
        <MUIGrid sx={{ height: '35px', display: 'flex', gap: '5px' }}>
          <MUIGrid item>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                apiRef.current.setColumnVisibility(
                  'shipStationTagsExport',
                  true,
                );
                apiRef.current.exportDataAsCsv();
                apiRef.current.setColumnVisibility(
                  'shipStationTagsExport',
                  false,
                );
              }}
            >
              <FileDownloadIcon />
            </Button>
          </MUIGrid>
          <MUIGrid item>
            <Chip
              label={`All (${orderQuantities?.allOrders || 0})`}
              size="small"
              sx={{
                color: selectedQuickFilter === '' ? '#FFF' : '#8a8a8c',
                fontWeight: '500',
                fontSize: '0.75rem',
                padding: '15px 5px',
                height: '35px',
              }}
              style={{
                backgroundColor:
                  selectedQuickFilter === '' ? '#1C9DCC' : '#ebeef3',
                borderRadius: '4px',
              }}
              onClick={() => {
                handleQuickFiltersClick('');
              }}
              disabled={isFetchingData}
            />
          </MUIGrid>
          <MUIGrid item>
            <Chip
              label={`Hold (${orderQuantities?.holdOrders || 0})`}
              size="small"
              sx={{
                color: selectedQuickFilter === 'hold' ? '#FFF' : '#8a8a8c',
                fontWeight: '500',
                fontSize: '0.75rem',
                padding: '15px 5px',
                height: '35px',
              }}
              style={{
                backgroundColor:
                  selectedQuickFilter === 'hold' ? '#1C9DCC' : '#ebeef3',
                borderRadius: '4px',
              }}
              onClick={() => {
                handleQuickFiltersClick('hold');
              }}
              disabled={isFetchingData}
            />
          </MUIGrid>
          <MUIGrid item>
            <Chip
              label={`New (${orderQuantities?.newOrders || 0})`}
              size="small"
              sx={{
                color: selectedQuickFilter === 'new' ? '#FFF' : '#8a8a8c',
                fontWeight: '500',
                fontSize: '0.75rem',
                padding: '15px 5px',
                height: '35px',
              }}
              onClick={() => {
                handleQuickFiltersClick('new');
              }}
              style={{
                backgroundColor:
                  selectedQuickFilter === 'new' ? '#1C9DCC' : '#ebeef3',
                borderRadius: '4px',
              }}
              disabled={isFetchingData}
            />
          </MUIGrid>
          <MUIGrid item>
            <Chip
              label={`Open (${orderQuantities?.openOrders || 0})`}
              size="small"
              sx={{
                color: selectedQuickFilter === 'open' ? '#FFF' : '#8a8a8c',
                fontWeight: '500',
                fontSize: '0.75rem',
                padding: '15px 5px',
                height: '35px',
              }}
              style={{
                backgroundColor:
                  selectedQuickFilter === 'open' ? '#1C9DCC' : '#ebeef3',
                borderRadius: '4px',
              }}
              onClick={() => {
                handleQuickFiltersClick('open');
              }}
              disabled={isFetchingData}
            />
          </MUIGrid>

          <MUIGrid item>
            <Chip
              label={`Packing (${orderQuantities?.packing || 0})`}
              size="small"
              sx={{
                color: selectedQuickFilter === 'shipment' ? '#FFF' : '#8a8a8c',
                fontWeight: '500',
                fontSize: '0.75rem',
                padding: '15px 5px',
                height: '35px',
              }}
              style={{
                backgroundColor:
                  selectedQuickFilter === 'shipment' ? '#1C9DCC' : '#ebeef3',
                borderRadius: '4px',
              }}
              onClick={() => {
                handleQuickFiltersClick('shipment');
              }}
              disabled={isFetchingData}
            />
          </MUIGrid>
          <MUIGrid item>
            <Chip
              label={`Completed (${orderQuantities?.completedOrders || 0})`}
              size="small"
              sx={{
                color: selectedQuickFilter === 'complete' ? '#FFF' : '#8a8a8c',
                fontWeight: '500',
                fontSize: '0.75rem',
                padding: '15px 5px',
                height: '35px',
              }}
              style={{
                backgroundColor:
                  selectedQuickFilter === 'complete' ? '#1C9DCC' : '#ebeef3',
                borderRadius: '4px',
              }}
              onClick={() => {
                handleQuickFiltersClick('complete');
              }}
              disabled={isFetchingData}
            />
          </MUIGrid>

          {filterSearchOptions.slice(0, 3).map((item) => (
            <MUIGrid item key={item.value}>
              <Chip
                label={item.label}
                size="small"
                sx={{
                  color:
                    selectedQuickFilter === item.label ? '#FFF' : '#8a8a8c',
                  fontWeight: '500',
                  fontSize: '0.75rem',
                  padding: '15px 5px',
                  height: '35px',
                }}
                style={{
                  backgroundColor:
                    selectedQuickFilter === item.label ? '#1C9DCC' : '#ebeef3',
                  borderRadius: '4px',
                }}
                onClick={() => {
                  handleQuickFiltersClick('custom', item);
                }}
                disabled={isFetchingData}
              />
            </MUIGrid>
          ))}
        </MUIGrid>
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
            height: '35px',
            alignItems: 'center',
          }}
        >
          {!isWarehouseCustomerAccount && (
            <MUIGrid sx={{ textAlign: 'right !important' }}>
              <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Actions
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={(e) => {
                    if (selectedRowsOnCheckbox === null) {
                      snackActions.error('Please select one or more orders!');
                      return;
                    }

                    handleClose();
                    setIsUpdateStatusOnOrderModalOpen(true);
                  }}
                  disableRipple
                >
                  Update Status
                </MenuItem>
                {handleUserPermissionAllow(
                  UserPermissionEnum.Orders_Assignment,
                ) && (
                  <MenuItem
                    onClick={(e) => {
                      if (selectedRowsOnCheckbox === null) {
                        snackActions.error('Please select one or more orders!');
                        return;
                      }
                      handleClose();
                      setIsAssingToOnOrderModalOpen(true);
                    }}
                    disableRipple
                  >
                    Assign To
                  </MenuItem>
                )}
                <MenuItem
                  onClick={(e) => {
                    if (selectedRowsOnCheckbox === null) {
                      snackActions.error('Please select one or more orders!');
                      return;
                    }
                    handleClose();
                    setIsAddToBatchModalOpen(true);
                  }}
                  disableRipple
                >
                  Add to Batch
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (selectedRowsOnCheckbox === null) {
                      snackActions.error('Please select one or more orders!');
                      return;
                    }
                    handleClose();
                    setIsAddTagsModalOpen(true);
                  }}
                  disableRipple
                >
                  Add Tags
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (selectedRowsOnCheckbox === null) {
                      snackActions.error('Please select one or more orders!');
                      return;
                    }

                    handleClose();
                    setIsBulkDelete(true);
                    setIsDeleteBulkOrderModalOpen(true);
                  }}
                  disableRipple
                >
                  Delete Orders
                </MenuItem>
              </StyledMenu>
            </MUIGrid>
          )}
          <Autocomplete
            sx={{ width: 150 }}
            options={filterSearchOptions}
            getOptionLabel={(option: IAutoCompleteOption) => option.label ?? ''}
            isOptionEqualToValue={(option, selected) =>
              option.value === selected.value
            }
            value={advanceSearchValue.filterSearchParameter}
            onChange={handleDropdownFilterClick}
            renderInput={(params) => (
              <div>
                <TextField
                  {...params}
                  placeholder="Filters"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <FilterListIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ boxShadow: 'none !important' }}
                  fullWidth
                  size="small"
                />
              </div>
            )}
          />
          <Button
            variant="outlined"
            size="medium"
            sx={{ boxShadow: 'none !important', padding: '7px' }}
            onClick={handleAdvancedSearchClick}
          >
            <SearchIcon />
            Advanced Search
          </Button>
          <Input
            sx={{ boxShadow: 'none !important' }}
            placeholder="Search"
            value={searchFieldValue}
            onChange={(e) => setSearchFieldValue(e.target.value)}
            rightIcon={<Search />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearchFieldChange(e.target.value);
              }
            }}
          />
        </Box>
      </ContainerFlexBetween>
      <SearchPanelSidebar
        show={showAdvancedFilter}
        onCloseSidebar={handleAdvancedCloseClick}
      />
      <AdvanceSearchFilterModal />
    </ContainerFlat>
  );
};

export default React.memo(OrdersFilterSearchPanel);
