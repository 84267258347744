import React, { useContext, useState } from 'react';

import Button from 'components/button';
import { snackActions } from 'config/snackbar.js';
import { useCycleCountContext } from 'pages/cyclecount/context';
import { useInventoryListPageContext } from 'pages/inventory/context';
import { useWarehouseCustomerContext } from 'pages/ordercustomers/warehousecustomer';
import { usePackAndShipContext } from 'pages/packandship/context';
import { useProductionContext } from 'pages/production/context';
import { useSubpageReceiptsContext } from 'pages/receiving/receipts/context';
import { useSalesOrderContext } from 'pages/sales/salesorder/context';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import {
  deleteCustomerFacility,
  deleteCustomerFacilityZone,
  deleteCustomerBin,
} from 'services/api/customerfacilities/customerfacilities.api';
import { deleteCustomerLocation } from 'services/api/customerlocations/customerlocations.api';
import { deleteCycleCountFromModal } from 'services/api/cyclecount/cyclecount.api';
import { DeleteDevice } from 'services/api/devices/devices.api';
import { removeExtensivIntegration } from 'services/api/integrations/extensiv/extensive.api';
import { deleteItem } from 'services/api/item/item.api';
import {
  deleteOrderCustomer,
  deleteOrderCustomerAddress,
} from 'services/api/ordercustomers';
import {
  deletePackageLineItemQTY,
  deletePackageOnPackAndShip,
} from 'services/api/packandship/packandship.api';
import {
  ProductionDeletePickTicket,
  ProductionReportDelete,
  DeleteLicensePlateKnown,
} from 'services/api/production/production.api';
import { deletePurchaseOrder } from 'services/api/purchaseOrders/purchaseOrders.api';
import { UserDelete } from 'services/api/user/users.api';
import {
  deleteConnectionIntegration,
  deleteAdhocChargeBilling,
  deleteBillingChargeSectionByModuleAsync,
  deleteHandlingChargeBilling,
  deleteStorageChargeBilling,
  deleteWarehouseCustomerOtherContact,
} from 'services/api/warehousecustomer/warehouseCustomer.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Modal, Box, Typography, Grid as MUIGrid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface ConfirmationDialogProps {
  deleteInventoryItem?: boolean;
  deleteCycleCount?: boolean;
  deleteFacility?: boolean;
  deleteLocation?: boolean;
  deleteBin?: boolean;
  deleteOtherContact?: boolean;
  deleteZone?: boolean;
  deletePO?: boolean;
  deleteConnectedIntegration?: boolean;
  deleteBillingAdhoc?: boolean;
  deleteBillingHandling?: boolean;
  deleteBillingStorage?: boolean;
  deleteBillingChargeSectionReceipt?: boolean;
  deleteBillingChargeSectionOrder?: boolean;
  deletePickTicket?: boolean;
  deleteProductionReport?: boolean;
  deleteUser?: boolean;
  deleteCustomerDevice?: boolean;
  deleteProductionLicensePlate?: boolean;
  deletePackageLineItem?: boolean;
  deletePackagePackAndShip?: boolean;
  orderCustomerDelete?: boolean;
  orderCustomerAddressDelete?: boolean;
  orderCustomerAddressData?: any;
  itemData?: any;
  cycleCountData?: any;
  facilityData?: any;
  locationData?: any;
  orderCustomerData?: any;
  zoneData?: any;
  binData?: any;
  binDataList?: any[];
  otherContactData?: any;
  poData?: any;
  pickTicketData?: any;
  productionReportData?: any;
  userData?: any;
  customerDeviceData?: any;
  productionLicensePlateData?: any;
  packageLineItemData?: any;
  packagePackAndShipData?: any;
  billingAdhocChargeData?: any;
  billingHandlingChargeData?: any;
  billingStorageChargeData?: any;
  billingChargeSectionData?: any;
  billingChargeSectionDataOrder?: any;
  connectedIntegrationData?: any;
  deleteBinList?: boolean;
  callBack?: () => void;
}

export default React.memo(
  ({
    billingAdhocChargeData,
    connectedIntegrationData,
    billingHandlingChargeData,
    billingStorageChargeData,
    billingChargeSectionData,
    billingChargeSectionDataOrder,
    deleteInventoryItem,
    deleteCycleCount,
    deleteFacility,
    deleteLocation,
    deleteBin,
    deleteOtherContact,
    deleteZone,
    deletePO,
    deletePickTicket,
    deleteBillingAdhoc,
    deleteConnectedIntegration,
    deleteBillingHandling,
    deleteBillingStorage,
    deleteBillingChargeSectionReceipt,
    deleteBillingChargeSectionOrder,
    deleteProductionReport,
    deleteUser,
    deleteCustomerDevice,
    deleteProductionLicensePlate,
    deletePackageLineItem,
    deletePackagePackAndShip,
    orderCustomerDelete,
    orderCustomerAddressDelete,
    orderCustomerData,
    orderCustomerAddressData,
    itemData,
    cycleCountData,
    facilityData,
    locationData,
    zoneData,
    binData,
    otherContactData,
    poData,
    pickTicketData,
    productionReportData,
    userData,
    customerDeviceData,
    productionLicensePlateData,
    packageLineItemData,
    packagePackAndShipData,
    deleteBinList,
    binDataList,
    callBack,
  }: ConfirmationDialogProps) => {
    const theme = useTheme();
    const {
      isConfirmDeleteDialogOpen,
      onCloseConfirmDeleteDialog,
      handleUpdateData,
    } = useContext(GlobalContext);
    const { currentUser } = useContext(AuthContext);
    const [showErrors, setShowErrors] = useState(false);
    const [error, setError] = useState('');

    const { setIsPickTicketItemEdit, setIsProductLicensePlateEdit } =
      useProductionContext();

    const { viewLoadData, setDataSelectedPackageLineItem } =
      usePackAndShipContext();

    const { onLoadCustomerInventory } = useInventoryListPageContext();

    const { setIsBillingChargeDeleteHasId } = useSubpageReceiptsContext();
    const { setIsBillingChargeDeleteHasIdOrder } = useSalesOrderContext();
    const { loadBillingData, queryString, loadExtensivIntegrations } =
      useWarehouseCustomerContext();

    const { viewLoadDataCycleCount } = useCycleCountContext();

    const handleCloseConfirmationModal = () => {
      onCloseConfirmDeleteDialog();
    };

    const deleteContent = async () => {
      try {
        if (orderCustomerDelete) {
          await deleteOrderCustomer({
            customerId: currentUser.Claim_CustomerId,
            orderCustomerId: orderCustomerData.orderCustomerId,
          });
        }
        if (orderCustomerAddressDelete) {
          await deleteOrderCustomerAddress({
            orderCustomerAddressId:
              orderCustomerAddressData.orderCustomerAddressId,
          });
        }
        if (deleteInventoryItem) {
          await deleteItem({
            itemId: itemData.itemId,
          }).then(() => {
            onLoadCustomerInventory();
          });
        }
        if (deleteCycleCount) {
          await deleteCycleCountFromModal({
            cycleCountId: cycleCountData.cycleCountId,
          });

          viewLoadDataCycleCount();
        }
        if (deleteFacility) {
          await deleteCustomerFacility({
            customerFacilityId: facilityData.customerFacilityId,
          });
        }
        if (deleteZone) {
          await deleteCustomerFacilityZone({ zoneId: zoneData.zoneId });
        }

        if (deleteBin) {
          await deleteCustomerBin({ binId: binData.binId });
        }

        if (deleteOtherContact) {
          await deleteWarehouseCustomerOtherContact(otherContactData);
        }

        if (deleteLocation) {
          await deleteCustomerLocation({
            customerLocationId: locationData.customerLocationId,
          });
        }
        if (deletePO) {
          await deletePurchaseOrder({
            purchaseOrderId: poData.purchaseOrderId,
          });
        }
        if (deletePickTicket) {
          await ProductionDeletePickTicket({
            pickTicketId: pickTicketData.pickTicketId,
          });
          setIsPickTicketItemEdit(false);
          callBack();
        }
        if (deleteProductionReport) {
          await ProductionReportDelete({
            JobProductionReportId: productionReportData.jobProductionReportId,
          });
        }
        if (deleteProductionLicensePlate) {
          await DeleteLicensePlateKnown({
            licensePlateId: productionLicensePlateData.licensePlateId,
          });
          setIsProductLicensePlateEdit(false);
          callBack();
        }

        if (deleteUser) {
          await UserDelete({
            UserId: userData.userId,
          });
          callBack();
        }
        if (deleteCustomerDevice) {
          await DeleteDevice({
            CustomerDeviceId: customerDeviceData.customerDeviceId,
          });
          callBack();
        }
        // to update
        if (deletePackageLineItem) {
          await deletePackageLineItemQTY({
            packageLineItemId: packageLineItemData.packageLineItemId,
          });

          viewLoadData();
          setDataSelectedPackageLineItem({});
        }

        if (deletePackagePackAndShip) {
          await deletePackageOnPackAndShip({
            packageId: packagePackAndShipData.packageId,
          }).then(() => {
            viewLoadData();
          });
        }

        // //remove connected
        if (deleteConnectedIntegration) {
          await removeExtensivIntegration(
            connectedIntegrationData.extensivCartConfigurationId,
          );
          await deleteConnectionIntegration({
            ExtensivCartConfigurationId:
              connectedIntegrationData.extensivCartConfigurationId,
          });
          loadExtensivIntegrations();
        }

        if (deleteBillingAdhoc) {
          await deleteAdhocChargeBilling({
            WarehouseCustomerAdhocChargeId:
              billingAdhocChargeData.warehouseCustomerAdhocChargeId,
          });
          loadBillingData(queryString.id);
        }

        if (deleteBillingHandling) {
          await deleteHandlingChargeBilling({
            WarehouseCustomerHandlingChargeId:
              billingHandlingChargeData.warehouseCustomerHandlingChargeId,
          });
          loadBillingData(queryString.id);
        }

        if (deleteBillingStorage) {
          await deleteStorageChargeBilling({
            WarehouseCustomerStorageChargeId:
              billingStorageChargeData.warehouseCustomerStorageChargeId,
          });
          loadBillingData(queryString.id);
        }

        if (deleteBillingChargeSectionReceipt) {
          await deleteBillingChargeSectionByModuleAsync({
            warehouseCustomerBillingChargeSectionId:
              billingChargeSectionData.warehouseCustomerBillingChargeSectionId,
          });
          setIsBillingChargeDeleteHasId(true);
        }

        if (deleteBillingChargeSectionOrder) {
          await deleteBillingChargeSectionByModuleAsync({
            warehouseCustomerBillingChargeSectionId:
              billingChargeSectionDataOrder.warehouseCustomerBillingChargeSectionId,
          });
          setIsBillingChargeDeleteHasIdOrder(true);
        }

        if (deleteBinList) {
          callBack();
        }

        snackActions.success(`Successfully removed data.`);
        setShowErrors(false);
        setError('');
        handleCloseConfirmationModal();
        handleUpdateData();
      } catch (err: any) {
        setError(err);
        snackActions.error('Delete Operation Failed.');
      }
    };

    if (!isConfirmDeleteDialogOpen) return null;

    return (
      <Modal
        open={isConfirmDeleteDialogOpen}
        onClose={() => handleCloseConfirmationModal()}
      >
        <MUIContainer sx={{ maxWidth: '500px !important' }}>
          <Typography
            className="modalTextHeader"
            variant="h6"
            fontWeight="bold"
          >
            Confirm Deletion
          </Typography>
          <MUIContent sx={{ padding: '0px 20px !important ' }}>
            {deleteInventoryItem && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '16px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Item {itemData.itemSKU}
                </Typography>
                <Typography variant="subtitle1">
                  This action cannot be undone.
                  <br />
                  Please confirm that you wish to delete this item from your
                  inventory.
                </Typography>
              </Box>
            )}

            {deleteCycleCount && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '16px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Cycle Count {cycleCountData.cycleCountName}
                </Typography>
                <Typography variant="subtitle1">
                  Are you sure you want to delete?
                </Typography>
              </Box>
            )}
            {orderCustomerDelete && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '16px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Client {orderCustomerData.customerName}
                </Typography>
                <Typography variant="subtitle1">
                  This action cannot be undone.
                  <br />
                  Please confirm that you wish to delete customer and their
                  information.
                </Typography>
              </Box>
            )}
            {orderCustomerAddressDelete && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '16px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Client Address
                </Typography>
                <Typography variant="subtitle1">
                  This action cannot be undone.
                  <br />
                  Please confirm that you wish to delete customer address.
                </Typography>
              </Box>
            )}
            {deleteFacility && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '16px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Facility {facilityData.name}
                </Typography>
                <Typography variant="subtitle1">
                  This action cannot be undone, and all data associated to this
                  facility will be lost.
                  <br />
                  Please confirm that you wish to delete this facility.
                </Typography>
              </Box>
            )}
            {deleteLocation && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '16px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Location {locationData.name}
                </Typography>
                <Typography variant="subtitle1">
                  This action cannot be undone, and all data associated to this
                  location will be lost.
                  <br />
                  Please confirm that you wish to delete this location.
                </Typography>
              </Box>
            )}
            {deleteZone && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '16px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Zone {zoneData.name}
                </Typography>
                <Typography variant="subtitle1">
                  This action cannot be undone, and all data associated to this
                  location will be lost.
                  <br />
                  Please confirm that you wish to delete this zone.
                </Typography>
              </Box>
            )}
            {deleteBin && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '16px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Are you sure you want to delete {binData.name}
                </Typography>
              </Box>
            )}
            {deleteBinList && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '16px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Are you sure you want to delete:{' '}
                  {binDataList.map((x) => (
                    <Typography> {x.name} </Typography>
                  ))}
                </Typography>
              </Box>
            )}
            {deleteOtherContact && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '16px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Other Contact {otherContactData.name}
                </Typography>
                <Typography variant="subtitle1">
                  This action cannot be undone, and all data associated to this
                  location will be lost.
                  <br />
                  Please confirm that you wish to delete this zone.
                </Typography>
              </Box>
            )}

            {deletePO && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '16px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Purchase Order {poData.purchaseOrderNo}
                </Typography>
                <Typography variant="subtitle1">
                  This action cannot be undone, and all data associated to this
                  location will be lost.
                  <br />
                  Please confirm that you wish to delete this purchase order.
                </Typography>
              </Box>
            )}
            {deletePickTicket && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '3px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '8px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Pick Ticket {pickTicketData.pickId}
                </Typography>
                <Typography variant="subtitle1">
                  Are you sure you want to delete this Pick Ticket?
                  <br />
                  It will be removed from the Operator&#39;s queue.
                </Typography>
              </Box>
            )}
            {deleteProductionReport && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '3px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '8px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Production Report
                </Typography>
                <Typography variant="subtitle1">
                  Are you sure you want to delete this Production Report?
                  <br />
                  It will be removed from the Operator&#39;s queue.
                </Typography>
              </Box>
            )}
            {deleteProductionLicensePlate && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '3px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '8px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Production License Plate
                </Typography>
                <Typography variant="subtitle1">
                  Are you sure you want to delete this{' '}
                  {productionLicensePlateData.lpn}?
                  <br />
                  It will be removed from the Operator&#39;s queue.
                </Typography>
              </Box>
            )}

            {deletePackageLineItem && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '3px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '8px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Package Line Item
                </Typography>
                <Typography variant="subtitle1">
                  Are you sure you want to remove the item from the package?
                </Typography>
              </Box>
            )}

            {deletePackagePackAndShip && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '3px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '8px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Package
                </Typography>
                <Typography variant="subtitle1">
                  Are you sure you want to remove this package?
                </Typography>
              </Box>
            )}

            {deleteConnectedIntegration && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '3px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '8px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Connected Integration
                </Typography>
                <Typography variant="subtitle1">
                  Are you sure want to remove the item?
                </Typography>
              </Box>
            )}

            {deleteBillingAdhoc && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '3px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '8px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Adhoc Charge
                </Typography>
                <Typography variant="subtitle1">
                  Are you sure want to remove the item?
                </Typography>
              </Box>
            )}
            {deleteBillingHandling && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '3px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '8px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Handling Charge
                </Typography>
                <Typography variant="subtitle1">
                  Are you sure want to remove the item?
                </Typography>
              </Box>
            )}
            {deleteBillingStorage && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '3px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '8px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Storage Charge
                </Typography>
                <Typography variant="subtitle1">
                  Are you sure want to remove the item?
                </Typography>
              </Box>
            )}

            {deleteBillingChargeSectionReceipt && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '3px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '8px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Billing Charge
                </Typography>
                <Typography variant="subtitle1">
                  Are you sure want to remove the item?
                </Typography>
              </Box>
            )}

            {deleteBillingChargeSectionOrder && (
              <Box
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  gap: '3px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '8px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Billing Charge
                </Typography>
                <Typography variant="subtitle1">
                  Are you sure want to remove the item?
                </Typography>
              </Box>
            )}

            {deleteUser && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '3px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '8px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete User
                </Typography>
                <Typography variant="subtitle1">
                  Are you sure you want to delete this User?
                </Typography>
              </Box>
            )}
            {deleteCustomerDevice && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '3px',
                }}
              >
                <Typography
                  sx={{ marginBottom: '8px' }}
                  variant="h6"
                  fontWeight="bold"
                >
                  Delete Device
                </Typography>
                <Typography variant="subtitle1">
                  Are you sure you want to delete this device?
                </Typography>
              </Box>
            )}
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={6}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '20px',
                  padding: '0px',
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ padding: 1, width: '100%' }}
                  onClick={() => handleCloseConfirmationModal()}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={() => deleteContent()}
                >
                  Confirm
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
    );
  },
);
