import React, { useContext } from 'react';

import Button from 'components/button';
import { usePackAndShipContext } from 'pages/packandship/context';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Divider,
  Grid as MUIGrid,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

export const MUIContainer = MuiStyled(Box)`
    && {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 16px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  
      background: ${(props) =>
        props.theme.palette.mode === 'dark'
          ? props.theme.palette.background.default
          : 'white'};
      box-shadow: 0px 24px 38px #00000024;
      width: 100%;
      max-width: 600px;
      border-radius: 4px;
    }
  `;
export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 24px;
    gap: 16px;
  }
`;
export default React.memo(() => {
  const { isShipAndCompleteModalOpen, onCloseShipAndCompleteModalModal } =
    useContext(GlobalContext);

  const {
    findPickTicketFormObj,
    setFindPickTicketFormObj,
    updatePackageToShipAndCompleteOnClick,
  } = usePackAndShipContext();

  return (
    <Modal open={isShipAndCompleteModalOpen}>
      <MUIContainer>
        <MUIContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Confirm Shipment
          </Typography>
          <Box autoComplete="off" component="form" noValidate>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12}>
                Are you sure you want to ship and complete this order?
              </MUIGrid>
            </MUIGrid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={8}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => onCloseShipAndCompleteModalModal()}
                    variant="outlined"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    onClick={(e) => updatePackageToShipAndCompleteOnClick(e)}
                    variant="contained"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    COMPLETE
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
