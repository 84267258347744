/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  ChangeEvent,
  useRef,
} from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { BinItemTypeEnum, PageLoadStateEnum } from 'common/enums';
import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import { DropdownListItem } from 'common/models';
import Button from 'components/button';
import Card from 'components/card';
import CardTab from 'components/card/card-tab';
import DatePickerInput from 'components/datepicker';
import CustomGridToolbar from 'components/gridtoolbar';
import Header from 'components/header';
import Input from 'components/input/Input';
import { Grid } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import moment from 'moment';
import { LocationToPrint } from 'pages/inventory/item/barcode-and-label-to-print';
import PrintLocationModal from 'pages/inventory/item/barcode-and-label-to-print-modal';
import DeleteInventoryModal from 'pages/inventory/item/delete-inventory-modal';
import ItemReceiptPictureModal from 'pages/inventory/item/item-receipt-picture-modal';
import { KitOnDemand, KitSkuType } from 'pages/inventory/models/item/details';
import warehousecustomer from 'pages/ordercustomers/warehousecustomer';
import { itemPalletTypeOption } from 'pages/receiving/receipts/context';
import { SinglePrintLineItemV2 } from 'pages/receiving/receipts/lp-print-v2';
import { FileType } from 'pages/receiving/receipts/subpage';
import { ModalTitleStatus } from 'pages/sales/salesorder/context';
import ActivePOSeeMoreModal from 'pages/shared/activeposeeemoremodal';
import AddItemCostSheet from 'pages/shared/addItemCostSheet';
import { AddLotNoModal } from 'pages/shared/addlotno';
import AddSupplierFromInventoryModal from 'pages/shared/addsupplierfrominventorymodal';
import AdjustInventoryModal from 'pages/shared/adjustinventorymodal';
import AdjustQtyModal from 'pages/shared/adjustqtymodal';
import ConfirmationModal from 'pages/shared/confirmationmodal';
import KittingLineItemModal from 'pages/shared/kittinglineitemmodal';
import PictureUploadModal from 'pages/shared/pictureuploadmodal';
import { PrintTypeEnum } from 'pages/shared/print/models/PrintTypeEnum';
import {
  OtherUOMPrint,
  OtherUOMPrintModel,
} from 'pages/shared/print/otheruomprint';
import PrintQtyModal from 'pages/shared/print/printqtymodal';
import { SKUPrint, SKUPrintModel } from 'pages/shared/print/skuprint';
import Printformtemplate, {
  FormTemplateTypeEnum,
} from 'pages/shared/printformtemplate';
import TransferBinV2Modal from 'pages/shared/transferbin_v2modal';
import {
  getDeleteInventories,
  getBinWithCategoryByCustomerFacility,
} from 'services/api/customerfacilities/customerfacilities.api';
import { itemEcommerceSyncQty } from 'services/api/integrations/integrations.api';
import {
  getWarehouseCustomerLookup,
  getStatusLookup,
  getClassificationLookup,
  getAllocationMethodTypeLookup,
  getPrimaryInventoryUOMLookup,
  getPackagingUOMLookup,
  getLicensePlateTypeLookup,
  getKitLineItemLookup,
  createItemDetail,
  updateItemDetail,
  deleteKitlineItem,
  getInventoryTypes,
} from 'services/api/inventory/inventory-new.api';
import {
  uploadItemImage,
  deleteItemImage,
  createItemSupplierFromInventory,
  getItemDetail,
  getItemUnitOfMeasureDDL,
} from 'services/api/item/item.api';
import { getReceiptLineItemToPrint } from 'services/api/receipts/receipts-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridStandard } from 'styles';

import { Pageview } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PrintIcon from '@mui/icons-material/Print';
import RemoveIcon from '@mui/icons-material/Remove';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Typography,
  TextField,
  Grid as MUIGrid,
  Box,
  Autocomplete,
  Checkbox,
  Tooltip,
  FormControlLabel,
  IconButton,
  Divider,
  Chip,
  Link,
  Button as ButtonMui,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  GridColumns,
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridRenderCellParams,
} from '@mui/x-data-grid';

import { autoCompleteTimeStamp } from '../../../../helpers/autocompletetimestamp';
import { MainContainer, ContentContainer } from '../../../styles';
import { KitOnDemandComponentModal } from '../kit-on-demand-component-modal';

type AutocompleteBase = {
  label?: string;
  value?: number;
};

interface AutocompleteBaseKit extends AutocompleteBase {
  isPrimary?: boolean;
  otherUomId?: number;
}

interface State {
  pageLoadState: PageLoadStateEnum;
}

interface InventoryTableToolBarProps {
  title?: string;
  exportItem?: React.ReactNode;
  filterItem?: React.ReactNode;
}
type PickLocationType = AutocompleteBase;
type BulkLocationType = AutocompleteBase;
type CustomerType = AutocompleteBase;
type StatusType = AutocompleteBase;
type ClassificationType = AutocompleteBase;
type AllocationMethodType = AutocompleteBase;
type PrimaryInventoryUOMType = AutocompleteBase;
type SecondaryInventoryUOMType = AutocompleteBase;
type PackagingUOMType = AutocompleteBase;
type LicensePlateType = AutocompleteBase;
type InventoryTypes = AutocompleteBase;
type PackagingType = AutocompleteBase;

type PageViewerType = {
  isNew: () => boolean;
  isView?: () => boolean;
  isEdit?: () => boolean;
  isViewOrEdit: () => boolean;
};

const enum ItemStatusEnum {
  Available = 1,
  Hold = 2,
  Damaged = 3,
  Quarantine = 4,
}

interface ItemStatusChipProps {
  itemStatus: ItemStatusEnum;
}

const ItemStatusSmallChip = ({ itemStatus }: ItemStatusChipProps) => (
  <>
    {itemStatus === ItemStatusEnum.Hold && (
      <Tooltip title="Hold" placement="right">
        <Chip
          label="Hold"
          size="small"
          sx={{
            marginLeft: 1,
            fontWeight: 'bold',
            width: 50,
            color: 'white',
          }}
          style={{ backgroundColor: '#CC3333' }}
        />
      </Tooltip>
    )}
    {itemStatus === ItemStatusEnum.Damaged && (
      <Tooltip title="Damaged" placement="right">
        <Chip
          label="Damaged"
          size="small"
          sx={{
            marginLeft: 1,
            fontWeight: 'bold',
            width: 80,
            color: 'white',
          }}
          style={{ backgroundColor: '#CC3333' }}
        />
      </Tooltip>
    )}
    {itemStatus === ItemStatusEnum.Quarantine && (
      <Tooltip title="Quarantine" placement="right">
        <Chip
          label="Quarantine"
          size="small"
          sx={{
            marginLeft: 1,
            fontWeight: 'bold',
            width: 90,
            color: 'white',
          }}
          style={{ backgroundColor: '#CC3333' }}
        />
      </Tooltip>
    )}
  </>
);

enum FormQueryStringStatus {
  View = 'view',
  New = 'new',
  Edit = 'edit',
}

export const PackagingTypeOption: AutocompleteBase[] = [
  { value: 1, label: 'Box' },
  { value: 2, label: 'Bag' },
  { value: 3, label: 'Envelope' },
  { value: 4, label: 'Pallet' },
];

export type { KitSkuType };
export type InventoryItemImageType = {
  inventoryItemImageId?: number;
  inventoryItemId?: number;
  fileName?: string;
  fileExtension?: string;
  awsFileKey?: string;
  file?: FileType;
  base64Data: string;
  showRemove: boolean; // UI
};

type InventoryItemDetailsContextProviderProps = {
  children: React.ReactNode;
};
export const decimalValidationHelper = (e: string): string => {
  if (e !== null || e !== undefined || e !== '') {
    const a = e.split('');
    let Count = 0;
    let Checkdecimal = 0;
    a.forEach((str, index) => {
      if (str === '.') {
        Count += 1;
        if (Count === 1) {
          Checkdecimal = index;
        } else {
          delete a[index];
        }
      } else if (str === ',') {
        delete a[index];
      }
    });
    if (Checkdecimal !== 0) {
      return a
        .toString()
        .replaceAll(',', '')
        .substring(0, Checkdecimal + 3);
    }
    return a.toString().replaceAll(',', '');
  }
  return e;
};

interface IUnitOfMeasure {
  itemUnitOfMeasureId?: number;
  itemId?: string;
  itemInventoryUOM?: {
    value?: number;
    label?: string;
  };
  unitsPerItem?: string;
  unitsLength?: string;
  unitsWidth?: string;
  unitsHeight?: string;
  unitsTotalArea?: string;
  unitsWeight?: string;
  upc?: string;
  name?: string;
  isPackedAsCarton?: boolean;
}

const InventoryItemDetailsContext = createContext(
  {} as {
    currentUser: any;
    optionDefaultpickLocations: any;
    optionBulkLocations: any;
    optionWarehouseCustomers: any;
    optionStatus: any;
    optionClassifications: any;
    optionAllocationMethodTypes: any;
    optionPrimaryInventoryUOM: any;
    optionPackagingUOM: any;
    optionLicensePlateTypes: any;
    queryStringItemDetails: any;
    setQueryStringItemDetails: any;
    formValues: any;
    formDatatable: any;
    setFormValues: any;
    formErrors: any;
    isViewingOrEditing: any;
    secondaryInventoryUOMChecked: any;
    setSecondaryInventoryUOMChecked: any;
    thisIsAKitChecked: any;
    setThisIsAKitChecked: any;
    kitComponentItemRows: any;
    kitComponentSingleItemRow: any;
    setKitComponentItemRows: any;
    setKitComponentSingleItemRow: any;
    modalTitle: any;
    setModalTitle: any;
    optionKitSkus: KitSkuType[];
    setOptionKitSkus: React.Dispatch<React.SetStateAction<KitSkuType[]>>;
    optionFinishedGoodUOM: any;
    kitSkuValue: any;
    setKitSkuValue: any;
    finishedGoodUOMValue: any;
    setFinishedGoodUOMValue: any;
    useAsKitOnDemandChecked: boolean;
    setUseAsKitOnDemandChecked: React.Dispatch<React.SetStateAction<boolean>>;
    finishedGoodUOMKitOnDemandDDL: AllocationMethodType;
    setFinishedGoodUOMKitOnDemandDDL: React.Dispatch<
      React.SetStateAction<AllocationMethodType>
    >;
    kitOnDemandItemRows: KitOnDemand[];
    setKitOnDemandItemRows: React.Dispatch<React.SetStateAction<KitOnDemand[]>>;
    kitOnDemandSelectedItemRow: KitOnDemand;
    setKitOnDemandSelectedItemRow: React.Dispatch<
      React.SetStateAction<KitOnDemand>
    >;
    pageViewer: PageViewerType;
    handleOnChange: any;
    handleOnClickSaveInventoryItemDetail: any;
    deleteKitLineItemComponentOnClick: any;
    itemCostSheetItemRows: any;
    setItemCostSheetItemRows: any;
    itemUnitOfMeasureRows: any;
    setItemUnitOfMeasureRows: any;
    OnClickAddRecordItemCostSheet: any;
    setItemCostSheetSingleData: any;
    itemCostSheetSingleData: any;
    viewLoadDataDetails: any;
    reloadItemImage: any;
    setSaveCostSheetTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setLocationDataToPrint: React.Dispatch<any>;
    locationDataToPrint: any;
    setLPReceiptLineItemIdToPrint: React.Dispatch<any>;
    lpReceiptLineItemIdToPrint: any;
    setTabValue: React.Dispatch<React.SetStateAction<string>>;
    tabValue: string;
    handleTabOnChange: (event: React.SyntheticEvent, newValue: string) => void;
    setOptionInventoryTypes: React.Dispatch<
      React.SetStateAction<AutocompleteBase[]>
    >;
    optionInventoryTypes: AutocompleteBase[];
    setImageDataText: React.Dispatch<React.SetStateAction<string>>;
    imageDataText: string;
    setFilteredItemLoc: React.Dispatch<React.SetStateAction<any[]>>;
    filteredItemLoc: any[];
    setItemLocations: React.Dispatch<React.SetStateAction<any[]>>;
    itemLocations: any[];
    setIsSerialNo: React.Dispatch<React.SetStateAction<boolean>>;
    isSerialNo: boolean;
    setIsTrackableByLot: React.Dispatch<React.SetStateAction<boolean>>;
    isTrackableByLot: boolean;
    onLoadItemDetailInventory: () => Promise<unknown>;
    onLoadItemDetailSummary: () => Promise<unknown>;
    setItemSummary: React.Dispatch<any>;
    itemSummary: any;
    itemEcommerceSync: () => void;
    setIsUsingLicensePlateId: React.Dispatch<React.SetStateAction<boolean>>;
    isUsingLicensePlateId: boolean;
    setSelectedToDeleteRows: React.Dispatch<any>;
    selectedToDeleteRows: any;
    onClickDeleteInventories: () => Promise<void>;
    setPrimaryInventoryUOMValue: React.Dispatch<
      React.SetStateAction<AutocompleteBase>
    >;
    primaryInventoryUOMValue: AutocompleteBase;
    state: State;
    setState: React.Dispatch<React.SetStateAction<State>>;
  },
);
export const useInventoryItemDetailsContext = () =>
  useContext(InventoryItemDetailsContext);

const InventoryItemDetailsContextProvider = ({
  children,
}: InventoryItemDetailsContextProviderProps) => {
  const query = useQuery();
  const navigate = useNavigate();

  const INITIAL_STATE: State = {
    pageLoadState: PageLoadStateEnum.LOAD_NONE,
  };
  const [state, setState] = useState<State>(INITIAL_STATE);

  // tab -----------
  const [tabValue, setTabValue] = React.useState('1');
  const handleTabOnChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);

    setState((prev) => ({
      ...prev,
      pageLoadState: PageLoadStateEnum.LOAD_START,
    }));
  };
  //----------------

  const {
    currentUser,
    currentLocationAndFacility,
    isWarehouseCustomerAccount,
  } = useContext(AuthContext);
  const { onCloseItemCostSheetModal, handleUpdateData, setSKUPrintModelData } =
    useContext(GlobalContext);

  const [kitComponentItemRows, setKitComponentItemRows] = useState<[] | any>(
    [],
  );
  const [kitComponentSingleItemRow, setKitComponentSingleItemRow] =
    useState(null);

  const [itemCostSheetItemRows, setItemCostSheetItemRows] = useState<[] | any>(
    [],
  );
  const [itemCostSheetSingleData, setItemCostSheetSingleData] = useState<any>({
    rowId: null,
    itemCostSheetId: null,
    sellPrice: 0,
    cost: 0,
    shippingCost: 0,
    date: null,
    supplierId: null,
    supplierName: '',
    partNumber: '',
    tempId: null,
  });

  const [itemUnitOfMeasureRows, setItemUnitOfMeasureRows] = useState<
    [] | IUnitOfMeasure[] | null
  >([]);

  const [primaryInventoryUOMValue, setPrimaryInventoryUOMValue] =
    useState<PrimaryInventoryUOMType>(null);

  const initialStateOfForm = {
    desc: {
      customerId: 0,
      customer: '',
      statusId: 1,
      itemImage: '',
      sku: '',
      disableSkuUpdate: false,
      description: '',
      classificationId: '',
      classificationText: '',
      upc: '',
      sellPrice: '',
      cost: '',
      inventoryType: null,
      inventoryItemImage: null,
      billRate: '',
      useAsPackaging: false,
      useAsVoidFill: false,
      packagingType: null,
      acceptsVoidFill: false,
      innerDescriptionLength: '',
      innerDescriptionWidth: '',
      innerDescriptionHeight: '',
      voidFillQty: '',
    },
    inventorySettings: {
      allocationMethodId: '',
      minOnHand: 0,
      maxOnHand: 0,
      replenishQty: 0,
      leadTime: 0,
      safetyStock: 0,
      storageTemp: '',
      defaultPickLocationBinId: '',
      bulkLocationBinId: '',
      reorderQty: '',
      isHazmat: false,
      freightClass: '',
    },
    inventoryUnitsOfMeasure: {
      primaryInventoryUOMId: 1,
      secondaryInventoryUOMId: 0,
      howManyUOMPerSecondary: 0,
      primaryUnitsLength: '',
      primaryUnitsWidth: '',
      primaryUnitsHeight: '',
      primaryUnitsWeight: '',
    },
    packaging: {
      packagingUOMId: '',
      primaryUnitsPerPackage: '',
      length: '',
      width: '',
      height: '',
      totalArea: '',
      weight: '',
      netWeight: '',
    },
    licensePlates: {
      palleteId: '',
      weight: '',
      qty: '',
      length: '',
      width: '',
      height: '',
      totalArea: '',
      palletType: null,
    },
    kitComponents: {
      finishedGoodUOM: 0,
      isPrimary: true,
      finishedGoodUOMId: 0,
      lineItems: [],
    },
    itemCostSheets: [],
    finishedGoodUOMKitOnDemand: null,
  };

  const initialStateOfQueryParams = {
    form: query.get('form'),
    id: query.get('id'),
    isPackaging: query.get('isPackaging')
      ? Boolean(query.get('isPackaging'))
      : false,
    isVoidFill: query.get('isVoidFill')
      ? Boolean(query.get('isVoidFill'))
      : false,
  };

  const initialStateOfDatatable = {
    locations: [],
    suppliers: [],
    activities: [],
  };

  const [formValues, setFormValues] = useState<any>(initialStateOfForm);
  const [formDatatable, setFormDatatable] = useState(initialStateOfDatatable);
  const [formErrors, setFormErrors] = useState({});

  const [queryStringItemDetails, setQueryStringItemDetails] = useState(
    initialStateOfQueryParams,
  );
  const [optionWarehouseCustomers, setOptionsWarehouseCustomers] = useState<
    CustomerType[]
  >([]);
  const [optionDefaultpickLocations, setOptionDefaultpickLocations] = useState<
    PickLocationType[]
  >([]);
  const [optionBulkLocations, setOptionBulkLocations] = useState<
    BulkLocationType[]
  >([]);
  const [optionStatus, setOptionStatus] = useState<StatusType[]>([]);
  const [optionInventoryTypes, setOptionInventoryTypes] = useState<
    InventoryTypes[]
  >([]);
  const [optionClassifications, setOptionClassifications] = useState<
    ClassificationType[]
  >([]);
  const [optionAllocationMethodTypes, setOptionAllocationMethodTypes] =
    useState<AllocationMethodType[]>([]);
  const [optionPrimaryInventoryUOM, setOptionPrimaryInventoryUOM] = useState<
    PrimaryInventoryUOMType[]
  >([]);
  const [optionFinishedGoodUOM, setOptionFinishedGoodUOM] = useState<
    AutocompleteBaseKit[]
  >([]);
  const [optionPackagingUOM, setOptionPackagingUOM] = useState<
    PackagingUOMType[]
  >([]);
  const [optionLicensePlateTypes, setOptionLicensePlateTypes] = useState<
    LicensePlateType[]
  >([]);
  const [optionKitSkus, setOptionKitSkus] = useState<KitSkuType[]>([]);
  const [secondaryInventoryUOMChecked, setSecondaryInventoryUOMChecked] =
    useState(false);
  const [thisIsAKitChecked, setThisIsAKitChecked] = useState(false);
  const [kitSkuValue, setKitSkuValue] = useState<KitSkuType>(null);
  const [modalTitle, setModalTitle] = useState<string | null>(null);
  const [finishedGoodUOMValue, setFinishedGoodUOMValue] =
    useState<AutocompleteBaseKit>(null);

  const [useAsKitOnDemandChecked, setUseAsKitOnDemandChecked] = useState(false);
  const [finishedGoodUOMKitOnDemandDDL, setFinishedGoodUOMKitOnDemandDDL] =
    useState<AllocationMethodType>(null);
  const [kitOnDemandItemRows, setKitOnDemandItemRows] = useState<KitOnDemand[]>(
    [],
  );
  const [kitOnDemandSelectedItemRow, setKitOnDemandSelectedItemRow] =
    useState<KitOnDemand>(null);

  const [saveCostSheetTrigger, setSaveCostSheetTrigger] = useState(false);

  // delete inventory ----------------------------------
  const [selectedToDeleteRows, setSelectedToDeleteRows] = useState<any>(null);

  // printing --------------------------------------
  const [locationDataToPrint, setLocationDataToPrint] = useState(null);
  const [lpReceiptLineItemIdToPrint, setLPReceiptLineItemIdToPrint] =
    useState(null);
  const [isUsingLicensePlateId, setIsUsingLicensePlateId] = useState(false);

  // upload image -----------------------------------------------
  const [imageDataText, setImageDataText] = useState('');

  // ---------------------------------
  const [isViewingOrEditing] = useState(
    () =>
      (queryStringItemDetails.form === 'view' ||
        queryStringItemDetails.form === 'edit') &&
      parseInt(queryStringItemDetails.id, 10) > 0,
  );

  // SUMMARY TAB INVENTORY --------------------------------
  const [urlSearchParams] = useSearchParams();

  const [filteredItemLoc, setFilteredItemLoc] = useState([]);
  const [itemLocations, setItemLocations] = useState([]);
  const [isSerialNo, setIsSerialNo] = useState<boolean>(false);
  const [isTrackableByLot, setIsTrackableByLot] = useState<boolean>(false);

  const onLoadItemDetailInventory = async () => {
    try {
      const itemSuppliersAndLocationsFromApi = await getItemDetail(
        currentUser.Claim_CustomerId,
        !isWarehouseCustomerAccount
          ? currentLocationAndFacility.customerFacilityId
          : 0,
        urlSearchParams.get(`id`),
      );
      setItemLocations(itemSuppliersAndLocationsFromApi.inventories);
      setFilteredItemLoc(itemSuppliersAndLocationsFromApi.inventories);
      setIsSerialNo(!itemSuppliersAndLocationsFromApi.isSerialNo);
      setIsTrackableByLot(!itemSuppliersAndLocationsFromApi.isTrackableByLot);

      return true;
    } catch (error) {
      return error;
    }
  };

  // ------------------------------------------------------

  const pageViewer: PageViewerType = {
    isNew: () => queryStringItemDetails.form === FormQueryStringStatus.New,
    isView: () =>
      queryStringItemDetails.form === FormQueryStringStatus.View &&
      parseInt(queryStringItemDetails.id, 10) > 0,
    isEdit: () =>
      queryStringItemDetails.form === FormQueryStringStatus.Edit &&
      parseInt(queryStringItemDetails.id, 10) > 0,
    isViewOrEdit: () =>
      (queryStringItemDetails.form === FormQueryStringStatus.View ||
        queryStringItemDetails.form === FormQueryStringStatus.Edit) &&
      parseInt(queryStringItemDetails.id, 10) > 0,
  };

  // #region 'ONLOADS'
  const onLoadWarehouseCustomerLookup = async () => {
    try {
      return await getWarehouseCustomerLookup(currentUser.Claim_CustomerId, '');
    } catch (err) {
      return err;
    }
  };

  const onLoadStatusLookup = async () => {
    try {
      return await getStatusLookup('');
    } catch (err) {
      return err;
    }
  };

  const onLoadInventoryTypes = async () => {
    try {
      return await getInventoryTypes();
    } catch (err) {
      return err;
    }
  };

  const onLoadClassificationLookup = async () => {
    try {
      return await getClassificationLookup('');
    } catch (err) {
      return err;
    }
  };

  const onLoadAllocationMethodTypeLookup = async () => {
    try {
      return await getAllocationMethodTypeLookup('');
    } catch (err) {
      return err;
    }
  };

  const onLoadPrimaryInventoryUOMLookup = async () => {
    try {
      return await getPrimaryInventoryUOMLookup('');
    } catch (err) {
      return err;
    }
  };

  const onLoadPackagingUOMLookup = async () => {
    try {
      return await getPackagingUOMLookup('');
    } catch (err) {
      return err;
    }
  };

  const onLoadLicensePlateTypeLookup = async () => {
    try {
      return await getLicensePlateTypeLookup('');
    } catch (err) {
      return err;
    }
  };

  const onLoadGetItemDetail = async () => {
    try {
      return await getItemDetail(
        parseInt(currentUser.Claim_CustomerId, 10),
        parseInt(
          !isWarehouseCustomerAccount
            ? currentLocationAndFacility.customerFacilityId
            : 0,
          10,
        ),
        parseInt(queryStringItemDetails.id, 10),
      );
    } catch (err) {
      return err;
    }
  };

  const onLoadCustomerBins = async () => {
    try {
      const customerBinsFromApi = await getBinWithCategoryByCustomerFacility(
        !isWarehouseCustomerAccount
          ? currentLocationAndFacility.customerFacilityId
          : 0,
      );

      return customerBinsFromApi;
    } catch (err: any) {
      return err;
    }
  };

  const onLoadKitSkuLookup = async (warehouseCustomerId) => {
    try {
      return await getKitLineItemLookup(warehouseCustomerId, '');
    } catch (err) {
      return err;
    }
  };
  // #endregion
  const validateForm = () => {
    const temp: any = {};
    temp.customer =
      formValues.desc.customer !== '' && formValues.desc.customer !== undefined
        ? ''
        : 'This field is required';

    temp.sku =
      formValues.desc.sku !== '' && formValues.desc.sku !== undefined
        ? ''
        : 'This field is required';

    temp.description =
      formValues.desc.description !== '' &&
      formValues.desc.description !== undefined
        ? ''
        : 'This field is required';

    if (secondaryInventoryUOMChecked) {
      temp.secondaryInventoryUOMId =
        formValues.inventoryUnitsOfMeasure.secondaryInventoryUOMId !== 0
          ? ''
          : 'This field is required';

      temp.howManyUOMPerSecondary =
        formValues.inventoryUnitsOfMeasure.howManyUOMPerSecondary !== 0
          ? ''
          : 'This field is required';
    }
    setFormErrors({
      ...temp,
    });

    return Object.values(temp).every((tmp) => tmp === '');
  };

  const deleteKitLineItemComponentOnClick = async (e: any, row: any) => {
    e.preventDefault();
    if (pageViewer.isNew()) {
      const deleteLineItemUI = kitComponentItemRows.filter(
        (i) => i.itemKitComponentId !== row.itemKitComponentId,
      );
      setKitComponentItemRows(deleteLineItemUI);

      setFormValues((prevState) => ({
        ...prevState,
        kitComponents: {
          lineItems: deleteLineItemUI,
        },
      }));
    }
    if (pageViewer.isEdit()) {
      const PAYLOAD = {
        itemKitComponentId: row.itemKitComponentId,
      };

      try {
        const response = await deleteKitlineItem(PAYLOAD);
        snackActions.success('Successfully deleted kit item.');
        const value = {
          itemKitComponentId: response.itemKitComponentId,
          itemId: response.itemId,
          sku: response.sku,
          description: response.description,
          uom: response.uom,
          uomStr: response.uomStr,
          qty: response.qty,
          // lotId: kitComponentItem.lotId,
          // lotNo: kitComponentItem.lotNo,
          // lotExpiration: kitComponentItem.lotExpiration,
        };
        const deleteLineItemUI = kitComponentItemRows.filter(
          (i) => i.itemKitComponentId !== row.itemKitComponentId,
        );
        setKitComponentItemRows(deleteLineItemUI);
      } catch (err) {
        snackActions.error(err);
      }
    }
  };

  const setValueState = (
    name: string,
    prevState: any,
    value: any,
    dataset: any,
  ) => {
    if (name === 'desc') {
      return { ...prevState.desc, [dataset]: value };
    }

    if (name === 'inventorySettings') {
      return { ...prevState.inventorySettings, [dataset]: value };
    }

    if (name === 'inventoryUnitsOfMeasure') {
      return { ...prevState.inventoryUnitsOfMeasure, [dataset]: value };
    }

    if (name === 'licensePlates') {
      return { ...prevState.licensePlates, [dataset]: value };
    }

    if (name === 'packaging') {
      return { ...prevState.packaging, [dataset]: value };
    }

    if (name === 'kitOnDemand') {
      return { ...prevState.kitOnDemand, [dataset]: value };
    }

    return prevState;
  };

  const handleOnChange = (e: any) => {
    e.preventDefault();
    const { name, value, dataset } = e.target;

    setFormValues((prevState) => ({
      ...prevState,
      [name]: setValueState(name, prevState, value, dataset.state),
    }));
  };

  const viewLoadDataDetails = async () => {
    if (isViewingOrEditing) {
      const response = onLoadGetItemDetail();
      response.then((v) => {
        setOptionPrimaryInventoryUOM(
          v.itemInventoryUOMs.map((c: any) => ({
            label: c.name,
            value: c.id,
          })),
        );
        setFormValues((prevState) => ({
          ...prevState,
          desc: {
            ...prevState.desc,
            customerId: v.warehouseCustomerId,
            statusId: v.status,
            sku: v.sku,
            disableSkuUpdate: v.inventories.length > 0,
            description: v.description,
            classificationId: v.classification,
            classificationText: v.classificationText,
            inventoryType: v.inventoryType,
            upc: v.upc,
            sellPrice: v.sellPrice,
            cost: v.cost,
            averageSellPrice: v.averageSellPrice,
            averageCost: v.averageCost,
            costSheetDate: v.costSheetDate,
            itemImage: v.itemImage,
            inventoryItemImage: v.inventoryItemImage,
            useAsPackaging: v.useAsPackaging,
            packagingType: v.packagingType
              ? PackagingTypeOption.find((e) => e.value === v.packagingType)
              : null,
            acceptsVoidFill: v.acceptsVoidFill,
            innerDescriptionLength: v.innerDescriptionLength,
            innerDescriptionWidth: v.innerDescriptionWidth,
            innerDescriptionHeight: v.innerDescriptionHeight,
            useAsVoidFill: v.useAsVoidFill,
            voidFillQty: v.voidFillQty,
            billRate: v.billRate,
          },
          inventorySettings: {
            ...prevState.inventorySettings,
            allocationMethodId: v.allocationMethod,
            minOnHand: v.minOnHand,
            maxOnHand: v.maxOnHand,
            storageTemp: v.storageTemperatureFahrenheit,
            defaultPickLocationBinId: v.defaultPickLocationBinId,
            bulkLocationBinId: v.bulkLocationBinId,
            reorderQty: v.reorderQty,
            isSerialNo: v.isSerialNo,
            isTrackableByLot: v.isTrackableByLot,
            isFullQuantityOnly: v.isFullQuantityOnly,
            replenishQty: v.replenishQty,
            leadTime: v?.leadTime,
            safetyStock: v?.safetyStock,
            isHazmat: v?.isHazmat,
            freightClass: v?.freightClass,
          },
          inventoryUnitsOfMeasure: {
            primaryInventoryUOMId: v.primaryInventoryUOM,
            primaryInventoryUOMStr: v.primaryInventoryUOMStr,
            primaryInventoryUOMAbbr: v.primaryInventoryUOMAbbr,
            secondaryInventoryUOMId: v.secondaryInventoryUOM,
            howManyUOMPerSecondary: v.qtyOfPrimaryUOMPerSecondary,
            primaryUnitsLength: v.primaryUnitsLength,
            primaryUnitsWidth: v.primaryUnitsWidth,
            primaryUnitsHeight: v.primaryUnitsHeight,
            primaryUnitsWeight: v.primaryUnitsWeight,
            isPackedAsCarton: v.isPackedAsCarton,
          },
          licensePlates: {
            palleteId: v.licensePlateType,
            weight: v.licensePlateWeightPound,
            qty: v.qtyOfPrimaryOUMPerLP,
            length: v.licensePlateLengthInch,
            width: v.licensePlateWidthInch,
            height: v.licensePlateHeightInch,
            totalArea: v.licensePlateTotalAreaSoft,
            palletType: v?.licensePlatePalletType && {
              value: v?.licensePlatePalletType,
              label: v?.licensePlatePalletTypeStr,
            },
          },
          kitComponents: {
            finishedGoodUOM: v.finishedGoodUOM,
            lineItems: v.kitComponents,
          },
          finishedGoodUOMKitOnDemand: v.finishedGoodUOMKitOnDemand,
        }));

        setSKUPrintModelData((prevState: SKUPrintModel) => ({
          ...prevState,
          sku: v.upc ? v.upc : v.sku,
          description: v.upc ? v.upc : v.description,
          qty: 0,
          buttonPrint: null,
        }));

        setItemCostSheetItemRows(v.itemCostSheets);

        setItemUnitOfMeasureRows(
          v.itemUnitOfMeasures.map((x) => ({
            itemUnitOfMeasureId: x.itemUnitOfMeasureId,
            itemId: x.itemId,
            itemInventoryUOM: {
              value: x.itemInventoryUOM,
              label: x.itemInventoryUOMStr,
            },
            unitsPerItem: x.unitsPerItem,
            unitsLength: x.unitsLength,
            unitsWidth: x.unitsWidth,
            unitsHeight: x.unitsHeight,
            unitsTotalArea: x.unitsTotalArea,
            unitsWeight: x.unitsWeight,
            upc: x.upc,
            name: x.name,
            itemInventoryUOMAbbr: x.itemInventoryUOMAbbr,
            isPackedAsCarton: x.isPackedAsCarton,
            itemInventoryUOMStr: x.itemInventoryUOMStr,
          })),
        );

        setSecondaryInventoryUOMChecked(v.useSecondaryInventoryUOM);

        setThisIsAKitChecked(v.isKit);
        setKitComponentItemRows(
          v.kitComponents.map((x: any) => ({
            itemKitComponentId: x.itemKitComponentId,
            sku: x.sku,
            description: x.description,
            itemId: x.itemId,
            lotId: x.lotId,
            lotNo: x.lotNo,
            lotExpiration: x.lotExpirationDate,
            qty: x.qty,
          })),
        );

        setFinishedGoodUOMValue(
          v.isKit
            ? {
                value:
                  v?.finishedGoodUOMIsPrimary === true
                    ? v?.finishedGoodUOM
                    : v?.finishedGoodUOMId,
                label: v.finishedGoodUOMStr,
              }
            : {
                value: -0,
                label: '',
              },
        );

        setUseAsKitOnDemandChecked(v.isKitOnDemand ?? false);
        setFinishedGoodUOMKitOnDemandDDL(
          v.isKitOnDemand
            ? {
                value: v.finishedGoodUOMKitOnDemand,
                label: v.finishedGoodUOMKitOnDemandStr,
              }
            : null,
        );
        setKitOnDemandItemRows(
          v.kitOnDemands.map((x: KitOnDemand) => ({
            rowId: null,
            itemKitOnDemandId: x.itemKitOnDemandId,
            itemId: x.itemId,
            sku: x.sku,
            description: x.description,
            kitSkuType: null,
            isNeedPicking: x.isNeedPicking,
            qty: x.qty,
            itemIdError: null,
            qtyError: null,
          })),
        );

        const optionsKitSku = onLoadKitSkuLookup(v.warehouseCustomerId);
        optionsKitSku.then((opt) => {
          setOptionKitSkus(
            opt.map((c: any) => ({
              label: c.sku,
              value: c.itemId,
              description: c.description,
              uom: c.uom,
              uomStr: c.uomStr,
            })),
          );
        });

        setFormDatatable((prevState) => ({
          ...prevState,
          locations: [...prevState.locations, v.customerLocations],
          suppliers: [...prevState.suppliers, v.suppliers],
          activities: [...prevState.activities, v.activities],
        }));
        if (v?.itemImage) {
          const regex = /data:.*base64,/;
          setImageDataText(v.itemImage.replace(regex, ''));
        }
      });
    } else if (pageViewer.isNew()) {
      if (queryStringItemDetails.isPackaging === true) {
        setFormValues((prevState) => ({
          ...prevState,
          desc: {
            ...prevState.desc,
            useAsPackaging: true,
          },
        }));
      }

      if (queryStringItemDetails.isVoidFill === true) {
        setFormValues((prevState) => ({
          ...prevState,
          desc: {
            ...prevState.desc,
            useAsVoidFill: true,
          },
        }));
      }
    }
  };
  const handleOnClickSaveInventoryItemDetail = async (e?: any) => {
    e?.preventDefault();

    if (!validateForm()) {
      snackActions.error('Please complete required fields');
    } else if (pageViewer.isNew()) {
      const savePayload = {
        customerId: parseInt(currentUser.Claim_CustomerId, 10),
        WarehouseCustomerId: formValues.desc.customerId,
        Status: formValues.desc.statusId,
        sku: formValues.desc.sku,
        description: formValues.desc.description,
        Classification: formValues.desc.classificationId,
        classificationText: formValues.desc.classificationText,
        inventoryType: formValues.desc.inventoryType,
        upc: formValues.desc.upc,
        SellPrice: formValues.desc.sellPrice,
        Cost: formValues.desc.cost,
        ItemImage: formValues.desc.inventoryItemImage,

        UseAsPackaging: formValues.desc.useAsPackaging,
        PackagingType: formValues.desc?.packagingType?.value,
        AcceptsVoidFill: formValues.desc?.acceptsVoidFill,
        InnerDescriptionLength: formValues.desc?.innerDescriptionLength,
        InnerDescriptionWidth: formValues.desc?.innerDescriptionWidth,
        InnerDescriptionHeight: formValues.desc?.innerDescriptionHeight,
        UseAsVoidFill: formValues.desc.useAsVoidFill,
        VoidFillQty: formValues.desc?.voidFillQty,
        BillRate: formValues.desc?.billRate,

        AllocationMethod: formValues.inventorySettings.allocationMethodId,
        DefaultPickLocationBinId:
          formValues.inventorySettings.defaultPickLocationBinId,
        BulkLocationBinId: formValues.inventorySettings.bulkLocationBinId,
        MinOnHand:
          formValues.inventorySettings.minOnHand <= 0
            ? ''
            : formValues.inventorySettings.minOnHand,
        MaxOnHand:
          formValues.inventorySettings.maxOnHand <= 0
            ? ''
            : formValues.inventorySettings.maxOnHand,
        ReplenishQty:
          formValues.inventorySettings.replenishQty <= 0
            ? ''
            : formValues.inventorySettings.replenishQty,
        LeadTime:
          formValues.inventorySettings.leadTime <= 0
            ? ''
            : formValues.inventorySettings.leadTime,
        SafetyStock:
          formValues.inventorySettings.safetyStock <= 0
            ? ''
            : formValues.inventorySettings.safetyStock,
        StorageTemperatureFahrenheit: formValues.inventorySettings.storageTemp,
        isHazmat: formValues.inventorySettings.isHazmat,
        freightClass: formValues.inventorySettings.freightClass,
        PrimaryUnitsLength:
          formValues.inventoryUnitsOfMeasure.primaryUnitsLength,
        PrimaryUnitsWidth: formValues.inventoryUnitsOfMeasure.primaryUnitsWidth,
        PrimaryUnitsHeight:
          formValues.inventoryUnitsOfMeasure.primaryUnitsHeight,
        PrimaryUnitsWeight:
          formValues.inventoryUnitsOfMeasure.primaryUnitsWeight,
        ReorderQty: formValues.inventorySettings.reorderQty,
        IsSerialNo: formValues.inventorySettings.isSerialNo,
        IsTrackableByLot: formValues.inventorySettings.isTrackableByLot,
        IsFullQuantityOnly: formValues.inventorySettings.isFullQuantityOnly,
        PrimaryInventoryUOM:
          formValues.inventoryUnitsOfMeasure.primaryInventoryUOMId,
        IsPackedAsCarton: formValues.inventoryUnitsOfMeasure.isPackedAsCarton,
        UseSecondaryInventoryUOM: secondaryInventoryUOMChecked,
        SecondaryInventoryUOM:
          formValues.inventoryUnitsOfMeasure.secondaryInventoryUOMId,
        QtyOfPrimaryUOMPerSecondary:
          formValues.inventoryUnitsOfMeasure.howManyUOMPerSecondary,
        LicensePlateType: formValues.licensePlates.palleteId,
        QtyOfPrimaryOUMPerLP: formValues.licensePlates.qty,
        LicensePlateLengthInch: formValues.licensePlates.length,
        LicensePlateWidthInch: formValues.licensePlates.width,
        LicensePlateHeightInch: formValues.licensePlates.height,
        LicensePlateTotalAreaSoft: formValues.licensePlates.totalArea,
        LicensePlateWeightPound: formValues.licensePlates.weight,
        LicensePlatePalletType: formValues?.licensePlates?.palletType?.value,
        IsKit: thisIsAKitChecked,
        FinishedGoodUOM: thisIsAKitChecked ? finishedGoodUOMValue.value : null,
        FinishedGoodUOMId:
          thisIsAKitChecked && finishedGoodUOMValue?.isPrimary === false
            ? finishedGoodUOMValue.otherUomId
            : null,
        FinishedGoodUOMIsPrimary:
          thisIsAKitChecked && finishedGoodUOMValue?.isPrimary === true,
        KitComponents: formValues.kitComponents.lineItems.map((x: any) => ({
          ItemId: x.itemId,
          LotId: x.lotId,
          lotExpirationDate: x.lotExpiration,
          Qty: x.qty,
        })),
        IsKitOnDemand: useAsKitOnDemandChecked,
        FinishedGoodUOMKitOnDemand: useAsKitOnDemandChecked
          ? finishedGoodUOMKitOnDemandDDL.value
          : null,
        KitOnDemands: useAsKitOnDemandChecked
          ? kitOnDemandItemRows.map((x: any) => ({
              ItemKitOnDemandId: x.itemKitOnDemandId,
              ItemId: x.itemId,
              IsNeedPicking: x.isNeedPicking,
              Qty: x.qty,
            }))
          : [],
        itemCostSheets: itemCostSheetItemRows,
        ItemUnitOfMeasures: itemUnitOfMeasureRows.map((x: any) => ({
          ItemInventoryUOM: x.itemInventoryUOM.value,
          UnitsPerItem: x.unitsPerItem,
          UnitsLength: x.unitsLength,
          UnitsWidth: x.unitsWidth,
          UnitsHeight: x.unitsHeight,
          UnitsTotalArea: x.unitsTotalArea,
          UnitsWeight: x.unitsWeight,
          UPC: x.upc,
          Name: x.name,
          IsPackedAsCarton: x.isPackedAsCarton,
        })),
      };

      try {
        const response = await createItemDetail(savePayload);
        snackActions.success('Successfully created new item.');
        setQueryStringItemDetails((prevState) => ({
          ...prevState,
          form: 'view',
          id: response.itemId,
        }));
        navigate(`/inventory/item-details?form=view&id=${response.itemId}`);
        viewLoadDataDetails();
      } catch (err) {
        snackActions.error(err);
      }
    } else if (pageViewer.isEdit() || pageViewer.isView()) {
      const updatePayload = {
        itemId: queryStringItemDetails.id,
        customerId: parseInt(currentUser.Claim_CustomerId, 10),
        WarehouseCustomerId: formValues.desc.customerId,
        Status: formValues.desc.statusId,
        sku: formValues.desc.sku,
        description: formValues.desc.description,
        Classification: formValues.desc.classificationId,
        classificationText: formValues.desc.classificationText,
        inventoryType: formValues.desc.inventoryType,
        upc: formValues.desc.upc,
        SellPrice: formValues.desc.sellPrice,
        Cost: formValues.desc.cost,
        ItemImage: formValues.desc.inventoryItemImage,

        UseAsPackaging: formValues.desc.useAsPackaging,
        PackagingType: formValues.desc?.packagingType?.value,
        AcceptsVoidFill: formValues.desc?.acceptsVoidFill,
        InnerDescriptionLength: formValues.desc?.innerDescriptionLength,
        InnerDescriptionWidth: formValues.desc?.innerDescriptionWidth,
        InnerDescriptionHeight: formValues.desc?.innerDescriptionHeight,
        UseAsVoidFill: formValues.desc.useAsVoidFill,
        VoidFillQty: formValues.desc?.voidFillQty,
        BillRate: formValues.desc?.billRate,

        AllocationMethod: formValues.inventorySettings.allocationMethodId,
        DefaultPickLocationBinId:
          formValues.inventorySettings.defaultPickLocationBinId,
        BulkLocationBinId: formValues.inventorySettings.bulkLocationBinId,
        MinOnHand:
          formValues.inventorySettings.minOnHand <= 0
            ? ''
            : formValues.inventorySettings.minOnHand,
        MaxOnHand:
          formValues.inventorySettings.maxOnHand <= 0
            ? ''
            : formValues.inventorySettings.maxOnHand,
        ReplenishQty:
          formValues.inventorySettings.replenishQty <= 0
            ? ''
            : formValues.inventorySettings.replenishQty,
        LeadTime:
          formValues.inventorySettings.leadTime <= 0
            ? ''
            : formValues.inventorySettings.leadTime,
        SafetyStock:
          formValues.inventorySettings.safetyStock <= 0
            ? ''
            : formValues.inventorySettings.safetyStock,
        StorageTemperatureFahrenheit: formValues.inventorySettings.storageTemp,
        isHazmat: formValues.inventorySettings.isHazmat,
        freightClass: formValues.inventorySettings.freightClass,
        ReorderQty: formValues.inventorySettings.reorderQty,
        IsSerialNo: formValues.inventorySettings.isSerialNo,
        IsTrackableByLot: formValues.inventorySettings.isTrackableByLot,
        IsFullQuantityOnly: formValues.inventorySettings.isFullQuantityOnly,
        PrimaryUnitsLength:
          formValues.inventoryUnitsOfMeasure.primaryUnitsLength,
        PrimaryUnitsWidth: formValues.inventoryUnitsOfMeasure.primaryUnitsWidth,
        PrimaryUnitsHeight:
          formValues.inventoryUnitsOfMeasure.primaryUnitsHeight,
        PrimaryUnitsWeight:
          formValues.inventoryUnitsOfMeasure.primaryUnitsWeight,
        PrimaryInventoryUOM:
          formValues.inventoryUnitsOfMeasure.primaryInventoryUOMId,
        IsPackedAsCarton: formValues.inventoryUnitsOfMeasure.isPackedAsCarton,
        UseSecondaryInventoryUOM: secondaryInventoryUOMChecked,
        SecondaryInventoryUOM:
          formValues.inventoryUnitsOfMeasure.secondaryInventoryUOMId,
        QtyOfPrimaryUOMPerSecondary:
          formValues.inventoryUnitsOfMeasure.howManyUOMPerSecondary,
        LicensePlateType: formValues.licensePlates.palleteId,
        QtyOfPrimaryOUMPerLP: formValues.licensePlates.qty,
        LicensePlateLengthInch: formValues.licensePlates.length,
        LicensePlateWidthInch: formValues.licensePlates.width,
        LicensePlateHeightInch: formValues.licensePlates.height,
        LicensePlateTotalAreaSoft: formValues.licensePlates.totalArea,
        LicensePlateWeightPound: formValues.licensePlates.weight,
        LicensePlatePalletType: formValues?.licensePlates?.palletType?.value,
        IsKit: thisIsAKitChecked,
        FinishedGoodUOM: thisIsAKitChecked ? finishedGoodUOMValue.value : null,
        FinishedGoodUOMId:
          thisIsAKitChecked && finishedGoodUOMValue?.isPrimary === false
            ? finishedGoodUOMValue?.otherUomId
            : null,
        FinishedGoodUOMIsPrimary:
          thisIsAKitChecked && finishedGoodUOMValue?.isPrimary === true,
        KitComponents: formValues.kitComponents.lineItems.map((x: any) => ({
          ItemId: x.itemId,
          LotId: x.lotId,
          lotExpirationDate: x.lotExpiration,
          Qty: x.qty,
        })),
        IsKitOnDemand: useAsKitOnDemandChecked,
        FinishedGoodUOMKitOnDemand: useAsKitOnDemandChecked
          ? finishedGoodUOMKitOnDemandDDL.value
          : null,
        KitOnDemands: useAsKitOnDemandChecked
          ? kitOnDemandItemRows.map((x: any) => ({
              ItemKitOnDemandId: x.itemKitOnDemandId,
              ItemId: x.itemId,
              IsNeedPicking: x.isNeedPicking,
              Qty: x.qty,
            }))
          : [],
        ItemCostSheets: itemCostSheetItemRows,
        ItemUnitOfMeasures: itemUnitOfMeasureRows.map((x: any) => ({
          ItemUnitOfMeasureId: x.itemUnitOfMeasureId,
          ItemInventoryUOM: x.itemInventoryUOM.value,
          UnitsPerItem: x.unitsPerItem,
          UnitsLength: x.unitsLength,
          UnitsWidth: x.unitsWidth,
          UnitsHeight: x.unitsHeight,
          UnitsTotalArea: x.unitsTotalArea,
          UnitsWeight: x.unitsWeight,
          UPC: x.upc,
          Name: x.name,
          IsPackedAsCarton: x.isPackedAsCarton,
        })),
      };
      try {
        await updateItemDetail(updatePayload);
        snackActions.success('Successfully update the item.');

        viewLoadDataDetails();

        if (pageViewer.isEdit()) {
          setQueryStringItemDetails((prevState) => ({
            ...prevState,
            form: 'view',
          }));
          navigate(
            `/inventory/item-details?form=view&id=${queryStringItemDetails.id}`,
          );
        }
      } catch (err) {
        snackActions.error(err);
      }
    }
  };

  const OnClickAddRecordItemCostSheet = async () => {
    let index;
    if (
      itemCostSheetSingleData.supplierId !== -1 &&
      itemCostSheetSingleData.supplierId
    ) {
      const PAYLOAD = {
        itemId: queryStringItemDetails.id,
        customerId: parseInt(currentUser.Claim_CustomerId, 10),
        supplierId: itemCostSheetSingleData.supplierId,
      };
      try {
        const isExistSupplier = [...formDatatable.suppliers];
        index = isExistSupplier[0].find(
          (v) => v.supplierId === itemCostSheetSingleData.supplierId,
        );
        if (index === undefined) {
          await createItemSupplierFromInventory(PAYLOAD);
        }
      } catch (err) {
        snackActions.error(err);
      }
    }

    handleOnClickSaveInventoryItemDetail();

    setItemCostSheetSingleData({});
    onCloseItemCostSheetModal();
    if (
      index === undefined &&
      itemCostSheetSingleData.supplierId !== -1 &&
      itemCostSheetSingleData.supplierId
    ) {
      handleUpdateData();
    }
  };

  useEffect(() => {
    if (saveCostSheetTrigger) {
      OnClickAddRecordItemCostSheet();
      setSaveCostSheetTrigger(false);
    }
  }, [saveCostSheetTrigger]);

  // this use effect fires only once, at page init
  const [isPageInitialized, setIsPageInitialized] = useState<boolean>(false);
  useEffect(() => {
    if (
      isPageInitialized === false &&
      state.pageLoadState === PageLoadStateEnum.LOAD_FINISH
    ) {
      const optionsDefaultpickLocation = onLoadCustomerBins();
      optionsDefaultpickLocation
        .then((opt) => {
          setOptionDefaultpickLocations(
            opt
              .filter((x) => x.category === 2)
              .map((c: any) => ({
                label: c.name,
                value: c.binId,
              })),
          );

          setOptionBulkLocations(
            opt.map((c: any) => ({
              label: c.name,
              value: c.binId,
            })),
          );
        })
        .catch();

      const optionsCustomer = onLoadWarehouseCustomerLookup();
      optionsCustomer
        .then((opt) => {
          setOptionsWarehouseCustomers(
            opt.map((c: any) => ({
              label: c.name,
              value: c.warehouseCustomerId,
            })),
          );
        })
        .catch();

      const optionsClassification = onLoadClassificationLookup();
      optionsClassification.then((opt) => {
        setOptionClassifications(
          opt.map((c: any) => ({
            label: c.name,
            value: c.id,
          })),
        );
      });

      const optionsAllocationMethodType = onLoadAllocationMethodTypeLookup();
      optionsAllocationMethodType.then((opt) => {
        setOptionAllocationMethodTypes(
          opt.map((c: any) => ({
            label: c.name,
            value: c.id,
          })),
        );
      });

      const optionsPrimaryInventoryUOM = onLoadPrimaryInventoryUOMLookup();
      optionsPrimaryInventoryUOM.then((opt) => {
        setOptionPrimaryInventoryUOM(
          opt.map((c: any) => ({
            label: c.name,
            value: c.id,
          })),
        );
        setOptionFinishedGoodUOM(
          opt.map((c: any) => ({
            label: c.name,
            value: c.id,
          })),
        );
      });

      const optionsStatus = onLoadStatusLookup();
      optionsStatus.then((opt) => {
        setOptionStatus(
          opt.map((c: any) => ({
            label: c.name,
            value: c.id,
          })),
        );
      });

      const optionsInventoryTypes = onLoadInventoryTypes();
      optionsInventoryTypes.then((opt) => {
        setOptionInventoryTypes(
          opt.map((c: any) => ({
            label: c.name,
            value: c.id,
          })),
        );
      });

      const optionsPackagingUOM = onLoadPackagingUOMLookup();
      optionsPackagingUOM.then((opt) => {
        setOptionPackagingUOM(
          opt.map((c: any) => ({
            label: c.name,
            value: c.id,
          })),
        );
      });

      const optionsLicensePlateType = onLoadLicensePlateTypeLookup();
      optionsLicensePlateType.then((opt) => {
        setOptionLicensePlateTypes(
          opt.map((c: any) => ({
            label: c.name,
            value: c.id,
          })),
        );
      });

      viewLoadDataDetails();

      setIsPageInitialized(true);
    }
  }, [isPageInitialized, state]);

  useEffect(() => {
    if (formValues?.desc?.customerId) {
      const optionsKitSku = onLoadKitSkuLookup(formValues?.desc?.customerId);
      optionsKitSku.then((opt) => {
        setOptionKitSkus(
          opt.map((c: any) => ({
            label: c.sku,
            value: c.itemId,
            description: c.description,
            uom: c.uom,
            uomStr: c.uomStr,
          })),
        );
      });
    }
  }, [formValues?.desc?.customerId]);

  useEffect(() => {
    if (primaryInventoryUOMValue) {
      const setKitUom = [
        {
          label: primaryInventoryUOMValue?.label,
          value: primaryInventoryUOMValue?.value,
          otherUomId: null,
          isPrimary: true,
        },
      ];

      if (itemUnitOfMeasureRows?.length > 0) {
        const otherUnits = itemUnitOfMeasureRows?.map((c: any) => ({
          label: c?.name ? c.name : c.itemInventoryUOMStr,
          value: c.itemInventoryUOM?.value,
          otherUomId: c.itemUnitOfMeasureId,
          isPrimary: false,
        }));
        setOptionFinishedGoodUOM([...setKitUom, ...otherUnits]);
        return;
      }

      setOptionFinishedGoodUOM([...setKitUom]);
    }
  }, [primaryInventoryUOMValue, itemUnitOfMeasureRows]);

  useEffect(() => {
    if (optionPrimaryInventoryUOM?.length > 0) {
      if (queryStringItemDetails.form === 'new') {
        const vmPrimary = optionPrimaryInventoryUOM.find(
          (o) =>
            o.value ===
            formValues.inventoryUnitsOfMeasure.primaryInventoryUOMId,
        );

        if (vmPrimary !== undefined) {
          setPrimaryInventoryUOMValue(vmPrimary);
        }
      }

      if (isViewingOrEditing) {
        const vmPrimary = optionPrimaryInventoryUOM.find(
          (o) =>
            o.value ===
            formValues.inventoryUnitsOfMeasure.primaryInventoryUOMId,
        );

        if (vmPrimary !== undefined) {
          setPrimaryInventoryUOMValue(vmPrimary);
        }
      }
    }
  }, [optionPrimaryInventoryUOM, queryStringItemDetails]);

  const reloadItemImage = (base64Image) => {
    setFormValues((prevState) => ({
      ...prevState,
      desc: {
        ...prevState.desc,
        itemImage: base64Image,
      },
    }));
  };

  const [itemSummary, setItemSummary] = useState<any>({});
  const onLoadItemDetailSummary = async () => {
    try {
      const itemSuppliersAndLocationsFromApi = await getItemDetail(
        currentUser.Claim_CustomerId,
        !isWarehouseCustomerAccount
          ? currentLocationAndFacility.customerFacilityId
          : 0,
        queryStringItemDetails.id,
      );
      setItemSummary(itemSuppliersAndLocationsFromApi.summary);

      return true;
    } catch (error) {
      return error;
    }
  };

  const itemEcommerceSync = () => {
    itemEcommerceSyncQty(queryStringItemDetails.id)
      .then(() => {
        snackActions.success('Item synced succesfully');
      })
      .catch((err) => {
        snackActions.error(err);
      });
  };

  const onClickDeleteInventories = async () => {
    const ids = selectedToDeleteRows.map((row) => row || null).join(',');

    await getDeleteInventories(
      currentUser.Claim_CustomerId,
      currentLocationAndFacility.customerFacilityId,
      queryStringItemDetails.id,
      ids,
    ).then(() => {
      onLoadItemDetailInventory().then(() => {
        snackActions.success('Selected Inventory rows have been deleted');
      });
    });
  };

  // page load
  useEffect(() => {
    if (state.pageLoadState === PageLoadStateEnum.LOAD_NONE) {
      setState((prev) => ({
        ...prev,
        pageLoadState: PageLoadStateEnum.LOAD_START,
      }));
    }
  }, [state.pageLoadState]);
  useEffect(() => {
    if (state.pageLoadState === PageLoadStateEnum.LOAD_START) {
      setState((prev) => ({
        ...prev,
        pageLoadState: PageLoadStateEnum.LOAD_FINISH,
      }));
    }
  }, [state.pageLoadState, currentLocationAndFacility]);

  // facility change
  useEffect(() => {
    // this will automatically trigger viewLoadData
    setState((prev) => ({
      ...prev,
      pageLoadState: PageLoadStateEnum.LOAD_START,
    }));
  }, [currentLocationAndFacility]);

  const value = useMemo(
    () => ({
      currentUser,
      optionDefaultpickLocations,
      optionBulkLocations,
      optionWarehouseCustomers,
      optionStatus,
      optionClassifications,
      optionAllocationMethodTypes,
      optionPrimaryInventoryUOM,
      optionPackagingUOM,
      optionLicensePlateTypes,
      queryStringItemDetails,
      setQueryStringItemDetails,
      formErrors,
      formValues,
      formDatatable,
      isViewingOrEditing,
      secondaryInventoryUOMChecked,
      setSecondaryInventoryUOMChecked,
      thisIsAKitChecked,
      setThisIsAKitChecked,
      optionFinishedGoodUOM,
      setFormValues,
      validateForm,
      handleOnChange,
      handleOnClickSaveInventoryItemDetail,
      modalTitle,
      setModalTitle,
      optionKitSkus,
      setOptionKitSkus,
      kitComponentItemRows,
      setKitComponentItemRows,
      kitComponentSingleItemRow,
      itemCostSheetItemRows,
      setKitComponentSingleItemRow,
      setItemCostSheetItemRows,
      kitSkuValue,
      setKitSkuValue,
      useAsKitOnDemandChecked,
      setUseAsKitOnDemandChecked,
      finishedGoodUOMKitOnDemandDDL,
      setFinishedGoodUOMKitOnDemandDDL,
      kitOnDemandItemRows,
      setKitOnDemandItemRows,
      kitOnDemandSelectedItemRow,
      setKitOnDemandSelectedItemRow,
      pageViewer,
      finishedGoodUOMValue,
      setFinishedGoodUOMValue,
      deleteKitLineItemComponentOnClick,
      OnClickAddRecordItemCostSheet,
      setItemCostSheetSingleData,
      itemCostSheetSingleData,
      viewLoadDataDetails,
      reloadItemImage,
      itemUnitOfMeasureRows,
      setItemUnitOfMeasureRows,
      setSaveCostSheetTrigger,
      locationDataToPrint,
      setLocationDataToPrint,
      lpReceiptLineItemIdToPrint,
      setLPReceiptLineItemIdToPrint,
      tabValue,
      setTabValue,
      handleTabOnChange,
      optionInventoryTypes,
      setOptionInventoryTypes,
      imageDataText,
      setImageDataText,
      filteredItemLoc,
      setFilteredItemLoc,
      itemLocations,
      setItemLocations,
      isSerialNo,
      setIsSerialNo,
      isTrackableByLot,
      setIsTrackableByLot,
      onLoadItemDetailInventory,
      onLoadItemDetailSummary,
      itemSummary,
      setItemSummary,
      itemEcommerceSync,
      isUsingLicensePlateId,
      setIsUsingLicensePlateId,
      selectedToDeleteRows,
      setSelectedToDeleteRows,
      onClickDeleteInventories,
      primaryInventoryUOMValue,
      setPrimaryInventoryUOMValue,
      state,
      setState,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      primaryInventoryUOMValue,
      selectedToDeleteRows,
      isUsingLicensePlateId,
      filteredItemLoc,
      itemLocations,
      isSerialNo,
      isTrackableByLot,
      formValues,
      isViewingOrEditing,
      secondaryInventoryUOMChecked,
      thisIsAKitChecked,
      queryStringItemDetails,
      isViewingOrEditing,
      modalTitle,
      optionKitSkus,
      kitComponentItemRows,
      kitSkuValue,
      finishedGoodUOMValue,
      setFormValues,
      validateForm,
      setKitComponentItemRows,
      kitComponentSingleItemRow,
      itemCostSheetItemRows,
      itemUnitOfMeasureRows,
      itemCostSheetSingleData,
      setKitComponentSingleItemRow,
      useAsKitOnDemandChecked,
      finishedGoodUOMKitOnDemandDDL,
      kitOnDemandItemRows,
      setKitOnDemandItemRows,
      kitOnDemandSelectedItemRow,
      setKitOnDemandSelectedItemRow,
      setModalTitle,
      setQueryStringItemDetails,
      reloadItemImage,
      locationDataToPrint,
      lpReceiptLineItemIdToPrint,
      tabValue,
      optionInventoryTypes,
      imageDataText,
      itemSummary,
      itemEcommerceSync,
      optionDefaultpickLocations,
    ],
  );

  return (
    <InventoryItemDetailsContext.Provider value={value}>
      {children}
    </InventoryItemDetailsContext.Provider>
  );
};

const InventoryDescription = () => {
  const {
    formValues,
    formErrors,
    pageViewer,
    currentUser,
    optionStatus,
    setFormValues,
    imageDataText,
    handleOnChange,
    reloadItemImage,
    thisIsAKitChecked,
    setThisIsAKitChecked,
    optionInventoryTypes,
    optionClassifications,
    queryStringItemDetails,
    useAsKitOnDemandChecked,
    optionWarehouseCustomers,
    optionPrimaryInventoryUOM,
    setTabValue,
    setImageDataText,
    setUseAsKitOnDemandChecked,
    setOptionKitSkus,
  } = useInventoryItemDetailsContext();

  const { handleUserPermissionAllow } = useContext(AuthContext);

  const [customerValue, setCustomerValue] = useState<CustomerType>(null);
  const [inventoryTypeValue, setInventoryTypeValue] =
    useState<InventoryTypes>(null);
  const [statusValue, setStatusValue] = useState<StatusType>(null);
  const [primaryUOMString, setPrimaryUOMString] = useState('');
  const [classificationValue, setClassificationValue] =
    useState<ClassificationType>(null);

  const { onOpenItemPictureModal, onOpenConfirmationModal } =
    useContext(GlobalContext);

  const handleImageClick = () => {
    onOpenItemPictureModal();
  };

  const handleOnChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    if (type === 'isKit') {
      setThisIsAKitChecked(event.target.checked);
    } else if (type === 'isKitOnDemand') {
      setUseAsKitOnDemandChecked(event.target.checked);
    }
  };

  const onLoadKitSkuLookup = async (warehouseCustomerId) => {
    try {
      return await getKitLineItemLookup(warehouseCustomerId, '');
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    if (queryStringItemDetails.form === 'new') {
      if (optionWarehouseCustomers.length !== 0) {
        if (optionWarehouseCustomers.length === 1) {
          const result = optionWarehouseCustomers.find(
            (o) => o.label === currentUser.Claim_CustomerName,
          );
          setCustomerValue(result);
          setFormValues((prevState) => ({
            ...prevState,
            desc: {
              ...prevState.desc,
              customer: result.label,
              customerId: result.value,
            },
          }));
        }
      }

      if (optionStatus.length !== 0) {
        const result = optionStatus.find((o) => o.label === 'Active');
        if (result !== undefined) {
          setStatusValue(result);
        }
      }

      if (optionClassifications.length !== 0) {
        const result = optionClassifications.find(
          (o) => o.label === 'Unspecified',
        );
        if (result !== undefined) {
          setClassificationValue(result);
          setFormValues((prevState) => ({
            ...prevState,
            desc: {
              ...prevState.desc,
              classificationId: result.value,
            },
          }));
        }
      }
    }

    if (pageViewer.isViewOrEdit()) {
      if (optionStatus.length !== 0 || optionWarehouseCustomers.length !== 0) {
        const vmCustomer = optionWarehouseCustomers.find(
          (o) => o.value === formValues.desc.customerId,
        );
        const vmStatus = optionStatus.find(
          (o) => o.value === formValues.desc.statusId,
        );

        const vmClassification = optionClassifications.find(
          (o) => o.value === formValues.desc.classificationId,
        );

        const vmInventoryType = optionInventoryTypes.find(
          (o) => o.value === formValues.desc.inventoryType,
        );

        if (vmCustomer !== undefined) {
          setFormValues((prevState) => ({
            ...prevState,
            desc: {
              ...prevState.desc,
              customer: vmCustomer.label,
              customerId: vmCustomer.value,
            },
          }));

          setCustomerValue(vmCustomer);
        }

        if (vmStatus !== undefined) {
          setStatusValue(vmStatus);
        }

        if (vmClassification !== undefined) {
          setClassificationValue(vmClassification);
        }

        if (vmInventoryType !== undefined) {
          setInventoryTypeValue(vmInventoryType);
        }
      }
    }

    if (optionPrimaryInventoryUOM.length !== 0) {
      const vmPrimary = optionPrimaryInventoryUOM.find(
        (o) =>
          o.value === formValues.inventoryUnitsOfMeasure.primaryInventoryUOMId,
      );

      if (vmPrimary !== undefined) {
        setPrimaryUOMString(vmPrimary.label);
      }
    }
  }, [
    optionStatus,
    optionWarehouseCustomers,
    queryStringItemDetails,
    formValues.desc.customerId,
  ]);

  useEffect(() => {
    if (formValues?.desc?.customerId) {
      const result = optionWarehouseCustomers.find(
        (o) => o.value === formValues?.desc?.customerId,
      );
      setCustomerValue(result);
    }
  }, [formValues?.desc?.customerId]);

  const deleteImageCallback = async () => {
    try {
      await deleteItemImage(queryStringItemDetails.id);
      reloadItemImage('');
      snackActions.success('Image removed.');
    } catch (error) {
      snackActions.error(error);
    }
  };

  const onImageChange = (event) => {
    const MIN_FILE_SIZE = 1024; // 1MB

    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size / 1024 > MIN_FILE_SIZE) {
        // eslint-disable-next-line no-alert
        window.alert('Please upload a file smaller than 1 MB');
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          const regex = /data:.*base64,/;
          setImageDataText(reader.result.toString().replace(regex, ''));

          const data = {} as InventoryItemImageType;
          data.base64Data = reader.result.toString();

          const base64 = `${reader.result}`.split(';base64,').pop();
          const File = {
            name: event.target.files[0].name,
            content: base64,
            size: event.target.files[0].size,
          } as FileType;
          data.file = File;

          setFormValues((prevState) => ({
            ...prevState,
            desc: {
              ...prevState.desc,
              inventoryItemImage: data,
            },
          }));
        };
      }
    }
  };

  return (
    <>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={4}>
          <Typography variant="h6" fontWeight="bold">
            Description
          </Typography>
        </MUIGrid>
        <MUIGrid item xs={8} sx={{ textAlignLast: 'right' }}>
          {!formValues.desc?.useAsPackaging &&
            useAsKitOnDemandChecked === false &&
            thisIsAKitChecked === false && (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setFormValues((prevState) => ({
                        ...prevState,
                        desc: {
                          ...prevState.desc,
                          useAsVoidFill: event.target.checked,
                        },
                      }))
                    }
                    checked={formValues.desc?.useAsVoidFill}
                  />
                }
                label="Use as Void Fill"
                {...(pageViewer.isView() && {
                  disabled: true,
                })}
              />
            )}

          {handleUserPermissionAllow(UserPermissionEnum.Inventory_AddPackage) &&
            useAsKitOnDemandChecked === false &&
            thisIsAKitChecked === false &&
            !formValues.desc?.useAsVoidFill && (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setFormValues((prevState) => ({
                        ...prevState,
                        desc: {
                          ...prevState.desc,
                          useAsPackaging: event.target.checked,
                        },
                      }))
                    }
                    checked={formValues.desc?.useAsPackaging}
                  />
                }
                label="Use as Packaging"
                {...(pageViewer.isView() && {
                  disabled: true,
                })}
              />
            )}

          {!formValues.desc?.useAsPackaging && !formValues.desc?.useAsVoidFill && (
            <>
              {handleUserPermissionAllow(
                UserPermissionEnum.Inventory_AddKitOnDemand,
              ) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleOnChangeCheckbox(event, 'isKitOnDemand');
                        if (event.target.checked === true) {
                          setTabValue('4');
                        }
                      }}
                      checked={useAsKitOnDemandChecked}
                    />
                  }
                  label="Use as Bundle"
                  {...(pageViewer.isView() && {
                    disabled: true,
                  })}
                />
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleOnChangeCheckbox(event, 'isKit');
                      if (event.target.checked === true) {
                        setTabValue('3');
                      }
                    }}
                    checked={thisIsAKitChecked}
                  />
                }
                label="This is a Kit"
                {...(pageViewer.isView() && {
                  disabled: true,
                })}
              />
            </>
          )}
        </MUIGrid>
        <MUIGrid item xs={2}>
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={6}>
              <ButtonMui
                sx={{
                  height: '90px',
                  maxWidth: '100%',
                  width: '100px',
                  border: '2px dashed #4171ff !important',
                  backgroundColor: 'rgb(216 232 255) !important',
                  borderRadius: '5px !important',
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignContent: 'center',
                }}
                size="medium"
                variant="contained"
                component="label"
                disabled={pageViewer.isView()}
              >
                <UploadFileIcon
                  sx={{ fontSize: '40px', color: 'black !important', mb: 1 }}
                />
                <Typography
                  sx={{ fontSize: '7.5px', color: 'black !important' }}
                  fontWeight="bold"
                >
                  Item Image
                </Typography>
                <input
                  hidden
                  type="file"
                  onChange={onImageChange}
                  className="filetype"
                  accept="image/png, image/jpeg, image/png"
                />
              </ButtonMui>
            </MUIGrid>
            <MUIGrid item xs={6}>
              {imageDataText ? (
                <input
                  disabled
                  type="image"
                  src={`data:image/jpeg;base64,${imageDataText}`}
                  alt="preview image"
                  width="90"
                  accept="image/*"
                />
              ) : (
                <>
                  <Typography fontWeight="bold" variant="subtitle2">
                    Upload Item Image
                  </Typography>
                  <Typography sx={{ fontSize: 'x-small', color: 'red' }}>
                    (Only JPG & PNG)
                  </Typography>
                </>
              )}
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>

        <MUIGrid item xs={10}>
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={3}>
              <Autocomplete
                onChange={(event: any, newValue: CustomerType) => {
                  if (newValue !== null) {
                    setCustomerValue(newValue);
                    setFormValues((prevState) => ({
                      ...prevState,
                      desc: {
                        ...prevState.desc,
                        customer: newValue.label,
                        customerId: newValue.value,
                      },
                    }));

                    const optionsKitSku = onLoadKitSkuLookup(newValue.value);
                    optionsKitSku.then((opt) => {
                      setOptionKitSkus(
                        opt.map((c: any) => ({
                          label: c.sku,
                          value: c.itemId,
                          description: c.description,
                          uom: c.uom,
                          uomStr: c.uomStr,
                        })),
                      );
                    });
                  } else {
                    setCustomerValue(null);
                    setOptionKitSkus([]);
                  }
                }}
                id="controllable-states"
                options={optionWarehouseCustomers}
                getOptionLabel={(option: CustomerType) => option.label}
                value={customerValue}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    {...(formErrors.customer && {
                      error: true,
                      helperText: formErrors.customer,
                    })}
                    id="outlined-required"
                    label="Client"
                    size="small"
                    type="text"
                    sx={{ width: '100%' }}
                  />
                )}
                {...((queryStringItemDetails.form === 'view' ||
                  queryStringItemDetails.form === 'edit') &&
                  parseInt(queryStringItemDetails.id, 10) > 0 && {
                    disabled: true,
                  })}
              />
            </MUIGrid>
            <MUIGrid item xs={3}>
              <TextField
                inputProps={{
                  'data-state': 'sku',
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                autoComplete="off"
                required
                id="outlined-required"
                label="SKU"
                size="small"
                name="desc"
                value={formValues.desc.sku || ''}
                disabled={formValues.desc.disableSkuUpdate}
                onChange={(e) => handleOnChange(e)}
                {...(formErrors.sku && {
                  error: true,
                  helperText: formErrors.sku,
                })}
                sx={{ width: '100%' }}
                {...(queryStringItemDetails.form === 'view' &&
                  parseInt(queryStringItemDetails.id, 10) > 0 && {
                    disabled: true,
                  })}
              />
            </MUIGrid>
            <MUIGrid item xs={3}>
              <TextField
                inputProps={{
                  'data-state': 'description',
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                autoComplete="off"
                required
                id="outlined-required"
                label="Description"
                size="small"
                name="desc"
                type="text"
                value={formValues.desc.description || ''}
                onChange={(e) => handleOnChange(e)}
                {...(formErrors.description && {
                  error: true,
                  helperText: formErrors.description,
                })}
                sx={{ width: '100%' }}
                {...(queryStringItemDetails.form === 'view' &&
                  parseInt(queryStringItemDetails.id, 10) > 0 && {
                    disabled: true,
                  })}
              />
            </MUIGrid>
            <MUIGrid item xs={3}>
              <Autocomplete
                onChange={(event: any, newValue: StatusType) => {
                  if (newValue !== null) {
                    setStatusValue(newValue);
                    setFormValues((prevState) => ({
                      ...prevState,
                      desc: {
                        ...prevState.desc,
                        statusId: newValue.value,
                      },
                    }));
                  }
                }}
                id="controllable-states"
                options={optionStatus}
                getOptionLabel={(option: StatusType) => option.label}
                value={statusValue}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined"
                    label="Status"
                    size="small"
                    sx={{ width: '100%' }}
                  />
                )}
                {...(queryStringItemDetails.form === 'view' &&
                  parseInt(queryStringItemDetails.id, 10) > 0 && {
                    disabled: true,
                  })}
              />
            </MUIGrid>
            {primaryUOMString && (
              <MUIGrid item xs={1.5} sx={{ textAlign: 'center' }}>
                <Typography fontWeight="bold" variant="subtitle2">
                  Primary UOM: <br /> {primaryUOMString}
                </Typography>
              </MUIGrid>
            )}
            <MUIGrid item xs={1.5}>
              <TextField
                inputProps={{
                  'data-state': 'billRate',
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                autoComplete="off"
                id="outlined"
                label="Bill Rate"
                size="small"
                name="desc"
                value={formValues.desc.billRate || ''}
                onChange={(e) => {
                  const validateDecimal = decimalValidationHelper(
                    e.target.value,
                  );
                  setFormValues((prevState) => ({
                    ...prevState,
                    desc: {
                      ...prevState.desc,
                      billRate: validateDecimal,
                    },
                  }));
                }}
                sx={{ width: '100%' }}
                {...(queryStringItemDetails.form === 'view' &&
                  parseInt(queryStringItemDetails.id, 10) > 0 && {
                    disabled: true,
                  })}
              />
            </MUIGrid>
            <MUIGrid item xs={1.9}>
              <TextField
                inputProps={{
                  'data-state': 'classificationText',
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                autoComplete="off"
                id="outlined"
                label="Classification"
                size="small"
                name="desc"
                value={formValues.desc.classificationText || ''}
                onChange={(e) => handleOnChange(e)}
                sx={{ width: '100%' }}
                {...(queryStringItemDetails.form === 'view' &&
                  parseInt(queryStringItemDetails.id, 10) > 0 && {
                    disabled: true,
                  })}
              />
            </MUIGrid>
            <MUIGrid item xs={2.1}>
              <Autocomplete
                onChange={(event: any, newValue: InventoryTypes) => {
                  if (newValue !== null) {
                    setInventoryTypeValue(newValue);
                    setFormValues((prevState) => ({
                      ...prevState,
                      desc: {
                        ...prevState.desc,
                        inventoryType: newValue.value,
                      },
                    }));
                  } else {
                    setInventoryTypeValue(null);
                    setFormValues((prevState) => ({
                      ...prevState,
                      desc: {
                        ...prevState.desc,
                        inventoryType: null,
                      },
                    }));
                  }
                }}
                id="controllable-states"
                options={optionInventoryTypes}
                getOptionLabel={(option: InventoryTypes) => option.label}
                value={inventoryTypeValue}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined-required"
                    label="Type"
                    size="small"
                    type="text"
                    sx={{ width: '100%' }}
                  />
                )}
                {...(queryStringItemDetails.form === 'view' &&
                  parseInt(queryStringItemDetails.id, 10) > 0 && {
                    disabled: true,
                  })}
              />
            </MUIGrid>
            <MUIGrid item xs={2}>
              <TextField
                id="outlined"
                label="UPC"
                size="small"
                name="upc"
                value={formValues.desc.upc}
                onChange={(e) => {
                  setFormValues((prev) => ({
                    ...prev,
                    desc: { ...prev.desc, upc: e.target.value },
                  }));
                }}
                sx={{ width: '100%' }}
                {...(queryStringItemDetails.form === 'view' &&
                  parseInt(queryStringItemDetails.id, 10) > 0 && {
                    disabled: true,
                  })}
              />
            </MUIGrid>
            <MUIGrid item xs={1.5} sx={{ textAlign: 'center' }}>
              <Typography fontWeight="bold" variant="subtitle2">
                Average Cost
                <br />
                {formValues.desc.averageCost
                  ? `$${formValues.desc.averageCost.toFixed(2)}`
                  : '$0.00'}
              </Typography>
            </MUIGrid>
            <MUIGrid item xs={1.5} sx={{ textAlign: 'center' }}>
              <Typography fontWeight="bold" variant="subtitle2">
                Average Sell Price <br />
                {formValues.desc.averageSellPrice
                  ? `$${formValues.desc.averageSellPrice.toFixed(2)}`
                  : '$0.00'}
              </Typography>
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
      </MUIGrid>
      <ConfirmationModal
        dialogText="Do you want to remove this image?"
        callBack={deleteImageCallback}
      />
    </>
  );
};

const InventoryUnitsOfMeasure = () => {
  const {
    queryStringItemDetails,
    optionPrimaryInventoryUOM,
    formValues,
    setFormValues,
    isViewingOrEditing,
    itemUnitOfMeasureRows,
    setItemUnitOfMeasureRows,
    pageViewer,
    primaryInventoryUOMValue,
    setPrimaryInventoryUOMValue,
  } = useInventoryItemDetailsContext();

  const addItemUnitOfMeasure = () => {
    const newfield: IUnitOfMeasure = {
      itemInventoryUOM: { value: -1, label: '' },
      unitsPerItem: '',
      unitsLength: '',
      unitsWidth: '',
      unitsHeight: '',
      unitsTotalArea: '',
      unitsWeight: '',
      upc: '',
      name: '',
    };
    setItemUnitOfMeasureRows([...itemUnitOfMeasureRows, newfield]);
  };

  return (
    <>
      <MUIGrid container spacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid
          item
          xs={8}
          marginTop={-2}
          marginBottom={1}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography variant="subtitle1" fontWeight="bold">
            Inventory Units of Measure
          </Typography>
          <MUIGrid item xs={4} paddingLeft={2}>
            <Box sx={{ gap: '5px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      setFormValues((prevState) => ({
                        ...prevState,
                        inventoryUnitsOfMeasure: {
                          ...prevState.inventoryUnitsOfMeasure,
                          isPackedAsCarton: e.target.checked,
                        },
                      }));
                    }}
                    checked={
                      formValues.inventoryUnitsOfMeasure.isPackedAsCarton ||
                      false
                    }
                    {...(queryStringItemDetails.form === 'view' &&
                      parseInt(queryStringItemDetails.id, 10) > 0 && {
                        disabled: true,
                      })}
                  />
                }
                label="Is Packed as Carton"
                {...(queryStringItemDetails.form === 'view' &&
                  parseInt(queryStringItemDetails.id, 10) > 0 && {
                    disabled: true,
                  })}
              />
              <Tooltip
                title="Selecting this option will make the item packed as a carton."
                placement="bottom"
                arrow
              >
                <IconButton aria-label="print" color="primary">
                  <HelpOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </MUIGrid>
        </MUIGrid>

        <MUIGrid
          sx={{ textAlign: 'right' }}
          item
          xs={4}
          marginTop={-2}
          marginBottom={1}
        >
          <Button
            variant="outlined"
            size="small"
            sx={{
              padding: '8px !important;',
              textTransform: 'capitalize !important',
              display: pageViewer.isView() ? ' none' : '',
            }}
            onClick={() => addItemUnitOfMeasure()}
          >
            <AddIcon />
          </Button>
        </MUIGrid>
      </MUIGrid>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={3}>
          <Autocomplete
            onChange={(event: any, newValue: PrimaryInventoryUOMType) => {
              if (newValue !== null) {
                setPrimaryInventoryUOMValue(newValue);
                setFormValues((prevState) => ({
                  ...prevState,
                  inventoryUnitsOfMeasure: {
                    ...prevState.inventoryUnitsOfMeasure,
                    primaryInventoryUOMId: newValue.value,
                  },
                }));
              } else {
                setPrimaryInventoryUOMValue(null);
              }
            }}
            id="controllable-states"
            options={optionPrimaryInventoryUOM}
            getOptionLabel={(option: PrimaryInventoryUOMType) => option.label}
            value={primaryInventoryUOMValue}
            isOptionEqualToValue={(option, selected) =>
              option.value === selected.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                autoComplete="off"
                id="outlined"
                label="Primary Inventory UOM"
                size="small"
                sx={{ width: '100%' }}
              />
            )}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>

        <MUIGrid item xs={2.25}>
          <Input
            name="unitsLength"
            type="number"
            size="small"
            InputProps={{
              min: '0',
              'data-state': 'length',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            placeholder="Length"
            value={formValues.inventoryUnitsOfMeasure.primaryUnitsLength}
            onChange={(event: any) => {
              setFormValues((prev) => ({
                ...prev,
                inventoryUnitsOfMeasure: {
                  ...prev.inventoryUnitsOfMeasure,
                  primaryUnitsLength: event.target.value,
                },
              }));
            }}
            sx={{ width: '100%' }}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
        <MUIGrid item xs={2.25}>
          <Input
            name="unitsWidth"
            type="number"
            size="small"
            InputProps={{
              min: '0',
              'data-state': 'length',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            placeholder="Width"
            value={formValues.inventoryUnitsOfMeasure.primaryUnitsWidth}
            onChange={(event: any) => {
              setFormValues((prev) => ({
                ...prev,
                inventoryUnitsOfMeasure: {
                  ...prev.inventoryUnitsOfMeasure,
                  primaryUnitsWidth: event.target.value,
                },
              }));
            }}
            sx={{ width: '100%' }}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
        <MUIGrid item xs={2.25}>
          <Input
            name="unitsHeight"
            type="number"
            size="small"
            InputProps={{
              min: '0',
              'data-state': 'height',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            placeholder="Height"
            value={formValues.inventoryUnitsOfMeasure.primaryUnitsHeight}
            onChange={(event: any) => {
              setFormValues((prev) => ({
                ...prev,
                inventoryUnitsOfMeasure: {
                  ...prev.inventoryUnitsOfMeasure,
                  primaryUnitsHeight: event.target.value,
                },
              }));
            }}
            sx={{ width: '100%' }}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
        <MUIGrid item xs={2.25}>
          <Input
            name="unitsWeight"
            type="number"
            size="small"
            InputProps={{
              min: '0',
              'data-state': 'weight',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            placeholder="Weight (lbs)"
            value={formValues.inventoryUnitsOfMeasure.primaryUnitsWeight}
            onChange={(event: any) => {
              setFormValues((prev) => ({
                ...prev,
                inventoryUnitsOfMeasure: {
                  ...prev.inventoryUnitsOfMeasure,
                  primaryUnitsWeight: event.target.value,
                },
              }));
            }}
            sx={{ width: '100%' }}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
      </MUIGrid>
    </>
  );
};

const InventoryUnitsOfMeasureCards = () => {
  const {
    formValues,
    itemUnitOfMeasureRows,
    setItemUnitOfMeasureRows,
    queryStringItemDetails,
    pageViewer,
    optionPrimaryInventoryUOM,
  } = useInventoryItemDetailsContext();

  const handleFormChangeUnitOfMeasure = (
    arrayInput,
    index,
    event?,
    newValueDDL?,
    targetName?,
  ) => {
    const datas = [...arrayInput];
    const data = { ...datas[index] };

    if (targetName === 'UnitOfMeasure') {
      data.itemInventoryUOM = newValueDDL;
    } else {
      data[event.target.name] = event.target.value;
    }

    datas[index] = data;
    setItemUnitOfMeasureRows(datas);
  };

  const removeUnitOfMeasure = (index) => {
    const data = [...itemUnitOfMeasureRows];
    data.splice(index, 1);
    setItemUnitOfMeasureRows(data);
  };

  // print uom -----------------------------------------------
  const [uomPrintData, setUomPrintData] = useState(null);

  const componentPrintRefUom = useRef(null);
  const buttonPrintRefUom = useRef(null);

  const batchPrintUomCallback = (data) => {
    const aux = {
      labelToPrint: [],
      button: null,
    };

    const labelToPrintValues = [];
    if (data.length > 0) {
      for (let index = 0; index < data.length; index += 1) {
        labelToPrintValues.push(data[index]);
      }
    }
    aux.labelToPrint = [...labelToPrintValues];

    aux.button = buttonPrintRefUom.current;
    setUomPrintData(aux);
  };

  const handleOnClicPrintTriggerUOM = useReactToPrint({
    content: () => componentPrintRefUom.current,
  });

  return (
    <div>
      <div style={{ display: 'none' }}>
        <LocationToPrint ref={componentPrintRefUom} data={uomPrintData} />
        <button
          type="button"
          ref={buttonPrintRefUom}
          onClick={handleOnClicPrintTriggerUOM}
        >
          print uom
        </button>
      </div>
      {itemUnitOfMeasureRows &&
        itemUnitOfMeasureRows.map((row, index) => (
          <>
            <MUIGrid
              container
              spacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid
                item
                xs={8}
                marginTop={-2}
                marginBottom={1}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  Other Units of Measure {index + 1}
                </Typography>
                <IconButton
                  aria-label="print"
                  color="primary"
                  onClick={(e) => {
                    let barcode: string;
                    if (row.upc) {
                      barcode = row.upc;
                    } else {
                      barcode = `{${row.itemInventoryUOMAbbr}}{${formValues.desc.sku}}{${row.unitsPerItem}}`;
                    }

                    batchPrintUomCallback([
                      {
                        clientName: `${formValues.desc.customer}`,
                        barcode,
                        labelName: `${row.itemInventoryUOMAbbr} ${formValues.desc.sku}`,
                        qtyToDisplay: `1 ${row.itemInventoryUOMAbbr} (${row.unitsPerItem} ${formValues?.inventoryUnitsOfMeasure?.primaryInventoryUOMAbbr})`,
                        numberOfLabel: 1,
                      },
                    ]);
                  }}
                >
                  <PrintIcon />
                </IconButton>
                <MUIGrid item xs={4} paddingLeft={2}>
                  <Box sx={{ gap: '5px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event: any) => {
                            const datas = [...itemUnitOfMeasureRows];
                            const data = { ...datas[index] };

                            data.isPackedAsCarton = event.target.checked;

                            datas[index] = data;
                            setItemUnitOfMeasureRows(datas);
                          }}
                          checked={row.isPackedAsCarton || false}
                          {...(queryStringItemDetails.form === 'view' &&
                            parseInt(queryStringItemDetails.id, 10) > 0 && {
                              disabled: true,
                            })}
                        />
                      }
                      label="Is Packed as Carton"
                      {...(queryStringItemDetails.form === 'view' &&
                        parseInt(queryStringItemDetails.id, 10) > 0 && {
                          disabled: true,
                        })}
                    />
                    <Tooltip
                      title="Selecting this option will make the item packed as a carton."
                      placement="bottom"
                      arrow
                    >
                      <IconButton aria-label="print" color="primary">
                        <HelpOutlineOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </MUIGrid>
              </MUIGrid>
              <MUIGrid
                sx={{ textAlign: 'right' }}
                item
                xs={4}
                marginTop={-2}
                marginBottom={1}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    padding: '8px !important;',
                    textTransform: 'capitalize !important',
                    display: pageViewer.isView() ? 'none' : '',
                  }}
                  onClick={() => removeUnitOfMeasure(index)}
                >
                  <RemoveIcon />
                </Button>
              </MUIGrid>
            </MUIGrid>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={3}>
                <Autocomplete
                  onChange={(event: any, newValue: PackagingUOMType) => {
                    handleFormChangeUnitOfMeasure(
                      itemUnitOfMeasureRows,
                      index,
                      event,
                      newValue,
                      'UnitOfMeasure',
                    );
                  }}
                  id="controllable-states"
                  options={optionPrimaryInventoryUOM}
                  getOptionLabel={(option: PackagingUOMType) => option.label}
                  value={row.itemInventoryUOM}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Inventory UOM"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                  {...(queryStringItemDetails.form === 'view' &&
                    parseInt(queryStringItemDetails.id, 10) > 0 && {
                      disabled: true,
                    })}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Input
                  name="unitsPerItem"
                  type="number"
                  size="small"
                  InputProps={{
                    min: '0',
                    'data-state': 'primaryUnitsPerPackage',
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  placeholder="Primary Units Count"
                  value={row.unitsPerItem}
                  onChange={(event: any) => {
                    handleFormChangeUnitOfMeasure(
                      itemUnitOfMeasureRows,
                      index,
                      event,
                    );
                  }}
                  sx={{ width: '100%' }}
                  {...(queryStringItemDetails.form === 'view' &&
                    parseInt(queryStringItemDetails.id, 10) > 0 && {
                      disabled: true,
                    })}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <TextField
                  inputProps={{
                    'data-state': 'UPC',
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  id="outlined"
                  label="UPC"
                  size="small"
                  name="upc"
                  value={row.upc}
                  onChange={(value) => {
                    handleFormChangeUnitOfMeasure(
                      itemUnitOfMeasureRows,
                      index,
                      value,
                    );
                  }}
                  // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  sx={{ width: '100%' }}
                  {...(queryStringItemDetails.form === 'view' &&
                    parseInt(queryStringItemDetails.id, 10) > 0 && {
                      disabled: true,
                    })}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <TextField
                  inputProps={{
                    'data-state': 'name',
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  id="outlined"
                  label="Name"
                  size="small"
                  name="name"
                  value={row.name}
                  onChange={(value) => {
                    handleFormChangeUnitOfMeasure(
                      itemUnitOfMeasureRows,
                      index,
                      value,
                    );
                  }}
                  // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  sx={{ width: '100%' }}
                  {...(queryStringItemDetails.form === 'view' &&
                    parseInt(queryStringItemDetails.id, 10) > 0 && {
                      disabled: true,
                    })}
                />
              </MUIGrid>

              <MUIGrid item xs={3}>
                <Input
                  name="unitsLength"
                  type="number"
                  size="small"
                  InputProps={{
                    min: '0',
                    'data-state': 'length',
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  placeholder="Length"
                  value={row.unitsLength}
                  onChange={(event: any) => {
                    handleFormChangeUnitOfMeasure(
                      itemUnitOfMeasureRows,
                      index,
                      event,
                    );
                  }}
                  sx={{ width: '100%' }}
                  {...(queryStringItemDetails.form === 'view' &&
                    parseInt(queryStringItemDetails.id, 10) > 0 && {
                      disabled: true,
                    })}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Input
                  name="unitsWidth"
                  type="number"
                  size="small"
                  InputProps={{
                    min: '0',
                    'data-state': 'length',
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  placeholder="Width"
                  value={row.unitsWidth}
                  onChange={(event: any) => {
                    handleFormChangeUnitOfMeasure(
                      itemUnitOfMeasureRows,
                      index,
                      event,
                    );
                  }}
                  sx={{ width: '100%' }}
                  {...(queryStringItemDetails.form === 'view' &&
                    parseInt(queryStringItemDetails.id, 10) > 0 && {
                      disabled: true,
                    })}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Input
                  name="unitsHeight"
                  type="number"
                  size="small"
                  InputProps={{
                    min: '0',
                    'data-state': 'height',
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  placeholder="Height"
                  value={row.unitsHeight}
                  onChange={(event: any) => {
                    handleFormChangeUnitOfMeasure(
                      itemUnitOfMeasureRows,
                      index,
                      event,
                    );
                  }}
                  sx={{ width: '100%' }}
                  {...(queryStringItemDetails.form === 'view' &&
                    parseInt(queryStringItemDetails.id, 10) > 0 && {
                      disabled: true,
                    })}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <Input
                  name="unitsWeight"
                  type="number"
                  size="small"
                  InputProps={{
                    min: '0',
                    'data-state': 'weight',
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  placeholder="Weight (lbs)"
                  value={row.unitsWeight}
                  onChange={(event: any) => {
                    handleFormChangeUnitOfMeasure(
                      itemUnitOfMeasureRows,
                      index,
                      event,
                    );
                  }}
                  sx={{ width: '100%' }}
                  {...(queryStringItemDetails.form === 'view' &&
                    parseInt(queryStringItemDetails.id, 10) > 0 && {
                      disabled: true,
                    })}
                />
              </MUIGrid>
            </MUIGrid>
            <Divider sx={{ mt: 2, mb: 3 }} />
          </>
        ))}
    </div>
  );
};

const InventoryLicensePlates = () => {
  const {
    queryStringItemDetails,
    optionLicensePlateTypes,
    formValues,
    setFormValues,
    handleOnChange,
  } = useInventoryItemDetailsContext();

  const [palleteValue, setPalleteValue] = useState<LicensePlateType>(null);

  useEffect(() => {
    if (
      queryStringItemDetails.form === 'view' &&
      parseInt(queryStringItemDetails.id, 10) > 0
    ) {
      if (optionLicensePlateTypes.length !== 0) {
        const result = optionLicensePlateTypes.find(
          (o) => o.value === formValues.licensePlates.palleteId,
        );
        if (result !== undefined) {
          setPalleteValue(result);
        }
      }
    }
  }, [optionLicensePlateTypes, queryStringItemDetails]);

  return (
    <>
      <Typography variant="subtitle1" fontWeight="bold" mb={1}>
        Pallet Settings
      </Typography>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={3}>
          <Autocomplete
            onChange={(event: any, newValue: LicensePlateType) => {
              if (newValue !== null) {
                setPalleteValue(newValue);
                setFormValues((prevState) => ({
                  ...prevState,
                  licensePlates: {
                    ...prevState.licensePlates,
                    palleteId: newValue.value,
                  },
                }));
              } else {
                setPalleteValue(null);
              }
            }}
            id="controllable-states"
            options={optionLicensePlateTypes}
            getOptionLabel={(option: LicensePlateType) => option.label}
            value={palleteValue}
            isOptionEqualToValue={(option, selected) =>
              option.value === selected.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                autoComplete="off"
                id="outlined"
                label="Type"
                size="small"
                sx={{ width: '100%' }}
              />
            )}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          <TextField
            inputProps={{
              'data-state': 'qty',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Qty (Primary UOM)"
            size="small"
            name="licensePlates"
            value={formValues.licensePlates.qty || ''}
            onChange={(e) => handleOnChange(e)}
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            sx={{ width: '100%' }}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          <TextField
            inputProps={{
              'data-state': 'weight',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Weight (lbs)"
            size="small"
            name="licensePlates"
            value={formValues.licensePlates.weight || ''}
            onChange={(e) => handleOnChange(e)}
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            sx={{ width: '100%' }}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          <Autocomplete
            onChange={(event: any, newValue: AutocompleteBase) => {
              if (newValue !== null) {
                setFormValues((prevState) => ({
                  ...prevState,
                  licensePlates: {
                    ...prevState.licensePlates,
                    palletType: newValue,
                  },
                }));
              } else {
                setFormValues((prevState) => ({
                  ...prevState,
                  licensePlates: {
                    ...prevState.licensePlates,
                    palletType: null,
                  },
                }));
              }
            }}
            id="controllable-states"
            options={itemPalletTypeOption}
            value={formValues.licensePlates.palletType}
            getOptionLabel={(option: AutocompleteBase) => option.label}
            isOptionEqualToValue={(option, selected) =>
              option.value === selected.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                }}
                id="outlined"
                label="Pallet Type"
                size="small"
                sx={{ width: '100%' }}
              />
            )}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>

        <MUIGrid item xs={3}>
          <TextField
            inputProps={{
              'data-state': 'length',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Length (in)"
            size="small"
            name="licensePlates"
            value={formValues.licensePlates.length || ''}
            onChange={(e) => handleOnChange(e)}
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            sx={{ width: '100%' }}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          <TextField
            inputProps={{
              'data-state': 'width',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Width (in)"
            size="small"
            name="licensePlates"
            value={formValues.licensePlates.width || ''}
            onChange={(e) => handleOnChange(e)}
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            sx={{ width: '100%' }}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          <TextField
            inputProps={{
              'data-state': 'height',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Height (in)"
            size="small"
            name="licensePlates"
            value={formValues.licensePlates.height || ''}
            onChange={(e) => handleOnChange(e)}
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            sx={{ width: '100%' }}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          <TextField
            inputProps={{
              'data-state': 'totalArea',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Total Area (soft)"
            size="small"
            name="licensePlates"
            value={formValues.licensePlates.totalArea || ''}
            onChange={(e) => handleOnChange(e)}
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            sx={{ width: '100%' }}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
      </MUIGrid>
    </>
  );
};

const InventorySettings = () => {
  const {
    queryStringItemDetails,
    optionDefaultpickLocations,
    optionBulkLocations,
    optionAllocationMethodTypes,
    formValues,
    setFormValues,
    handleOnChange,
    isViewingOrEditing,
    pageViewer,
  } = useInventoryItemDetailsContext();

  const [allocationMethodValue, setAllocationMethodValue] =
    useState<AllocationMethodType>(null);
  const [defaultPickLocationValue, setdefaultPickLocationValue] =
    useState<PickLocationType>(null);
  const [bulkLocationValue, setBulkLocationValue] =
    useState<BulkLocationType>(null);

  useEffect(() => {
    // if new, set allocation method to Lowest QTY (LQ)
    if (
      queryStringItemDetails.form === 'new' &&
      !formValues.inventorySettings.allocationMethodId
    ) {
      if (optionAllocationMethodTypes.length !== 0) {
        const result = optionAllocationMethodTypes.find((o) => o.value === 4);
        if (result !== undefined) {
          setAllocationMethodValue(result);
          setFormValues((prevState) => ({
            ...prevState,
            inventorySettings: {
              ...prevState.inventorySettings,
              allocationMethodId: result.value,
            },
          }));
        }
      }
    }

    if (isViewingOrEditing) {
      if (optionAllocationMethodTypes.length !== 0) {
        const result = optionAllocationMethodTypes.find(
          (o) => o.value === formValues.inventorySettings.allocationMethodId,
        );
        if (result !== undefined) {
          setAllocationMethodValue(result);
        }
      }

      if (optionDefaultpickLocations.length > 0) {
        const result = optionDefaultpickLocations.find(
          (o) =>
            o.value === formValues.inventorySettings.defaultPickLocationBinId,
        );
        if (result !== undefined) {
          setdefaultPickLocationValue(result);
        }
      }

      if (optionBulkLocations.length > 0) {
        const result = optionBulkLocations.find(
          (o) => o.value === formValues.inventorySettings.bulkLocationBinId,
        );
        if (result !== undefined) {
          setBulkLocationValue(result);
        }
      }
    }
  }, [
    optionDefaultpickLocations,
    optionBulkLocations,
    optionAllocationMethodTypes,
    queryStringItemDetails,
    formValues.inventorySettings.allocationMethodId,
    formValues.inventorySettings.defaultPickLocationBinId,
    formValues.inventorySettings.bulkLocationBinId,
  ]);

  return (
    <>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={3}>
          <Box sx={{ gap: '5px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setFormValues((prevState) => ({
                      ...prevState,
                      inventorySettings: {
                        ...prevState.inventorySettings,
                        isFullQuantityOnly: e.target.checked,
                      },
                    }));
                  }}
                  checked={
                    formValues.inventorySettings.isFullQuantityOnly || false
                  }
                  {...(queryStringItemDetails.form === 'view' &&
                    parseInt(queryStringItemDetails.id, 10) > 0 && {
                      disabled: true,
                    })}
                />
              }
              label="Full Quantity Only"
              {...(queryStringItemDetails.form === 'view' &&
                parseInt(queryStringItemDetails.id, 10) > 0 && {
                  disabled: true,
                })}
            />
            <Tooltip
              title="If checked, locations that can fill the entire order will be prioritized."
              placement="bottom"
              arrow
            >
              <IconButton aria-label="print" color="primary">
                <HelpOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </MUIGrid>
        <MUIGrid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setFormValues((prevState) => ({
                    ...prevState,
                    inventorySettings: {
                      ...prevState.inventorySettings,
                      isSerialNo: e.target.checked,
                    },
                  }));
                }}
                checked={formValues.inventorySettings.isSerialNo || false}
                {...(queryStringItemDetails.form === 'view' &&
                  parseInt(queryStringItemDetails.id, 10) > 0 && {
                    disabled: true,
                  })}
              />
            }
            label="Trackable by Serial No."
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
        <MUIGrid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setFormValues((prevState) => ({
                    ...prevState,
                    inventorySettings: {
                      ...prevState.inventorySettings,
                      isTrackableByLot: e.target.checked,
                    },
                  }));
                }}
                checked={formValues.inventorySettings.isTrackableByLot || false}
                {...(queryStringItemDetails.form === 'view' &&
                  parseInt(queryStringItemDetails.id, 10) > 0 && {
                    disabled: true,
                  })}
              />
            }
            label="Trackable by LOT"
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>

        <MUIGrid item xs={3}>
          <Autocomplete
            onChange={(event: any, newValue: AllocationMethodType) => {
              if (newValue !== null) {
                setAllocationMethodValue(newValue);

                setFormValues((prevState) => ({
                  ...prevState,
                  inventorySettings: {
                    ...prevState.inventorySettings,
                    allocationMethodId: newValue.value,
                  },
                }));
              } else {
                setAllocationMethodValue(null);
              }
            }}
            id="controllable-states"
            options={optionAllocationMethodTypes}
            getOptionLabel={(option: AllocationMethodType) => option.label}
            value={allocationMethodValue}
            isOptionEqualToValue={(option, selected) =>
              option.value === selected.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                autoComplete="off"
                id="outlined"
                label="Allocation Method"
                size="small"
                sx={{ width: '100%' }}
              />
            )}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>

        <MUIGrid item xs={3}>
          <TextField
            inputProps={{
              'data-state': 'storageTemp',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Storage Temp."
            size="small"
            name="inventorySettings"
            value={formValues.inventorySettings.storageTemp || ''}
            onChange={(e) => handleOnChange(e)}
            sx={{ width: '100%' }}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>

        <MUIGrid item xs={3}>
          <Autocomplete
            onChange={(event: any, newValue: PickLocationType) => {
              if (newValue !== null) {
                setdefaultPickLocationValue(newValue);
                setFormValues((prevState) => ({
                  ...prevState,
                  inventorySettings: {
                    ...prevState.inventorySettings,
                    defaultPickLocationBinId: newValue.value,
                  },
                }));
              } else {
                setdefaultPickLocationValue(null);
                setFormValues((prevState) => ({
                  ...prevState,
                  inventorySettings: {
                    ...prevState.inventorySettings,
                    defaultPickLocationBinId: null,
                  },
                }));
              }
            }}
            id="controllable-states"
            options={optionDefaultpickLocations}
            getOptionLabel={(option: PickLocationType) => option.label}
            value={defaultPickLocationValue}
            isOptionEqualToValue={(option, selected) =>
              option.value === selected.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                autoComplete="off"
                id="outlined"
                label="Default Pick Location"
                size="small"
                sx={{ width: '100%' }}
              />
            )}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>

        <MUIGrid item xs={3}>
          <Autocomplete
            onChange={(event: any, newValue: BulkLocationType) => {
              if (newValue !== null) {
                setBulkLocationValue(newValue);
                setFormValues((prevState) => ({
                  ...prevState,
                  inventorySettings: {
                    ...prevState.inventorySettings,
                    bulkLocationBinId: newValue.value,
                  },
                }));
              } else {
                setBulkLocationValue(null);
                setFormValues((prevState) => ({
                  ...prevState,
                  inventorySettings: {
                    ...prevState.inventorySettings,
                    bulkLocationBinId: null,
                  },
                }));
              }
            }}
            id="controllable-states"
            options={optionBulkLocations}
            getOptionLabel={(option: BulkLocationType) => option.label}
            value={bulkLocationValue}
            isOptionEqualToValue={(option, selected) =>
              option.value === selected.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                autoComplete="off"
                id="outlined"
                label="Bulk Location"
                size="small"
                sx={{ width: '100%' }}
              />
            )}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          <TextField
            inputProps={{
              'data-state': 'freightClass',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Freight Class"
            size="small"
            name="freightClass"
            value={formValues.inventorySettings.freightClass || ''}
            onChange={(e) =>
              setFormValues((prevState) => ({
                ...prevState,
                inventorySettings: {
                  ...prevState.inventorySettings,
                  freightClass: e.target.value,
                },
              }))
            }
            sx={{ width: '100%' }}
            {...(pageViewer.isView() && {
              disabled: true,
            })}
          />
        </MUIGrid>
        <MUIGrid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFormValues((prevState) => ({
                    ...prevState,
                    inventorySettings: {
                      ...prevState.inventorySettings,
                      isHazmat: event.target.checked,
                    },
                  }))
                }
                checked={formValues.inventorySettings?.isHazmat}
              />
            }
            label="Hazmat"
            {...(pageViewer.isView() && {
              disabled: true,
            })}
          />
        </MUIGrid>
      </MUIGrid>
      <Divider sx={{ mt: 3, mb: 2 }} />

      <MUIGrid item xs={12} sm={12} md={12} mb={2}>
        <Typography variant="subtitle1" fontWeight="bold">
          Replenishment
        </Typography>
        <Typography variant="subtitle2">
          Automatically creates a transfer job to replenish{' '}
          <b>picking locations.</b> If no Replen Qty is specified, picking
          locations will be replenished to Max On Hand quantity.
        </Typography>
      </MUIGrid>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={2}>
          <TextField
            inputProps={{
              'data-state': 'minOnHand',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Min on Hand"
            size="small"
            type="number"
            name="inventorySettings"
            value={
              formValues.inventorySettings.minOnHand <= 0
                ? ''
                : formValues.inventorySettings.minOnHand
            }
            onChange={(e) => handleOnChange(e)}
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            sx={{ width: '100%' }}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
        <MUIGrid item xs={2}>
          <TextField
            inputProps={{
              'data-state': 'maxOnHand',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Max on Hand"
            size="small"
            name="inventorySettings"
            type="number"
            value={
              formValues.inventorySettings.maxOnHand <= 0
                ? ''
                : formValues.inventorySettings.maxOnHand
            }
            onChange={(e) => handleOnChange(e)}
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            sx={{ width: '100%' }}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
        <MUIGrid item xs={2}>
          <TextField
            inputProps={{
              'data-state': 'replenishQty',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Replenish Qty"
            size="small"
            name="inventorySettings"
            type="number"
            value={
              formValues.inventorySettings.replenishQty <= 0
                ? ''
                : formValues.inventorySettings.replenishQty
            }
            onChange={(e) => handleOnChange(e)}
            sx={{ width: '100%' }}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
        <MUIGrid item xs={2}>
          <TextField
            inputProps={{
              'data-state': 'reorderQty',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Reorder Qty"
            size="small"
            name="inventorySettings"
            value={formValues.inventorySettings.reorderQty}
            onChange={(e) => handleOnChange(e)}
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            sx={{ width: '100%' }}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
        <MUIGrid item xs={2}>
          <TextField
            inputProps={{
              'data-state': 'leadTime',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Lead Time"
            size="small"
            name="inventorySettings"
            type="number"
            value={
              formValues.inventorySettings.leadTime <= 0
                ? ''
                : formValues.inventorySettings.leadTime
            }
            onChange={(e) => handleOnChange(e)}
            sx={{ width: '100%' }}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
        <MUIGrid item xs={2}>
          <TextField
            inputProps={{
              'data-state': 'safetyStock',
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Safety Stock"
            size="small"
            name="inventorySettings"
            type="number"
            value={
              formValues.inventorySettings.safetyStock <= 0
                ? ''
                : formValues.inventorySettings.safetyStock
            }
            onChange={(e) => handleOnChange(e)}
            sx={{ width: '100%' }}
            {...(queryStringItemDetails.form === 'view' &&
              parseInt(queryStringItemDetails.id, 10) > 0 && {
                disabled: true,
              })}
          />
        </MUIGrid>
      </MUIGrid>

      <Divider sx={{ mt: 3, mb: 3 }} />
      <InventoryUnitsOfMeasure />
      <Divider sx={{ mt: 2, mb: 3 }} />
      <InventoryUnitsOfMeasureCards />
      <InventoryLicensePlates />
    </>
  );
};

const InventoryKitComponents = () => {
  const { onOpenKittingLineItemModal } = useContext(GlobalContext);
  const {
    setKitSkuValue,
    optionFinishedGoodUOM,
    setFormValues,
    kitComponentItemRows,
    setModalTitle,
    finishedGoodUOMValue,
    setFinishedGoodUOMValue,
    pageViewer,
    setKitComponentSingleItemRow,
    deleteKitLineItemComponentOnClick,
    tabValue,
    state,
  } = useInventoryItemDetailsContext();

  useEffect(() => {
    if (
      state.pageLoadState === PageLoadStateEnum.LOAD_FINISH &&
      tabValue === '3'
    ) {
      if (optionFinishedGoodUOM.length > 0 && !finishedGoodUOMValue) {
        setFinishedGoodUOMValue({
          value: optionFinishedGoodUOM[0].value,
          label: optionFinishedGoodUOM[0].label,
          isPrimary: true,
        });
      }
    }
  }, [state, tabValue]);

  const productionItemsColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      hide: pageViewer.isView(),
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            setModalTitle(ModalTitleStatus.Edit);
            setKitComponentSingleItemRow(params.row);
            onOpenKittingLineItemModal();
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={(e) => {
            deleteKitLineItemComponentOnClick(e, params.row);
          }}
          showInMenu
        />,
      ],
    },
    {
      field: 'sku',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">SKU</Typography>
        </Box>
      ),
    },
    {
      field: 'description',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Description</Typography>
        </Box>
      ),
    },
    {
      field: 'qty',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Qty/Kit</Typography>
        </Box>
      ),
    },
  ];

  return (
    <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={6}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            border: '0.5px solid #ebeaea',
            margin: 2,
            padding: '12px !important',
          }}
        >
          <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <MUIGrid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                Bill of Materials
              </Typography>
            </MUIGrid>
            <MUIGrid
              item
              xs={8}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Autocomplete
                onChange={(event: any, newValue: AutocompleteBaseKit) => {
                  if (newValue !== null) {
                    setFinishedGoodUOMValue(newValue);
                  } else {
                    setFinishedGoodUOMValue(null);
                  }
                }}
                id="controllable-states"
                options={optionFinishedGoodUOM}
                disableClearable
                getOptionLabel={(option: any) => option.label}
                value={finishedGoodUOMValue}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                )}
                {...(pageViewer.isView() && {
                  disabled: true,
                })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined-required"
                    label="Finished Good UoM"
                    size="small"
                    required
                  />
                )}
                sx={{ width: '60%', margin: '10px' }}
              />
              <ButtonMui
                sx={{
                  textTransform: 'capitalize',
                  border: '1px solid rgb(51, 117, 224)',
                  color: 'rgb(51, 117, 224)',
                  margin: '10px 0px',
                  width: '30% !important',
                }}
                variant="outlined"
                size="small"
                onClick={() => {
                  if (finishedGoodUOMValue === null) {
                    snackActions.error('Finished Good Uom must have value');
                    return;
                  }
                  onOpenKittingLineItemModal();
                  setKitSkuValue(null);
                  setModalTitle('Add');
                }}
                disabled={pageViewer.isView()}
              >
                Add Component
              </ButtonMui>
            </MUIGrid>
            <MUIGrid item xs={12}>
              <DatagridStandard
                sx={{ border: 'none !important' }}
                autoHeight
                rows={kitComponentItemRows}
                density="compact"
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu
                disableSelectionOnClick
                columns={productionItemsColumns}
                rowsPerPageOptions={[25]}
                pageSize={25}
                getRowId={(row) => row.itemKitComponentId}
              />
            </MUIGrid>
          </MUIGrid>
        </Card>
      </MUIGrid>
      <MUIGrid
        item
        xs={6}
        sx={{ paddingLeft: '0px !important', display: 'none' }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            border: '0.5px solid #ebeaea',
            margin: 2,
            padding: '12px !important',
            marginLeft: '0px !important',
          }}
        >
          <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <MUIGrid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                Packing Materials
              </Typography>
            </MUIGrid>
            <MUIGrid
              item
              xs={8}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <ButtonMui
                sx={{
                  textTransform: 'capitalize',
                  border: '1px solid rgb(51, 117, 224)',
                  color: 'rgb(51, 117, 224)',
                  margin: '10px 0px',
                  padding: '7px 0px',
                  width: '30% !important',
                }}
                variant="outlined"
                size="small"
                onClick={() => {
                  onOpenKittingLineItemModal();
                  setKitSkuValue(null);
                  setModalTitle('Add');
                }}
                disabled={pageViewer.isView()}
              >
                Add Component
              </ButtonMui>
            </MUIGrid>
            <MUIGrid item xs={12}>
              <DatagridStandard
                sx={{ border: 'none !important' }}
                autoHeight
                rows={kitComponentItemRows}
                density="compact"
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu
                disableSelectionOnClick
                columns={productionItemsColumns}
                rowsPerPageOptions={[25]}
                pageSize={25}
                getRowId={(row) => row.itemKitComponentId}
              />
            </MUIGrid>
          </MUIGrid>
        </Card>
      </MUIGrid>
    </MUIGrid>
  );
};

const KitOnDemandTab = () => {
  const { onOpenKittingLineItemModal } = useContext(GlobalContext);
  const {
    setKitSkuValue,
    optionFinishedGoodUOM,
    setFormValues,
    kitOnDemandItemRows,
    setKitOnDemandItemRows,
    finishedGoodUOMKitOnDemandDDL,
    setFinishedGoodUOMKitOnDemandDDL,
    pageViewer,
    setKitOnDemandSelectedItemRow,
    deleteKitLineItemComponentOnClick,
    optionKitSkus,
    tabValue,
    state,
  } = useInventoryItemDetailsContext();

  useEffect(() => {
    if (
      state.pageLoadState === PageLoadStateEnum.LOAD_FINISH &&
      tabValue === '4'
    ) {
      if (optionFinishedGoodUOM.length > 0 && !finishedGoodUOMKitOnDemandDDL) {
        setFinishedGoodUOMKitOnDemandDDL({
          value: optionFinishedGoodUOM[0].value,
          label: optionFinishedGoodUOM[0].label,
        });
      }
    }
  }, [state, tabValue]);

  const kitOnDemandColumns: GridColumns<KitOnDemand> = [
    {
      field: 'actions',
      type: 'actions',
      hide: pageViewer.isView(),
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params: GridRowParams<KitOnDemand>) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            const kitSkuType = optionKitSkus.find(
              (x) => x.value === params.row.itemId,
            );
            setKitOnDemandSelectedItemRow({
              ...params.row,
              kitSkuType: { ...kitSkuType },
              itemIdError: '',
              qtyError: '',
            });
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            if (params.row.rowId !== null) {
              setKitOnDemandItemRows([
                ...kitOnDemandItemRows.filter(
                  (x) => x.rowId !== params.row.rowId,
                ),
              ]);
            }
            // exist in db
            else {
              setKitOnDemandItemRows([
                ...kitOnDemandItemRows.filter(
                  (x) => x.itemKitOnDemandId !== params.row.itemKitOnDemandId,
                ),
              ]);
            }
          }}
          showInMenu
        />,
      ],
    },
    {
      field: 'sku',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">SKU</Typography>
        </Box>
      ),
    },
    {
      field: 'description',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Description</Typography>
        </Box>
      ),
    },
    {
      field: 'qty',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Qty/Kit</Typography>
        </Box>
      ),
    },
    {
      field: 'isNeedPicking',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Need Picking</Typography>
        </Box>
      ),
      renderCell: (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params: GridRenderCellParams<any, KitOnDemand>,
      ) => <Typography>{params.row.isNeedPicking ? 'Yes' : 'No'}</Typography>,
    },
  ];

  return (
    <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={6}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            border: '0.5px solid #ebeaea',
            margin: 2,
            padding: '12px !important',
          }}
        >
          <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <MUIGrid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                Bill of Materials
              </Typography>
            </MUIGrid>
            <MUIGrid
              item
              xs={8}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Autocomplete
                value={finishedGoodUOMKitOnDemandDDL}
                onChange={(event: any, newValue: AllocationMethodType) => {
                  if (newValue !== null) {
                    setFinishedGoodUOMKitOnDemandDDL(newValue);
                    setFormValues((prevState) => ({
                      ...prevState,
                      finishedGoodUOMKitOnDemand: newValue.value,
                    }));
                  } else {
                    setFinishedGoodUOMKitOnDemandDDL(null);
                    setFormValues((prevState) => ({
                      ...prevState,
                      finishedGoodUOMKitOnDemand: null,
                    }));
                  }
                }}
                id="controllable-states"
                options={optionFinishedGoodUOM}
                disableClearable
                getOptionLabel={(option: AllocationMethodType) => option.label}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                )}
                {...(pageViewer.isView() && {
                  disabled: true,
                })}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    id="outlined-required"
                    label="Finished Good UoM"
                    size="small"
                  />
                )}
                sx={{ width: '60%', margin: '10px' }}
              />
              <ButtonMui
                sx={{
                  textTransform: 'capitalize',
                  border: '1px solid rgb(51, 117, 224)',
                  color: 'rgb(51, 117, 224)',
                  margin: '10px 0px',
                  width: '30% !important',
                }}
                variant="outlined"
                size="small"
                onClick={() => {
                  if (finishedGoodUOMKitOnDemandDDL === null) {
                    snackActions.error('Finished Good Uom must have value');
                    return;
                  }

                  setKitOnDemandSelectedItemRow((prevState) => ({
                    ...prevState,
                    rowId: Math.random(),
                    itemKitOnDemandId: null,
                    itemId: null,
                    sku: null,
                    description: null,
                    kitSkuType: null,
                    isNeedPicking: true,
                    qty: null,
                    itemIdError: '',
                    qtyError: '',
                  }));
                }}
                disabled={pageViewer.isView()}
              >
                Add Component
              </ButtonMui>
            </MUIGrid>
            <MUIGrid item xs={12}>
              <DatagridStandard
                sx={{ border: 'none !important' }}
                autoHeight
                rows={kitOnDemandItemRows}
                density="compact"
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu
                disableSelectionOnClick
                columns={kitOnDemandColumns}
                rowsPerPageOptions={[25]}
                pageSize={25}
                getRowId={() => Math.random()}
              />
            </MUIGrid>
          </MUIGrid>
        </Card>
      </MUIGrid>
    </MUIGrid>
  );
};

const InventoryCostSheets = () => {
  const {
    itemCostSheetItemRows,
    setItemCostSheetSingleData,
    pageViewer,
    setItemCostSheetItemRows,
    setSaveCostSheetTrigger,
  } = useInventoryItemDetailsContext();

  const { onOpenItemCostSheetModal } = useContext(GlobalContext);

  const costSheetFormOnChange = (data) => {
    setItemCostSheetSingleData((prev) => ({
      ...prev,
      rowId: data.id,
      itemCostSheetId: data.row.itemCostSheetId
        ? data.row.itemCostSheetId
        : undefined,
      sellPrice: data.row.sellPrice,
      cost: data.row.cost,
      shippingCost: data.row.shippingCost,
      date: data.row.date,
      supplierId: data.row.supplierId,
      supplierName: data.row.supplierName,
      partNumber: data.row.partNumber,
    }));

    onOpenItemCostSheetModal();
  };

  const itemCostSheetColumn: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            costSheetFormOnChange(params);
          }}
          showInMenu
          disabled={params.row.itemCostSheetId === undefined}
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            if (pageViewer.isNew()) {
              setItemCostSheetItemRows((prevState) =>
                prevState.filter((x) => x.tempId !== params.row.tempId),
              );
            } else {
              setItemCostSheetItemRows((prevState) =>
                prevState.filter(
                  (x) => x.itemCostSheetId !== params.row.itemCostSheetId,
                ),
              );
              setSaveCostSheetTrigger(true);
            }
          }}
          showInMenu
        />,
      ],
    },
    {
      field: 'date',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Date</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{moment(params.row.date).format('MM/DD/YY')}</Typography>
      ),
    },
    {
      field: 'sellPrice',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Sell Price</Typography>
        </Box>
      ),

      renderCell: (params) => (
        <Typography>
          {params.row.sellPrice
            ? `$${params.row.sellPrice.toFixed(2)}`
            : '$0.00'}
        </Typography>
      ),
    },
    {
      field: 'cost',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Cost</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params.row.cost ? `$${params.row.cost.toFixed(2)}` : '$0.00'}
        </Typography>
      ),
    },
    {
      field: 'shippingCost',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Shipping Cost</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params.row.shippingCost
            ? `$${params.row.shippingCost.toFixed(2)}`
            : '$0.00'}
        </Typography>
      ),
    },
    {
      field: 'supplierName',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Supplier</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row.supplierName}</Typography>
      ),
    },
    {
      field: 'partNumber',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Part Number</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.partNumber}</Typography>,
    },
  ];

  return (
    <>
      <MUIGrid
        container
        spacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ justifyContent: 'right' }}
      >
        <MUIGrid item xs={10}>
          <Typography
            variant="h6"
            sx={{ float: 'left' }}
            fontWeight="bold"
            mb={2}
          >
            Cost Sheets
          </Typography>
        </MUIGrid>
        <MUIGrid item xs={2} sx={{ textAlignLast: 'right' }}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              width: '50%',
              height: '40px !important',
              boxShadow: 'none !important',
            }}
            onClick={() => {
              onOpenItemCostSheetModal();
            }}
          >
            Add New
          </Button>
        </MUIGrid>
      </MUIGrid>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={12}>
          <DatagridStandard
            autoHeight
            sx={{ border: 'none !important' }}
            headerHeight={120}
            rows={itemCostSheetItemRows}
            columns={itemCostSheetColumn}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu
            hideFooterSelectedRowCount
            pageSize={6}
            density="compact"
            rowsPerPageOptions={[6]}
            getRowId={(row) =>
              row.itemCostSheetId !== undefined
                ? row.itemCostSheetId
                : Math.random()
            }
            componentsProps={{
              toolbar: {
                title: 'Cost Sheet',
              },
            }}
          />
        </MUIGrid>
      </MUIGrid>
    </>
  );
};

const InventorySummaryFirstRow = () => {
  const { loading, updateData } = useContext(GlobalContext);

  const { tabValue, onLoadItemDetailSummary, itemSummary, state } =
    useInventoryItemDetailsContext();

  useEffect(() => {
    if (
      state.pageLoadState === PageLoadStateEnum.LOAD_FINISH &&
      tabValue === '1'
    ) {
      onLoadItemDetailSummary();
    }
  }, [loading, updateData, state, tabValue]);

  return (
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={1.33}>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography fontSize={15}>Total on Hand</Typography>
          <Typography fontWeight="bold" fontSize={20}>
            {itemSummary?.totalOnHand
              ? itemSummary.totalOnHand.toLocaleString()
              : 0}
          </Typography>
        </Box>
      </MUIGrid>
      <MUIGrid item xs={1.33}>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography fontSize={15}>Allocated / Available</Typography>
          <Typography fontWeight="bold" fontSize={20}>
            {itemSummary?.totalAllocated
              ? itemSummary.totalAllocated.toLocaleString()
              : 0}{' '}
            /{' '}
            {itemSummary?.totalAvailable
              ? itemSummary.totalAvailable.toLocaleString()
              : 0}
          </Typography>
        </Box>
      </MUIGrid>
      <MUIGrid item xs={1.33}>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography fontSize={15}>Total Available</Typography>
          <Typography fontWeight="bold" fontSize={20}>
            {itemSummary?.totalAvailableToPick
              ? itemSummary.totalAvailableToPick.toLocaleString()
              : 0}
          </Typography>
        </Box>
      </MUIGrid>
      <MUIGrid item xs={1.33}>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography fontSize={15}>Total Pallets</Typography>
          <Typography fontWeight="bold" fontSize={20}>
            {itemSummary?.totalPallets
              ? itemSummary.totalPallets.toLocaleString()
              : 0}
          </Typography>
        </Box>
      </MUIGrid>
      <MUIGrid item xs={1.33}>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography fontSize={15}>Total Expected</Typography>
          <Typography fontWeight="bold" fontSize={20}>
            {itemSummary?.totalExpected
              ? itemSummary.totalExpected.toLocaleString()
              : 0}
          </Typography>
        </Box>
      </MUIGrid>
      <MUIGrid item xs={1.33}>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography fontSize={15}>Hold</Typography>
          <Typography fontWeight="bold" fontSize={20}>
            {itemSummary?.onHold ? itemSummary.onHold.toLocaleString() : 0}
          </Typography>
        </Box>
      </MUIGrid>
      <MUIGrid item xs={1.33}>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography fontSize={15}>Return</Typography>
          <Typography fontWeight="bold" fontSize={20}>
            {itemSummary?.return}
          </Typography>
        </Box>
      </MUIGrid>
      <MUIGrid item xs={1.33}>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography fontSize={15}>Total Revenue</Typography>
          <Typography fontWeight="bold" fontSize={20}>
            {itemSummary?.totalRevenue}
          </Typography>
        </Box>
      </MUIGrid>
      <MUIGrid item xs={1.33}>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography fontSize={15}>Total Inventory Cost</Typography>
          <Typography fontWeight="bold" fontSize={20}>
            {itemSummary?.totalInventoryCost}
          </Typography>
        </Box>
      </MUIGrid>
    </MUIGrid>
  );
};

const InventorySummaryThirdRow = () => {
  const theme = useTheme();

  const {
    isInventoryViewer,
    currentLocationAndFacility,
    isWarehouseCustomerAccount,
  } = useContext(AuthContext);

  const {
    loading,
    updateData,
    setPrintTypeData,
    onOpenAdjustModal,
    setIsPrintQtyModal,
    onOpenTransferV2Modal,
    setNumberToPrintGlobal,
    setOtherUOMPrintModelData,
    onOpenDeleteInventoryModal,
    onOpenAdjustInventoryModal,
    onOpenPrintFormTemplateModal,
    isItemReceiptPictureModalOpen,
    setIsInventoryLocationModalOpen,
    setIsItemReceiptPictureModalOpen,
  } = useContext(GlobalContext);

  const {
    tabValue,
    pageViewer,
    isSerialNo,
    itemLocations,
    filteredItemLoc,
    isTrackableByLot,
    selectedToDeleteRows,
    queryStringItemDetails,
    lpReceiptLineItemIdToPrint,
    setItemLocations,
    setFilteredItemLoc,
    setLocationDataToPrint,
    setSelectedToDeleteRows,
    setIsUsingLicensePlateId,
    onLoadItemDetailInventory,
    setLPReceiptLineItemIdToPrint,
    state,
  } = useInventoryItemDetailsContext();
  const [locationDataSelectedV2, setLocationDataSelectedV2] = useState(null);
  const [locationDataSelected, setLocationDataSelected] = useState({
    customerLocationId: '',
    itemSKU: '',
    binName: '',
  });

  const handleOpenTransferModal = (rowData) => {
    setLocationDataSelectedV2({
      ...rowData,
      ...currentLocationAndFacility,
    });
    onOpenTransferV2Modal();
  };

  const handleOpenAdjustModal = (rowData) => {
    setLocationDataSelected({
      ...rowData,
    });
    onOpenAdjustModal();
  };

  const handleOpenItemReceiptPictureModal = (rowData) => {
    setLocationDataSelected({
      ...rowData,
    });
    setIsItemReceiptPictureModalOpen(true);
  };

  type ItemRow = typeof itemLocations[number];

  const itemLocationColumn: GridColumns<ItemRow> = [
    {
      field: 'actions',
      type: 'actions',
      hide: isInventoryViewer,
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Transfer"
          onClick={() => handleOpenTransferModal(params.row)}
          showInMenu
          style={{ display: isWarehouseCustomerAccount ? 'none' : 'block' }}
        />,
        <GridActionsCellItem
          label="Adjust"
          onClick={() => handleOpenAdjustModal(params.row)}
          showInMenu
          style={{ display: isWarehouseCustomerAccount ? 'none' : 'block' }}
        />,
        <GridActionsCellItem
          label="Images"
          onClick={() => handleOpenItemReceiptPictureModal(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: 'inventoryBinDetailId',
      width: 50,
      renderHeader: () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography />
        </Box>
      ),
      renderCell: (params) => (
        <IconButton
          aria-label="print"
          color="primary"
          onClick={() => {
            // each
            if (params.row.binItemType === BinItemTypeEnum.Each) {
              if (params.row.isOtherUOM) {
                setLocationDataToPrint((prev) => ({
                  ...prev,
                  customer: params.row.customer,
                  barcode: params.row.inventoryLocationBarcode,
                  labelName: `${params.row.inventoryLocationLabel}`,
                  qtyToDisplay: params.row.otherUOMQtyStr,
                  numberOfLabel: Number(params.row.qtyToPrint),
                }));

                setOtherUOMPrintModelData((prevState: OtherUOMPrintModel) => ({
                  ...prevState,
                  clientName: params.row.customer,
                  barcode: params.row.inventoryLocationBarcode,
                  description: params.row.inventoryLocationLabel,
                  qtyToDisplay: params.row.otherUOMQtyStr,
                  buttonPrint: null,
                }));
                setPrintTypeData(PrintTypeEnum.OtherUOM);
                setIsPrintQtyModal(true);
                setNumberToPrintGlobal(params.row.qtyToPrint ?? null);
                setIsInventoryLocationModalOpen(true);
              } else {
                setPrintTypeData(PrintTypeEnum.SKU);
                setIsPrintQtyModal(true);
                setNumberToPrintGlobal(1);
              }
            }
            // pallet
            else {
              setLPReceiptLineItemIdToPrint(
                params.row?.receiptLineItemId ?? params.row?.licensePlateId,
              );
              if (!params.row?.receiptLineItemId) {
                setIsUsingLicensePlateId(true);
              } else {
                setIsUsingLicensePlateId(false);
              }
              onOpenPrintFormTemplateModal();
            }
          }}
        >
          <PrintIcon />
        </IconButton>
      ),
    },
    {
      field: 'bin',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Location</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography sx={{ color: [theme.palette.primary.main] }}>
          <Link
            href={`/locations/location/${params.row.bin}?binId=${params.row.binId}`}
            underline="none"
          >
            {params.row.bin}
          </Link>
        </Typography>
      ),
    },
    {
      field: 'lotNo',
      minWidth: 150,
      hide: isTrackableByLot,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Lot Number</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row.lotNo ? params.row.lotNo : 'N/A'}</Typography>
      ),
    },
    {
      field: 'expirationDate',
      minWidth: 150,
      hide: isTrackableByLot,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Expiration Date</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params.row.expirationDate
            ? moment(params.row.expirationDate).format('MM/DD/YY')
            : 'N/A'}
        </Typography>
      ),
    },
    {
      field: 'qtyOnHand',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Qty on Hand</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <>
          <Typography>{params.row.qtyOnHand?.toLocaleString()}</Typography>
          <ItemStatusSmallChip itemStatus={params.row.status} />
        </>
      ),
    },
    {
      field: 'availableQty',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Available Qty</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row.availableQty?.toLocaleString()}</Typography>
      ),
    },
    {
      field: 'serialNo',
      minWidth: 150,
      hide: isSerialNo,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Serial No</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params.row.serialNo ? params.row.serialNo : 'N/A'}
        </Typography>
      ),
    },
    {
      field: 'binItemTypeStr',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Type</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {/* BinItemType = Each, other uom */}
          {params.row.isOtherUOM &&
            params.row.binItemType === 1 &&
            params.row.otherUOMQtyStr}

          {/* BinItemType = Pallet */}
          {params.row.isOtherUOM &&
            params.row.binItemType === 2 &&
            `Pallet ${params.row.otherUOMQtyStr}`}

          {/* BinItemType = Each, primary uom */}
          {params.row.isOtherUOM === false && params.row.binItemTypeStr}
        </Typography>
      ),
    },
    {
      field: 'licensePlate',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>LP</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params.row.licensePlate ? params.row.licensePlate : 'N/A'}
        </Typography>
      ),
    },
    {
      field: 'receiptDate',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Receipt Date</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params.row.receiptDate
            ? moment(params.row.receiptDate).format('MM/DD/YY')
            : 'N/A'}
        </Typography>
      ),
    },
    {
      field: 'zone',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Area</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.zone}</Typography>,
    },
    {
      field: 'facility',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Facility</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.facility}</Typography>,
    },
  ];

  useEffect(() => {
    if (
      state.pageLoadState === PageLoadStateEnum.LOAD_FINISH &&
      tabValue === '1'
    ) {
      setItemLocations([]);
      setFilteredItemLoc([]);
      onLoadItemDetailInventory();
    }
  }, [loading, updateData, state, tabValue]);

  // <-- LP PRINT
  const [lpDataToPrint, setLPDataToPrint] = useState<any>(null);
  const lpPrintRef = useRef(null);
  const buttonLPPrintRef = useRef(null);

  const lpPrintClickTriggerHandler = useReactToPrint({
    content: () => lpPrintRef.current,
  });

  const onLoadReceiptLineItemToPrint = async (id) => {
    try {
      return await getReceiptLineItemToPrint(id);
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (lpReceiptLineItemIdToPrint !== null && lpReceiptLineItemIdToPrint > 0) {
      onLoadReceiptLineItemToPrint(lpReceiptLineItemIdToPrint).then((x) => {
        setLPDataToPrint((prev) => ({
          ...prev,
          receiptLineItemId: x.receiptLineItemId,
          customer: x.customer,
          reference: x.reference,
          licensePlateNo: x.licensePlateNo,
          sku: x.sku,
          description: x.description,
          each: x.each,
          eaCase: x.eaCase,
          totalCases: x.totalCases,
          lot: {
            lotId: x.lot?.lotId,
            lotNo: x.lot?.lotNo,
            lotCreationDate: x.lot?.creationDate,
            lotExpirationDate: x.lot?.expirationDate,
          },
          // button: buttonLPPrintRef.current,
          isSku: false,
        }));
      });
    }
  }, [lpReceiptLineItemIdToPrint]);

  const onRowsSelectionToDeleteHandler = (ids) => {
    const selectedRowsData = filteredItemLoc
      .map((row) => ids.find((id) => id === row?.inventoryBinDetailId))
      .filter((item) => item !== undefined && item !== null);

    setSelectedToDeleteRows(selectedRowsData);
  };

  return (
    <>
      <DatagridStandard
        sx={{ border: 'none !important' }}
        autoHeight
        rows={filteredItemLoc}
        columns={itemLocationColumn}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        pageSize={25}
        density="compact"
        rowsPerPageOptions={[25]}
        getRowId={(row) => row?.inventoryBinDetailId}
        components={{ Toolbar: CustomGridToolbar }}
        componentsProps={{
          toolbar: {
            title: 'Inventory',
            showAdjustInventoryButton:
              !isWarehouseCustomerAccount && pageViewer.isViewOrEdit(),
            adjustInventoryMethod: onOpenAdjustInventoryModal,
            showDeleteInventoryButton: true,
            disableDeleteInventoryButton:
              !selectedToDeleteRows || selectedToDeleteRows?.length === 0,
            deleteInventoryMethod: onOpenDeleteInventoryModal,
          },
        }}
        hideFooterSelectedRowCount
        checkboxSelection
        onSelectionModelChange={(ids) => onRowsSelectionToDeleteHandler(ids)}
      />
      <AdjustInventoryModal itemId={queryStringItemDetails.id} />
      <DeleteInventoryModal />
      <AddLotNoModal paramId={queryStringItemDetails.id} />
      {!isInventoryViewer && (
        <>
          <TransferBinV2Modal itemDetails={locationDataSelectedV2} />
          <AdjustQtyModal
            locationDetails={locationDataSelected}
            itemDetails={queryStringItemDetails.id}
          />
          {isItemReceiptPictureModalOpen && (
            <ItemReceiptPictureModal locationDetails={locationDataSelected} />
          )}
        </>
      )}

      <div style={{ display: 'none' }}>
        {lpDataToPrint !== null && (
          <SinglePrintLineItemV2 ref={lpPrintRef} data={lpDataToPrint} />
        )}

        <button
          type="button"
          ref={buttonLPPrintRef}
          onClick={lpPrintClickTriggerHandler}
        >
          This one is trigger on single print receipt line item
        </button>
      </div>
    </>
  );
};

const InventorySuppliers = () => {
  const theme = useTheme();

  const {
    loading,
    updateData,
    onOpenAddSupplierFromInventorySheetModal,
    onOpenActivePOSeeMoreModal,
  } = useContext(GlobalContext);

  const {
    currentUser,
    currentLocationAndFacility,
    isWarehouseCustomerAccount,
  } = useContext(AuthContext);
  const [urlSearchParams] = useSearchParams();

  const { state, tabValue } = useInventoryItemDetailsContext();

  const [filteredItemSuppliers, setFilteredItemSuppliers] = useState([]);
  const [itemSuppliers, setItemSuppliers] = useState([]);
  const [activePoLinks, setActivePoLinks] = useState<any>(null);

  const [supplierDataSelected, setSupplierDataSelected] = useState({
    supplierId: '',
    customerId: '',
    account: '',
    name: '',
    contact: '',
    address: '',
    city: '',
    stateProvince: '',
    zipPostalCode: '',
    phone: '',
  });

  const onLoadItemDetailSuppliers = async () => {
    try {
      const itemSuppliersAndLocationsFromApi = await getItemDetail(
        currentUser.Claim_CustomerId,
        !isWarehouseCustomerAccount
          ? currentLocationAndFacility.customerFacilityId
          : 0,
        urlSearchParams.get(`id`),
      );
      setItemSuppliers(itemSuppliersAndLocationsFromApi.suppliers);
      setFilteredItemSuppliers(itemSuppliersAndLocationsFromApi.suppliers);

      return true;
    } catch (error) {
      return error;
    }
  };

  const itemSupplierColumns: GridColDef[] = [
    {
      field: 'name',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Name</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`/inventory/supplier-details?form=view&id=${params.row.supplierId}`}
          target="_blank"
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.name}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'contact',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Contact</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.contact}</Typography>,
    },
    {
      field: 'activePOs',
      minWidth: 200,
      flex: 2,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Active PO</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <ul style={{ margin: '-10px' }}>
          {params?.value?.length > 1 ? (
            <Button
              variant="text"
              size="small"
              onClick={() => {
                setActivePoLinks(params?.value);
                onOpenActivePOSeeMoreModal();
              }}
            >
              See more
            </Button>
          ) : (
            params?.value?.map((row, index) => (
              <li>
                <Link
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: '#303538',
                    textDecoration: 'none',
                  }}
                  href={`/receiving/purchase-orders?form=view&orderNo=${row.poName}&id=${row.orderId}`}
                  target="_blank"
                >
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      color: [theme.palette.primary.main],
                    }}
                  >
                    {row.poName}
                  </Typography>
                </Link>
              </li>
            ))
          )}
        </ul>
      ),
      type: 'string',
    },
  ];

  useEffect(() => {
    if (
      state.pageLoadState === PageLoadStateEnum.LOAD_FINISH &&
      tabValue === '5'
    ) {
      setItemSuppliers([]);
      setFilteredItemSuppliers([]);
      onLoadItemDetailSuppliers();
    }
  }, [loading, updateData, state, tabValue]);

  return (
    <>
      <AddSupplierFromInventoryModal />
      <ActivePOSeeMoreModal listStringLink={activePoLinks} />
      <DatagridStandard
        sx={{ border: 'none !important' }}
        autoHeight
        rows={filteredItemSuppliers}
        columns={itemSupplierColumns}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        hideFooterSelectedRowCount
        pageSize={15}
        density="compact"
        rowsPerPageOptions={[15]}
        getRowId={(row) => row.supplierId}
        onSelectionModelChange={(supplierId) => {
          const selectedId = supplierId[0];
          const selectedRowData = itemSuppliers.filter(
            (c) => c.supplierId === selectedId,
          );
          if (selectedId === undefined) {
            setSupplierDataSelected({
              supplierId: '',
              customerId: '',
              account: '',
              name: '',
              contact: '',
              address: '',
              city: '',
              stateProvince: '',
              zipPostalCode: '',
              phone: '',
            });
          } else {
            setSupplierDataSelected(selectedRowData[0]);
          }
        }}
        components={{ Toolbar: CustomGridToolbar }}
        componentsProps={{
          toolbar: {
            title: 'Suppliers',
            showAddSupplierButton: true,
            addSupplierFromInventoryOpenModalMethod:
              onOpenAddSupplierFromInventorySheetModal,
          },
        }}
      />
    </>
  );
};

const InventoryActivities = () => {
  const theme = useTheme();
  const { loading, updateData } = useContext(GlobalContext);
  const { state, tabValue } = useInventoryItemDetailsContext();
  const {
    currentUser,
    currentLocationAndFacility,
    isWarehouseCustomerAccount,
  } = useContext(AuthContext);
  const [urlSearchParams] = useSearchParams();

  const [filteredItemActivityLogs, setFilteredActivityLogs] = useState([]);

  const onLoadItemDetailActivityLogs = async () => {
    try {
      const itemSuppliersAndLocationsFromApi = await getItemDetail(
        currentUser.Claim_CustomerId,
        !isWarehouseCustomerAccount
          ? currentLocationAndFacility.customerFacilityId
          : 0,
        urlSearchParams.get(`id`),
      );
      setFilteredActivityLogs(itemSuppliersAndLocationsFromApi.activities);

      return true;
    } catch (error) {
      return error;
    }
  };

  const itemActivityColumn: GridColDef[] = [
    {
      field: 'type',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Type</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <div>
          {params.row.receiptId ? (
            <Typography sx={{ color: [theme.palette.primary.main] }}>
              <Link
                href={`/receiving/receipts/subpage?form=view&id=${params.row.receiptId}`}
                underline="none"
                target="_blank"
              >
                {params.row.type}
              </Link>
            </Typography>
          ) : (
            <Typography>{params.row.type}</Typography>
          )}
        </div>
      ),
    },
    {
      field: 'entity',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Entity</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <div>
          {params.row.orderId ? (
            <Typography sx={{ color: [theme.palette.primary.main] }}>
              <Link
                href={`/orders/sales-orders?form=view&id=${params.row.orderId}`}
                underline="none"
                target="_blank"
              >
                {params.row.entity}
              </Link>
            </Typography>
          ) : (
            <Typography>{params.row.entity}</Typography>
          )}
        </div>
      ),
    },
    {
      field: 'user',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">User</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.user}</Typography>,
    },
    {
      field: 'date',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Date</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{moment(params.row.date).format('MM/DD/YY')}</Typography>
      ),
    },
    {
      field: 'qty',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Qty</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.qty}</Typography>,
    },
    {
      field: 'zone',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Area</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.zone}</Typography>,
    },
    {
      field: 'binLocation',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Location</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.binLocation}</Typography>,
    },
    {
      field: 'notes',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Notes</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.notes}</Typography>,
    },
  ];

  useEffect(() => {
    if (
      state.pageLoadState === PageLoadStateEnum.LOAD_FINISH &&
      tabValue === '6'
    ) {
      setFilteredActivityLogs([]);
      onLoadItemDetailActivityLogs();
    }
  }, [loading, updateData, state, tabValue]);

  return (
    <DatagridStandard
      sx={{ border: 'none !important' }}
      autoHeight
      rows={filteredItemActivityLogs}
      columns={itemActivityColumn}
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      disableColumnMenu
      hideFooterSelectedRowCount
      pageSize={30}
      density="compact"
      getRowId={(row) => row.activityLogId}
      components={{ Toolbar: CustomGridToolbar }}
      componentsProps={{
        toolbar: {
          title: 'Activities',
        },
      }}
    />
  );
};

const InventoryUseAsPackaging = () => {
  const { formValues, setFormValues, queryStringItemDetails, pageViewer } =
    useInventoryItemDetailsContext();

  return (
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={12}>
        <Typography variant="h6" fontWeight="bold">
          Packaging
        </Typography>
      </MUIGrid>
      <MUIGrid item xs={2}>
        <Autocomplete
          onChange={(event: any, newValue: PackagingType) => {
            if (newValue !== null) {
              setFormValues((prevState) => ({
                ...prevState,
                desc: {
                  ...prevState.desc,
                  packagingType: newValue,
                },
              }));
            }
          }}
          id="controllable-states"
          options={PackagingTypeOption}
          getOptionLabel={(option: PackagingType) => option.label}
          value={formValues.desc?.packagingType}
          isOptionEqualToValue={(option, selected) =>
            option.value === selected.value
          }
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: autoCompleteTimeStamp().toString(),
              }}
              autoComplete="off"
              id="outlined"
              label="Type"
              size="small"
              sx={{ width: '100%' }}
            />
          )}
          {...(queryStringItemDetails.form === 'view' &&
            parseInt(queryStringItemDetails.id, 10) > 0 && {
              disabled: true,
            })}
        />
      </MUIGrid>
      <MUIGrid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setFormValues((prevState) => ({
                  ...prevState,
                  desc: {
                    ...prevState.desc,
                    acceptsVoidFill: event.target.checked,
                  },
                }))
              }
              checked={formValues.desc?.acceptsVoidFill}
            />
          }
          label="Accepts Void Fill"
          {...(pageViewer.isView() && {
            disabled: true,
          })}
        />
        <Typography
          fontWeight="bold"
          sx={{
            borderLeft: '2px solid gray',
            paddingLeft: '20px',
            fontSize: '16px',
          }}
        >
          Inner Dimensions:
        </Typography>
        <TextField
          inputProps={{
            'data-state': 'innerDescriptionLength',
            autoComplete: autoCompleteTimeStamp().toString(),
          }}
          autoComplete="off"
          id="outlined"
          label="Length"
          size="small"
          name="desc"
          value={formValues.desc?.innerDescriptionLength || ''}
          onChange={(e) => {
            const validateDecimal = decimalValidationHelper(e.target.value);
            setFormValues((prevState) => ({
              ...prevState,
              desc: {
                ...prevState.desc,
                innerDescriptionLength: validateDecimal,
              },
            }));
          }}
          sx={{ width: '20%', margin: '0px 20px' }}
          {...(queryStringItemDetails.form === 'view' &&
            parseInt(queryStringItemDetails.id, 10) > 0 && {
              disabled: true,
            })}
        />
        <TextField
          inputProps={{
            'data-state': 'innerDescriptionWidth',
            autoComplete: autoCompleteTimeStamp().toString(),
          }}
          autoComplete="off"
          id="outlined"
          label="Width"
          size="small"
          name="desc"
          value={formValues.desc?.innerDescriptionWidth || ''}
          onChange={(e) => {
            const validateDecimal = decimalValidationHelper(e.target.value);
            setFormValues((prevState) => ({
              ...prevState,
              desc: {
                ...prevState.desc,
                innerDescriptionWidth: validateDecimal,
              },
            }));
          }}
          sx={{ width: '20%', marginRight: '20px' }}
          {...(queryStringItemDetails.form === 'view' &&
            parseInt(queryStringItemDetails.id, 10) > 0 && {
              disabled: true,
            })}
        />
        <TextField
          inputProps={{
            'data-state': 'innerDescriptionHeight',
            autoComplete: autoCompleteTimeStamp().toString(),
          }}
          autoComplete="off"
          id="outlined"
          label="Height"
          size="small"
          name="desc"
          value={formValues.desc?.innerDescriptionHeight || ''}
          onChange={(e) => {
            const validateDecimal = decimalValidationHelper(e.target.value);
            setFormValues((prevState) => ({
              ...prevState,
              desc: {
                ...prevState.desc,
                innerDescriptionHeight: validateDecimal,
              },
            }));
          }}
          sx={{ width: '20%' }}
          {...(queryStringItemDetails.form === 'view' &&
            parseInt(queryStringItemDetails.id, 10) > 0 && {
              disabled: true,
            })}
        />
      </MUIGrid>
    </MUIGrid>
  );
};
const InventoryUseAsVoidFill = () => {
  const { formValues, setFormValues, queryStringItemDetails } =
    useInventoryItemDetailsContext();

  return (
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={12}>
        <Typography variant="h6" fontWeight="bold">
          Void Fill
        </Typography>
      </MUIGrid>
      <MUIGrid item xs={1.5}>
        <TextField
          inputProps={{
            'data-state': 'voidFillQty',
            autoComplete: autoCompleteTimeStamp().toString(),
          }}
          autoComplete="off"
          id="outlined"
          label="Quantity"
          size="small"
          name="desc"
          value={formValues.desc?.voidFillQty || ''}
          onChange={(e) => {
            const validateDecimal = decimalValidationHelper(e.target.value);
            setFormValues((prevState) => ({
              ...prevState,
              desc: {
                ...prevState.desc,
                voidFillQty: validateDecimal,
              },
            }));
          }}
          sx={{ width: '100%' }}
          {...(queryStringItemDetails.form === 'view' &&
            parseInt(queryStringItemDetails.id, 10) > 0 && {
              disabled: true,
            })}
        />
      </MUIGrid>
    </MUIGrid>
  );
};

const InventoryItemDetailsContainer = () => {
  const {
    tabValue,
    formValues,
    reloadItemImage,
    thisIsAKitChecked,
    isUsingLicensePlateId,
    useAsKitOnDemandChecked,
    lpReceiptLineItemIdToPrint,
    handleTabOnChange,
  } = useInventoryItemDetailsContext();

  const query = useQuery();

  return (
    <>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={12}>
          <CardTab
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0px !important',
              marginBottom: '10px !important',
              overflow: 'auto',
              borderRadius: 2,
              margin: '-5px',
              border: '1px solid #cdcdcd',
            }}
            tabValue={tabValue}
            handleChange={handleTabOnChange}
            tabView={[
              {
                title: 'Summary',
                value: '1',
                isDisplay: true,
                children: (
                  <Box sx={{ padding: '21px' }}>
                    <InventorySummaryFirstRow />
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <InventoryDescription />
                    {formValues.desc?.useAsPackaging && (
                      <>
                        <Divider sx={{ mt: 2, mb: 2 }} />
                        <InventoryUseAsPackaging />
                      </>
                    )}
                    {formValues.desc?.useAsVoidFill && (
                      <>
                        <Divider sx={{ mt: 2, mb: 2 }} />
                        <InventoryUseAsVoidFill />
                      </>
                    )}

                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <InventorySummaryThirdRow />
                  </Box>
                ),
              },
              {
                title: 'Inventory Settings',
                value: '2',
                isDisplay: true,
                children: (
                  <Box sx={{ padding: '21px' }}>
                    <InventorySettings />
                  </Box>
                ),
              },
              {
                title: 'Kit/Manufacturing',
                value: '3',
                isDisplay: thisIsAKitChecked,
                children: <InventoryKitComponents />,
              },
              {
                title: 'Bundle Settings',
                value: '4',
                isDisplay: useAsKitOnDemandChecked,
                children: <KitOnDemandTab />,
              },
              {
                title: 'Suppliers',
                value: '5',
                isDisplay: true,
                children: (
                  <Box sx={{ padding: '21px' }}>
                    <InventorySuppliers />
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <InventoryCostSheets />
                  </Box>
                ),
              },
              {
                title: 'Activity',
                value: '6',
                isDisplay: true,
                children: (
                  <Box sx={{ padding: '21px' }}>
                    <InventoryActivities />
                  </Box>
                ),
              },
            ]}
          />
        </MUIGrid>
      </MUIGrid>
      <MUIGrid container spacing={2}>
        <PictureUploadModal
          itemId={query.get('id')}
          reloadImageCallback={reloadItemImage}
        />
      </MUIGrid>
      {lpReceiptLineItemIdToPrint !== null &&
        lpReceiptLineItemIdToPrint > 0 && (
          <Printformtemplate
            preview={false}
            dynamicId={lpReceiptLineItemIdToPrint}
            formtemplateTypeId={FormTemplateTypeEnum.PalletLabels}
            forPalletLabelsIsUseLicense={isUsingLicensePlateId}
          />
        )}
    </>
  );
};

function InventoryItemDetails() {
  const {
    skuPrintModelData,
    setSKUPrintModelData,
    printTypeData,
    setOtherUOMPrintModelData,
    otherUOMPrintModelData,
  } = useContext(GlobalContext);

  const skuPrintRef = useRef(null);
  const buttonSKUPrintRef = useRef(null);

  const otherUOMPrintRef = useRef(null);
  const buttonOtherUOMPrintRef = useRef(null);

  // PRINT SKU LABELS -----------------------------------------
  const printQtyModalCallback = (qty: number) => {
    if (printTypeData === PrintTypeEnum.SKU) {
      setSKUPrintModelData((prevState: SKUPrintModel) => ({
        ...prevState,
        qty,
        buttonPrint: buttonSKUPrintRef,
      }));
    } else if (printTypeData === PrintTypeEnum.OtherUOM) {
      setOtherUOMPrintModelData((prevState: OtherUOMPrintModel) => ({
        ...prevState,
        qty,
        buttonPrint: buttonOtherUOMPrintRef,
      }));
    }
  };

  const skuPrintClickTriggerHandler = useReactToPrint({
    content: () => skuPrintRef.current,
  });

  const handleOnClicPrintTriggerLocation = useReactToPrint({
    content: () => otherUOMPrintRef.current,
  });

  return (
    <MainContainer>
      <InventoryItemDetailsContextProvider>
        <Header />
        <KittingLineItemModal />
        <KitOnDemandComponentModal />

        <PrintQtyModal onCallback={printQtyModalCallback} />
        <div style={{ display: 'none' }}>
          <SKUPrint ref={skuPrintRef} data={skuPrintModelData} />
          <button
            type="button"
            ref={buttonSKUPrintRef}
            onClick={() => {
              skuPrintClickTriggerHandler();
            }}
          >
            This one is trigger on print
          </button>

          <OtherUOMPrint ref={otherUOMPrintRef} data={otherUOMPrintModelData} />
          <button
            type="button"
            ref={buttonOtherUOMPrintRef}
            onClick={() => {
              handleOnClicPrintTriggerLocation();
            }}
          >
            This one is trigger on multiple print of sku
          </button>
        </div>
        <ContentContainer>
          <AddItemCostSheet />
          <InventoryItemDetailsContainer />
        </ContentContainer>
      </InventoryItemDetailsContextProvider>
    </MainContainer>
  );
}

export default React.memo(InventoryItemDetails);
