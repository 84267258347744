import React, { useContext } from 'react';

import {
  OrderBillingType,
  usePackAndShipContext,
  CountryType,
} from 'pages/packandship/context';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Autocomplete,
  Modal,
  Box,
  Typography,
  TextField,
  Divider,
  Grid as MUIGrid,
  Tab,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

import { autoCompleteTimeStamp } from '../../../../helpers/autocompletetimestamp';

export const MUIContainer = MuiStyled(Box)`
    && {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 16px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  
      background: ${(props) =>
        props.theme.palette.mode === 'dark'
          ? props.theme.palette.background.default
          : 'white'};
      box-shadow: 0px 24px 38px #00000024;
      width: 100%;
      max-width: 1000px;
      border-radius: 4px;
    }
  `;
export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 24px;
    gap: 16px;
  }
`;
export default React.memo(() => {
  const {
    isPackAndShipShipToAndBillToModalOpen,
    onClosePackAndShipShipToAndBillToModal,
  } = useContext(GlobalContext);

  const {
    tabValue,
    optionCountries,
    shipToBillToForm,
    pickTicketFormObj,
    shipToCountryValue,
    billToCountryValue,
    verifyAddress,
    setTabValue,
    setBillToCountryValue,
    setShipToCountryValue,
    shipToBillToFormOnChange,
    shipToBillToFormAutoCompleteOnChange,
    updateThePickTicketShipToBillToDetailsOnClick,
  } = usePackAndShipContext();

  const setTabValuehandleOnChange = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setTabValue(newValue);
  };

  return (
    <Modal open={isPackAndShipShipToAndBillToModalOpen}>
      <MUIContainer>
        <MUIContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              sx={{ marginBotton: '16px' }}
              variant="h6"
              fontWeight="bold"
            >
              Contact Details
            </Typography>
            <Button
              {...(pickTicketFormObj?.isShipAndComplete && {
                disabled: true,
              })}
              sx={{
                marginLeft: '2px !important',
                padding: 1,
                background: '#ECF0F8',
                borderColor: '#DADCE0',
                color: '#3375E0',
                fontWeight: 600,
              }}
              variant="outlined"
              size="small"
              onClick={(e) => {
                setTabValue('1');
                verifyAddress();
              }}
            >
              Verify Address
            </Button>
          </Box>
          <Box autoComplete="off" component="form" noValidate>
            <TabContext value={tabValue.toString()}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  display: 'flex',
                }}
              >
                <TabList
                  sx={{ mr: 2, flexGrow: 1 }}
                  onChange={setTabValuehandleOnChange}
                  aria-label="Contact Details Tab"
                >
                  <Tab
                    label={
                      <Typography
                        fontWeight="bold"
                        sx={{ textTransform: 'capitalize !important' }}
                      >
                        Ship To
                      </Typography>
                    }
                    value="1"
                  />
                  <Tab
                    label={
                      <Typography
                        fontWeight="bold"
                        sx={{ textTransform: 'capitalize !important' }}
                      >
                        Bill To
                      </Typography>
                    }
                    value="2"
                  />
                </TabList>
                <FormControlLabel
                  // disabled={isFormDisable}
                  control={
                    <Checkbox
                    // onChange={handleOnChangeCheckbox}
                    // {...(useShipToForBillToChecked !== undefined && {
                    //   checked: useShipToForBillToChecked,
                    // })}
                    />
                  }
                  label="Use Ship To details for Bill To"
                />
              </Box>
              <TabPanel
                sx={{
                  '&.MuiTabPanel-root': {
                    padding: '15px 0px !important',
                  },
                }}
                value="1"
              >
                <MUIGrid
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <MUIGrid item xs={12}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{ fontSize: 16, paddingLeft: '15px' }}
                      mb={2}
                    >
                      Ship To Contact
                    </Typography>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      inputProps={{
                        'data-state': 'codeId',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Code / ID"
                      size="small"
                      name="shipTo"
                      value={shipToBillToForm?.shipTo?.codeId || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                </MUIGrid>

                <MUIGrid
                  container
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ margin: 1 }}
                >
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      required
                      // {...(salesOrderFormErrors.shipToRecipient && {
                      //   error: true,
                      //   helperText: salesOrderFormErrors.shipToRecipient,
                      // })}
                      inputProps={{
                        'data-state': 'recipientName',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Recipient Name"
                      size="small"
                      name="shipTo"
                      value={shipToBillToForm?.shipTo?.recipientName || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      inputProps={{
                        'data-state': 'companyName',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Company Name"
                      size="small"
                      name="shipTo"
                      value={shipToBillToForm?.shipTo?.companyName || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      inputProps={{
                        'data-state': 'phone',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Phone"
                      size="small"
                      name="shipTo"
                      value={shipToBillToForm?.shipTo?.phone || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      inputProps={{
                        'data-state': 'email',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Email"
                      size="small"
                      name="shipTo"
                      value={shipToBillToForm?.shipTo?.email || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                </MUIGrid>

                <MUIGrid
                  container
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ margin: 1 }}
                >
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      required
                      // {...(salesOrderFormErrors.shipToAddress1 && {
                      //   error: true,
                      //   helperText: salesOrderFormErrors.shipToAddress1,
                      // })}
                      inputProps={{
                        'data-state': 'address1',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Address 1"
                      size="small"
                      name="shipTo"
                      value={shipToBillToForm?.shipTo?.address1 || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      inputProps={{
                        'data-state': 'address2',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Address 2"
                      size="small"
                      name="shipTo"
                      value={shipToBillToForm?.shipTo?.address2 || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                </MUIGrid>

                <MUIGrid
                  container
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ margin: 1 }}
                >
                  <MUIGrid item xs={3}>
                    <Autocomplete
                      // disabled={isFormDisable}
                      onChange={(event: any, newValue: CountryType) => {
                        if (newValue !== null) {
                          setShipToCountryValue(newValue);
                          shipToBillToFormAutoCompleteOnChange(event, newValue);
                        } else {
                          setShipToCountryValue(null);
                          shipToBillToFormAutoCompleteOnChange(event, null, {
                            name: 'shipTo',
                            data: {
                              state: 'country',
                              value: undefined,
                            },
                          });
                        }
                      }}
                      id="controllable-states"
                      options={optionCountries}
                      getOptionLabel={(option: any) => option.label}
                      value={shipToCountryValue}
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      renderOption={(props, option) => (
                        <li data-name="shipTo" data-state="country" {...props}>
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          required
                          // {...(salesOrderFormErrors.shipToCountry && {
                          //   error: true,
                          //   helperText: salesOrderFormErrors.shipToCountry,
                          // })}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          id="outlined-required"
                          label="Country"
                          size="small"
                          sx={{ width: '100%', background: '#ffffff' }}
                        />
                      )}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      inputProps={{
                        'data-state': 'stateProvinceRegion',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="State/Province/Region"
                      size="small"
                      name="shipTo"
                      value={
                        shipToBillToForm?.shipTo?.stateProvinceRegion || ''
                      }
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      // {...(salesOrderFormErrors.shipToCityTownship && {
                      //   error: true,
                      //   helperText: salesOrderFormErrors.shipToCityTownship,
                      // })}
                      required
                      inputProps={{
                        'data-state': 'cityTownship',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="City/Township"
                      size="small"
                      name="shipTo"
                      value={shipToBillToForm?.shipTo?.cityTownship || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      // {...(salesOrderFormErrors.shipToZipPostalCode && {
                      //   error: true,
                      //   helperText: salesOrderFormErrors.shipToZipPostalCode,
                      // })}
                      required
                      inputProps={{
                        'data-state': 'zipPostalCode',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="ZIP/Postal Code"
                      size="small"
                      name="shipTo"
                      value={shipToBillToForm?.shipTo?.zipPostalCode || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                </MUIGrid>

                <MUIGrid
                  container
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ margin: 1 }}
                >
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      inputProps={{
                        'data-state': 'retailerId',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Retailer ID"
                      size="small"
                      name="shipTo"
                      value={shipToBillToForm?.shipTo?.retailerId || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      inputProps={{
                        'data-state': 'departmentNumber',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Department Number"
                      size="small"
                      name="shipTo"
                      value={shipToBillToForm?.shipTo?.departmentNumber || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                </MUIGrid>
              </TabPanel>
              <TabPanel
                sx={{
                  '&.MuiTabPanel-root': {
                    padding: '15px 0px !important',
                  },
                }}
                value="2"
              >
                <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <MUIGrid item xs={12}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{ fontSize: 16, paddingLeft: '15px' }}
                      mb={2}
                    >
                      Bill To Contact
                    </Typography>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      inputProps={{
                        'data-state': 'codeId',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Code / ID"
                      size="small"
                      name="billTo"
                      value={shipToBillToForm?.billTo?.codeId || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                </MUIGrid>
                <MUIGrid
                  container
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ margin: 1 }}
                >
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      // {...(salesOrderFormErrors.shipToRecipient && {
                      //   error: true,
                      //   helperText: salesOrderFormErrors.shipToRecipient,
                      // })}
                      required
                      inputProps={{
                        'data-state': 'recipientName',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Recipient Name"
                      size="small"
                      name="billTo"
                      value={shipToBillToForm?.billTo?.recipientName || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      inputProps={{
                        'data-state': 'companyName',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Company Name"
                      size="small"
                      name="billTo"
                      value={shipToBillToForm?.billTo?.companyName || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      inputProps={{
                        'data-state': 'phone',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Phone"
                      size="small"
                      name="billTo"
                      value={shipToBillToForm?.billTo?.phone || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      inputProps={{
                        'data-state': 'email',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Email"
                      size="small"
                      name="billTo"
                      value={shipToBillToForm?.billTo?.email || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                </MUIGrid>
                <MUIGrid
                  container
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ margin: 1 }}
                >
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      // {...(salesOrderFormErrors.shipToAddress1 && {
                      //   error: true,
                      //   helperText: salesOrderFormErrors.shipToAddress1,
                      // })}
                      required
                      inputProps={{
                        'data-state': 'address1',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="Address 1"
                      size="small"
                      name="billTo"
                      value={shipToBillToForm?.billTo?.address1 || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      inputProps={{
                        'data-state': 'address2',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Address 2"
                      size="small"
                      name="billTo"
                      value={shipToBillToForm?.billTo?.address2 || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                </MUIGrid>
                <MUIGrid
                  container
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ margin: 1 }}
                >
                  <MUIGrid item xs={3}>
                    <Autocomplete
                      // disabled={isFormDisable}
                      onChange={(event: any, newValue: CountryType) => {
                        if (newValue !== null) {
                          setBillToCountryValue(newValue);
                          shipToBillToFormAutoCompleteOnChange(event, newValue);
                        } else {
                          setShipToCountryValue(null);
                          shipToBillToFormAutoCompleteOnChange(event, null, {
                            name: 'billTo',
                            data: {
                              state: 'country',
                              value: undefined,
                            },
                          });
                        }
                      }}
                      id="controllable-states"
                      options={optionCountries}
                      getOptionLabel={(option: any) => option.label}
                      value={billToCountryValue}
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      renderOption={(props, option) => (
                        <li data-name="billTo" data-state="country" {...props}>
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          // {...(salesOrderFormErrors.shipToCountry && {
                          //   error: true,
                          //   helperText: salesOrderFormErrors.shipToCountry,
                          // })}
                          required
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          id="outlined-required"
                          label="Country"
                          size="small"
                          sx={{ width: '100%', background: '#ffffff' }}
                        />
                      )}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      inputProps={{
                        'data-state': 'stateProvinceRegion',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="State/Province/Region"
                      size="small"
                      name="billTo"
                      value={
                        shipToBillToForm?.billTo?.stateProvinceRegion || ''
                      }
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      // {...(salesOrderFormErrors.shipToCityTownship && {
                      //   error: true,
                      //   helperText: salesOrderFormErrors.shipToCityTownship,
                      // })}
                      required
                      inputProps={{
                        'data-state': 'cityTownship',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="City/Township"
                      size="small"
                      name="billTo"
                      value={shipToBillToForm?.billTo?.cityTownship || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      // {...(salesOrderFormErrors.shipToZipPostalCode && {
                      //   error: true,
                      //   helperText: salesOrderFormErrors.shipToZipPostalCode,
                      // })}
                      required
                      inputProps={{
                        'data-state': 'zipPostalCode',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined-required"
                      label="ZIP/Postal Code"
                      size="small"
                      name="billTo"
                      value={shipToBillToForm?.billTo?.zipPostalCode || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                </MUIGrid>
                <MUIGrid
                  container
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ margin: 1 }}
                >
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      inputProps={{
                        'data-state': 'retailerId',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Retailer ID"
                      size="small"
                      name="billTo"
                      value={shipToBillToForm?.billTo?.retailerId || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      // disabled={isFormDisable}
                      inputProps={{
                        'data-state': 'departmentNumber',
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      id="outlined"
                      label="Department Number"
                      size="small"
                      name="billTo"
                      value={shipToBillToForm?.billTo?.departmentNumber || ''}
                      onChange={shipToBillToFormOnChange}
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                    />
                  </MUIGrid>
                </MUIGrid>
              </TabPanel>
            </TabContext>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={8}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => onClosePackAndShipShipToAndBillToModal()}
                    variant="outlined"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    onClick={(e) => {
                      updateThePickTicketShipToBillToDetailsOnClick(e);
                      onClosePackAndShipShipToAndBillToModal();
                    }}
                    variant="contained"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    UPDATE
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
