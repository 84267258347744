import React, { useCallback, useContext, useEffect, useState } from 'react';

import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import { DataGridProgress } from 'components/progress';
import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import { useSalesOrderDataTableViewContext } from 'pages/sales/context-provider';
import OrderStatusSmallChip from 'pages/sales/salesorder/orderstatuschip/orderstatussmallchip';
import AdvanceSearchFilterModal from 'pages/shared/advancesearchfiltermodal';
import Printformtemplate, {
  FormTemplateTypeEnum,
} from 'pages/shared/printformtemplate';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import {
  ContainerFlat,
  DatagridChipStatus,
  DatagridPremium,
  DatagridStandard,
} from 'styles';

import {
  Link,
  Typography,
  Button,
  Tooltip,
  Chip,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridColumns, GridActionsCellItem } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';

import ChannelIcon from '../salesorder/channel-image';

const OrdersDatable = () => {
  const theme = useTheme();

  const {
    onCloseConfirmSalesOrderModal,
    onOpenPrintFormTemplateModal,
    onOpenConfirmSalesOrderModal,
    setIsDeleteOrderWithCompletedPickTicketModalOpen,
  } = useContext(GlobalContext);
  const { isWarehouseCustomerAccount, currentUser } = useContext(AuthContext);
  const {
    advanceSearchValue,
    apiResponse,
    initialRows,
    filteredRows,
    isFetchingData,
    rows,
    apiRef,
    setFilteredRows,
    setOrderId,
    setOrderNo,
    setSelectedRowsOnCheckbox,
  } = useSalesOrderDataTableViewContext();

  const [orderIdPrint, setOrderIdPrint] = useState(0);

  const printBillOfLadingOnClick = useCallback(
    async (e: React.SyntheticEvent, id: any): Promise<void> => {
      e.preventDefault();
      setOrderIdPrint(id);
      onOpenPrintFormTemplateModal();
    },
    [orderIdPrint],
  );

  const columns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      hideable: false,
      width: 30,
      hide: isWarehouseCustomerAccount,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params: any) => [
        <GridActionsCellItem
          label="Delete Order"
          disabled={params.row.status === 'Complete'}
          onClick={() => {
            setOrderId(Number(params.row.orderId));

            if (params.row.isOpenPickTicketCompleted) {
              setIsDeleteOrderWithCompletedPickTicketModalOpen(true);
            } else {
              setOrderNo(params.row.orderNo);
              onOpenConfirmSalesOrderModal();
            }
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Print BOL"
          onClick={(e) => printBillOfLadingOnClick(e, params.id)}
          showInMenu
        />,
        // NOTE: DISABLE FOR NOW
        // NEED TO APPLY THE ORDER RETURN RULE FROM RULE MANAGEMENT
        // <GridActionsCellItem
        //   label="Create Return"
        //   onClick={() => {
        //     navigate(
        //       `/orders/sales-orders?form=new&isReturn=true&id=${params.row.orderId}`,
        //     );
        //   }}
        //   showInMenu
        //   disabled={params.row.statusValue === 10}
        // />,
      ],
    },
    {
      field: 'batchNo',
      minWidth: 90,
      flex: 1,
      headerName: 'Batch No',
      renderCell: (params: any) => (
        <Button variant="text" size="small" className="cellDatagridWrapper">
          {params.row.batchNo}
        </Button>
      ),
    },
    {
      field: 'status',
      minWidth: 130,
      flex: 1,
      headerName: 'Status',
      renderCell: (params: any) => (
        <DatagridChipStatus>
          <span>{params.row.status}</span>
          <OrderStatusSmallChip
            isHold={params.row.isHold}
            isBackOrder={params.row.isBackOrder}
            isPartial={params.row.isPartial}
            backOrderDateTime={params.row.backOrderDateTime}
            isAddressInvalid={params.row.isAddressInvalid}
          />
        </DatagridChipStatus>
      ),
    },
    {
      field: 'orderNo',
      minWidth: 120,
      flex: 1,
      headerName: 'Order No.',
      renderCell: (params: any) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={
            isWarehouseCustomerAccount
              ? `warehouse-customer-orders/sales-orders?form=view&id=${params.row.orderId}`
              : `orders/sales-orders?form=view&id=${params.row.orderId}`
          }
        >
          <Typography
            className="cellDatagridWrapper"
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.orderNo}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'referenceNo',
      minWidth: 120,
      flex: 1,
      headerName: 'Reference No.',
      renderCell: (params: any) => (
        <Typography className="cellDatagridWrapper">
          {params.row.referenceNo}
        </Typography>
      ),
    },
    {
      field: 'channel',
      minWidth: 100,
      flex: 0.3,
      headerName: 'Channel',
      renderCell: (params: any) =>
        params.row?.channel && (
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Tooltip title={`${params.row?.channel}`} arrow>
              <div>
                <ChannelIcon type={params.row?.channel.toLowerCase()} />
              </div>
            </Tooltip>
          </div>
        ),
    },
    {
      field: 'po',
      minWidth: 120,
      flex: 1,
      headerName: 'PO',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">{params.row.po}</Typography>
      ),
    },
    {
      field: 'itemCount',
      minWidth: 120,
      flex: 1,
      headerName: 'Item Count',
      type: 'number',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.itemCount}
        </Typography>
      ),
    },
    {
      field: 'totalQuantity',
      minWidth: 120,
      flex: 1,
      headerName: 'Total Quantity',
      type: 'number',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.totalQuantity}
        </Typography>
      ),
    },
    {
      field: 'weightTotal',
      minWidth: 120,
      flex: 1,
      headerName: 'Weight',
      type: 'number',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.weightTotal && params.row.weightTotal.toFixed(2)} (lb)
        </Typography>
      ),
    },
    {
      field: 'carrier',
      minWidth: 120,
      flex: 1,
      headerName: 'Carrier',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.carrier}
        </Typography>
      ),
    },
    {
      field: 'assignedTo',
      minWidth: 100,
      flex: 1,
      headerName: 'Assigned To',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row?.assignedTo}
        </Typography>
      ),
    },
    {
      field: 'approvedByStr',
      minWidth: 100,
      flex: 1,
      headerName: 'Approved By',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row?.approvedByStr}
        </Typography>
      ),
    },
    {
      field: 'customer',
      minWidth: 130,
      flex: 1,
      headerName: 'Client',
      hide: isWarehouseCustomerAccount,
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.customer}
        </Typography>
      ),
    },
    {
      field: 'creationDateTime',
      minWidth: 170,
      flex: 1,
      headerName: 'Order Date',
      type: 'date',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row?.creationDateTime
            ? moment(params.row?.creationDateTime).format(
                'MM/DD/YYYY : hh:mm A',
              )
            : ''}
        </Typography>
      ),
    },
    {
      field: 'shipDateDay',
      minWidth: 150,
      flex: 1,
      headerName: 'Ship Date',
      type: 'date',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipDateDay} {params.row.shipDateTime}
        </Typography>
      ),
    },
    {
      field: 'shipName',
      minWidth: 150,
      flex: 1,
      headerName: 'Ship Name',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipName}
        </Typography>
      ),
    },
    {
      field: 'shipCompany',
      minWidth: 150,
      flex: 1,
      headerName: 'Ship Company',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipCompany}
        </Typography>
      ),
    },
    {
      field: 'shipAddress1',
      minWidth: 150,
      flex: 1,
      headerName: 'Ship Address 1',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipAddress1}
        </Typography>
      ),
    },
    {
      field: 'shipAddress2',
      minWidth: 120,
      flex: 1,
      headerName: 'Ship Address 2',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipAddress2}
        </Typography>
      ),
    },
    {
      field: 'shipCity',
      minWidth: 130,
      flex: 1,
      headerName: 'Ship City',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipCity}
        </Typography>
      ),
    },
    {
      field: 'shipState',
      minWidth: 130,
      flex: 1,
      headerName: 'Ship State',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipState}
        </Typography>
      ),
    },
    {
      field: 'shipZip',
      minWidth: 130,
      flex: 1,
      headerName: 'Ship Zip',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipZip}
        </Typography>
      ),
    },
    {
      field: 'shipCountry',
      minWidth: 130,
      flex: 1,
      headerName: 'Ship Country',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipCountry}
        </Typography>
      ),
    },
    {
      field: 'shipEmail',
      minWidth: 130,
      flex: 1,
      headerName: 'Ship Email',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipEmail}
        </Typography>
      ),
    },
    {
      field: 'shipPhone',
      minWidth: 130,
      flex: 1,
      headerName: 'Ship Phone',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipPhone}
        </Typography>
      ),
    },
    {
      field: 'shipByDateTime',
      minWidth: 120,
      flex: 1,
      headerName: 'Ship By',
      type: 'date',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row?.shipByDateTime
            ? moment(params.row?.shipByDateTime).format('MM/DD/YYYY')
            : ''}
        </Typography>
      ),
    },
    {
      field: 'shipCancelDateTime',
      minWidth: 150,
      flex: 1,
      headerName: 'Ship Cancel Date',
      type: 'date',
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params.row.shipCancelDateTime}
        </Typography>
      ),
    },
    {
      field: 'shipStationTags',
      minWidth: 200,
      flex: 2,
      disableExport: true,
      headerName: 'Tags',
      renderCell: (params) =>
        params.row.shipStationTags != null ? (
          <>
            {params.row.shipStationTags.map((tag) => ({
              ...(
                <Tooltip title={tag.tagName}>
                  <Chip
                    label={tag.tagName}
                    size="small"
                    sx={{
                      marginLeft: 1,
                      fontWeight: 'bold',
                      color: tag.tagColor === 'White' ? 'black' : 'white',
                    }}
                    style={{ backgroundColor: tag.tagColor }}
                  />
                </Tooltip>
              ),
            }))}
            <span />
          </>
        ) : (
          <span />
        ),
    },
    {
      field: 'shipStationTagsExport',
      minWidth: 150,
      flex: 1,
      hide: true,
      headerName: 'Tags',
    },
  ];

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids
      .map((id) => {
        const row = rows.find((v) => v.orderId === id);
        return row
          ? {
              orderId: row.orderId,
              statusValue: row.statusValue,
              orderNo: row.orderNo,
              isHold: row.isHold,
            }
          : null;
      })
      .filter((item) => item !== null);
    setSelectedRowsOnCheckbox(selectedRowsData);
  };

  const removeOrderItem = (id: number) => {
    setFilteredRows((prev) => prev.filter((o) => o.orderId !== id));
  };

  useEffect(() => {
    if (apiResponse !== null) {
      const { status, data } = apiResponse;
      switch (status) {
        case 200:
          removeOrderItem(data.orderId);
          onCloseConfirmSalesOrderModal();
          snackActions.success('Order item deleted successfully.');
          break;
        default:
          break;
      }
    }
  }, [
    apiResponse,
    isWarehouseCustomerAccount,
    advanceSearchValue.filterSearchParameter,
  ]);

  // order state -----------------------------------------------
  const [initialState, setInitialState] =
    React.useState<GridInitialStatePremium>();

  const [savedState, setSavedState] = React.useState<{
    initialState: GridInitialStatePremium;
  }>({ initialState: {} });

  const saveSnapshot = React.useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      localStorage.setItem('orderDataGridState', JSON.stringify(currentState));
    }
  }, []);

  React.useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage?.getItem('orderDataGridState');
    setInitialState(
      stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {},
    );

    setSavedState((prev) => ({
      ...prev,
      initialState: JSON.parse(stateFromLocalStorage),
    }));
  }, [currentUser.Claim_CustomerId]);

  useEffect(() => {
    if (savedState?.initialState !== null && apiRef?.current?.restoreState) {
      apiRef?.current?.restoreState(savedState?.initialState);
    }
  }, [columns]);

  if (!initialState) {
    return (
      <div style={{ textAlign: 'center', paddingTop: '20%' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <ContainerFlat>
      <DatagridPremium
        autoHeight
        checkboxSelection
        disableColumnMenu
        disableSelectionOnClick
        rows={filteredRows}
        columns={columns}
        getRowId={(row) => row.orderId}
        rowGroupingColumnMode="single"
        density="compact"
        onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
        components={{
          LoadingOverlay: DataGridProgress,
        }}
        loading={isFetchingData}
        apiRef={apiRef}
        onColumnOrderChange={() => {
          saveSnapshot();
        }}
        onColumnWidthChange={() => {
          saveSnapshot();
        }}
        onPageSizeChange={() => {
          saveSnapshot();
        }}
        initialState={{ pagination: { pageSize: 100 } }}
        rowsPerPageOptions={[50, 100, 200]}
        pagination
      />
      <AdvanceSearchFilterModal />
      {orderIdPrint !== 0 && (
        <Printformtemplate
          preview={false}
          dynamicId={orderIdPrint}
          formtemplateTypeId={FormTemplateTypeEnum.BillofLading}
        />
      )}
    </ContainerFlat>
  );
};

export default React.memo(OrdersDatable);
