import React, { useContext } from 'react';
import { useNavigate } from 'react-router';

import Header from 'components/header';
import AddTagsModal from 'pages/sales/addtags';
import AddToBatchModal from 'pages/sales/addtobatchmodal';
import AssignToModal from 'pages/sales/assigntomodal';
import SalesOrderDataTableViewContextProvider from 'pages/sales/context-provider';
import ImportOrderModal from 'pages/sales/importordermodal';
import UpdateStatusModal from 'pages/sales/updatestatusmodal';
import ConfirmSalesOrderModal from 'pages/shared/confirmsalesordermodal';
import ImportGlobalMessageModal from 'pages/shared/import-global-message-modal';
import { MainContainer, ContentContainer } from 'pages/styles';
import { GlobalContext } from 'store/contexts/GlobalContext';

import OrdersDatable from './orders-table';
import OrdersFilterSearchPanel from './search-panel';

function OrdersView() {
  const { isImportOrderModalOpen } = useContext(GlobalContext);
  const navigate = useNavigate();

  const orderImportHandler = () => {
    navigate(0);
  };

  return (
    <MainContainer>
      <SalesOrderDataTableViewContextProvider>
        <Header />
        <ImportGlobalMessageModal title="Import Order Error" />
        <ConfirmSalesOrderModal />
        <AddTagsModal />
        <AddToBatchModal />
        <AssignToModal />
        <UpdateStatusModal />
        {isImportOrderModalOpen && <ImportOrderModal />}
        <ContentContainer>
          <OrdersFilterSearchPanel />
          <OrdersDatable />
        </ContentContainer>
      </SalesOrderDataTableViewContextProvider>
    </MainContainer>
  );
}

export default React.memo(OrdersView);
